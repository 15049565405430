import { cloneDeep, uniqueId as uuid } from 'lodash'
import RiskalyzeType from './types/riskalyze'
import PDFCrowdType from './types/pdfcrowd'
import CustomType from './types/custom'
import ClientType from './types/client'
import { Placeholder } from '../types/mapper'

/**
 * Returns a unique timestamped id
 * @returns
 */
export const uniqueId = () => {
  return uuid('mid-')
}

export const Types = {
  CUSTOM: 'CUSTOM',
  CLIENT: 'CLIENT',
  RISKALYZE: 'RISKALYZE',
  PDFCROWD: 'PDFCROWD',
} as const

export const Active = {
  [Types.CUSTOM]: CustomType,
  [Types.CLIENT]: ClientType,
  [Types.RISKALYZE]: RiskalyzeType,
  [Types.PDFCROWD]: PDFCrowdType,
} as const

/**
 * Returns a list of available custom placeholders to add to place holder selection dropdown
 */
export const placeHolders = () => {
  // @ts-ignore
  if (false && !global.perms.get('ui.app.settings.custom-merge-fields')) {
    return [] // disallow custom place holders if not permissioned
  }

  return Object.keys(Active).reduce<Placeholder[]>((memo, Type) => {
    // @ts-ignore
    const Mapper = Active[Type]
    let placeHolders: Placeholder[] = cloneDeep(Mapper.placeHolders)

    placeHolders = placeHolders.map(({ id, label }) => {
      // prefix custom mappers with their custom type.
      id = Mapper.isCustom ? `${Type}-${id}` : id
      return { id, label }
    })

    return memo.concat(placeHolders)
  }, [])
}
