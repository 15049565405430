/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Form, FormInstance, Select, Space } from 'antd'
import { useCallback, useState } from 'react'
import styled from 'styled-components'
import { UnsupportedProviderAlert } from '../smartfield.alerts'
import { FormItemLabel } from '../smartfield.styles'
import { SmartField, SmartFieldProvider } from '../types'
import { SmartFieldIntegrationConfig } from '../types/data'
import { useProviders } from './hooks/useProviders'
import { SmartFieldIntegrationHolistiplan } from './providers/holistiplan'
import { SmartFieldIntegrationPreciseFP } from './providers/precisefp'
import { SmartFieldIntegrationRiskalyze } from './providers/riskalyze'

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  width: 18px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
`

interface SmartFieldIntegrationProps {
  smartfield?: SmartField
  form: FormInstance
  testId?: string
}

interface ProviderPros {
  provider: string
  form: FormInstance
  smartfield?: SmartField
}

const renderProvider: React.FC<ProviderPros> = ({ form, smartfield, provider }) => {
  switch (provider) {
    case 'precisefp':
      return <SmartFieldIntegrationPreciseFP smartfield={smartfield} form={form} />
    case 'riskalyze':
      return <SmartFieldIntegrationRiskalyze smartfield={smartfield} form={form} />
    case 'holistiplan':
      return <SmartFieldIntegrationHolistiplan smartfield={smartfield} form={form} />
    default:
      return <UnsupportedProviderAlert />
  }
}

export const SmartFieldIntegration: React.FC<SmartFieldIntegrationProps> = ({ form, smartfield }) => {
  const sf = smartfield as SmartField<SmartFieldIntegrationConfig>

  const [provider, setProvider] = useState<SmartFieldProvider>(sf?.config?.provider as SmartFieldProvider)

  const { providers } = useProviders()

  // reset label field when changing provider
  const onChange = useCallback(
    (value: any) => {
      form.setFieldValue('label', undefined)
      setProvider(value)
    },
    [form],
  )

  return (
    <div data-testid="smartfield-integration-form">
      <Form.Item
        name="provider"
        rules={[
          {
            required: true,
            message: 'Please select a data provider',
          },
        ]}
        label={<FormItemLabel label="Provider" description="Select your Integration provider" />}
        initialValue={sf?.config?.provider}
        preserve={false}
      >
        <Select
          onChange={onChange}
          value={provider}
          placeholder="Select integration"
          style={{ width: '100%' }}
          size="large"
        >
          {providers.map((provider) => (
            <Select.Option key={`integration-${provider.value}`} value={provider.value} label={provider.label}>
              <Space>
                {!!provider?.icon && (
                  <LogoContainer>
                    <provider.icon png="logo" />
                  </LogoContainer>
                )}
                {provider.label === 'Riskalyze' ? 'Nitrogen' : provider.label}
              </Space>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {provider && renderProvider({ form, smartfield, provider })}
    </div>
  )
}
