import { SmartFieldDrawer } from 'components/drawers/Smartfields'
import { ReplaceSmartFields } from './routes/tasks/modals/ReplaceSmartFields'

export const AppModalsAndDrawers = () => {
  return (
    <>
      <SmartFieldDrawer />
      <ReplaceSmartFields />
    </>
  )
}
