import { ProsemirrorNode, Transaction } from 'remirror'
import { ContainerTypes, CONTAINER_TYPES } from './NoteReplacer.constants'
import { getPosToReplaceMentionAtom } from './NoteReplacer.extension'
import { AvailableTopics, MergeFieldToReplace, NoteTag } from './NoteReplacer.interfaces'

/**
 * Gets merge fields from lists, including nested ones.
 */
export function getDeepListMergeFields(
  list: ProsemirrorNode,
  parentPos: number,
  tr: Transaction,
  subtopics?: NoteTag[],
): MergeFieldToReplace[] {
  const mergeFields: MergeFieldToReplace[] = []

  const lists: Omit<ContainerTypes, typeof CONTAINER_TYPES.INLINE>[] = [
    CONTAINER_TYPES.ORDERED_LIST,
    CONTAINER_TYPES.BULLET_LIST,
    CONTAINER_TYPES.TASK_LIST,
  ]

  if (!lists.includes(list.type.name)) {
    return []
  }

  list.descendants((node, pos, parent) => {
    if (node.type.name === 'mentionAtom') {
      /* The first node will always be the ListItem and it will always move the offset by 1. */
      const lisItemSize = 1
      const mentionAtomPos = getPosToReplaceMentionAtom(node, parent, pos, tr.doc) + parentPos + lisItemSize
      const tags = subtopics ? subtopics.filter((subtopic) => subtopic.value === node.attrs.tags) : node.attrs.tags

      const mentionAtom: MergeFieldToReplace = {
        node,
        mergeFieldType: node.attrs.kind === 'topic' ? node.attrs.label.split('::')[0] : node.attrs.id,
        kind: node.attrs.kind || '',
        pos: mentionAtomPos,
        containerType: list.type.name as ContainerTypes,
        displayMode: node.attrs.displayMode,
        header: node.attrs.header,
        tags,
        id: node.attrs.id,
        marks: node.marks,
        parentAttrs: parent?.attrs,
      }
      mergeFields.push(mentionAtom)
    }
    return true
  })
  return mergeFields
}

export function getTopicsFromMergeField(mergeField: MergeFieldToReplace): AvailableTopics | null {
  if (mergeField.kind === 'topic') {
    return {
      mergeFieldType: mergeField.mergeFieldType,
      tags: mergeField.tags,
    }
  } else if (mergeField.kind === 'placeholder') {
    return {
      mergeFieldType: mergeField.mergeFieldType,
      tags: mergeField.tags,
    }
  }
  return null
}
