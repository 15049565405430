/* eslint-disable no-underscore-dangle */
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { UnlockOutlined, UserOutlined } from '@ant-design/icons'
import Auth from '@aws-amplify/auth'
import { Button, Input } from 'antd'
import { ConfirmSignUp } from 'aws-amplify-react'
import CenteredLayout from 'components/layouts/CenteredLayout'
import styled from 'styled-components'
import styles from 'styles'

const StyledForm = styled(Form)`
  width: 320px;
  margin-top: 0px;
  .ant-form-item,
  .ant-legacy-form-item {
    margin-bottom: 10px !important;
    &.tight {
      margin-bottom: 0px !important;
    }
  }
  .c-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .c-button {
    margin-top: 30px;
    .reset-button {
      width: 100%;
    }
  }
  .c-error {
    color: ${styles.colors.error};
    text-align: center;
  }
  .c-forgot {
    color: ${styles.colors.blue};
    cursor: pointer;
    font-size: 14px;
    &:hover {
      text-decoration: underline;
    }
  }
`

const trim = (str = '') => str.trim()

class ConfirmSignUpComponent extends ConfirmSignUp {
  constructor(props) {
    super(props)
    this._validAuthStates = ['confirmSignUp']
    this.state = { error: null, loading: false }
  }

  onConfirmSignUp = (e) => {
    e && e.preventDefault()
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        this.setState({ loading: true })
        const username = this.usernameFromAuthData()
        Auth.confirmSignUp(trim(username), trim(values.code))
          .then(() => {
            global.data.audit.log('signUp_confirm', 'auth', {
              workspace: global.workspace.name,
              user: username,
            })
            this.setState({ loading: false })
            this.changeState('signIn')
          })
          .catch((err) => {
            this.setState({ loading: false })
            this.error(err)
          })
      }
    })
  }

  onResendCode = () => {
    const username = this.usernameFromAuthData()
    global.data.audit.log('signUp_resendCode', 'auth', {
      workspace: global.workspace.name,
      user: username,
    })
    Auth.resendSignUp(username)
  }

  showComponent() {
    const username = this.usernameFromAuthData()
    const { props } = this
    return (
      <CenteredLayout
        title="Confirm Signup"
        footer={
          <Button
            loading={this.state.loading}
            onClick={this.onConfirmSignUp}
            style={{ width: '100%', marginTop: 0 }}
            className="c-reset-button"
            type="primary"
            size="large"
          >
            Confirm
          </Button>
        }
      >
        <StyledForm onSubmit={this.onConfirmSignUp}>
          <p style={{ marginBottom: 20, fontSize: 13 }}>
            We sent a verification code to the email you provided. Please enter this below to complete sign-up.
          </p>
          <Form.Item>
            <Input prefix={<UserOutlined />} value={username} size="large" readOnly />
          </Form.Item>

          <Form.Item>
            {props.form.getFieldDecorator('code', {
              rules: [{ required: true, message: 'Enter verification code!' }],
            })(<Input prefix={<UnlockOutlined />} placeholder="Enter code" size="large" autoFocus />)}
          </Form.Item>

          <div className="c-controls">
            <Form.Item className="c-forgot">
              <CenteredLayout.Link className="forgot-link" onClick={(e) => props.onStateChange('signIn', {})}>
                Back to Sign in
              </CenteredLayout.Link>
            </Form.Item>

            <Form.Item className="c-forgot">
              <CenteredLayout.Link className="signup-link" onClick={this.onResendCode}>
                Resend code
              </CenteredLayout.Link>
            </Form.Item>
          </div>

          {this.state.error && <div className="c-error">{this.state.error}</div>}

          {/* hidden submit button */}
          <button type="submit" style={{ position: 'absolute', left: -9999 }} />
        </StyledForm>
      </CenteredLayout>
    )
  }
}

export default Form.create()(ConfirmSignUpComponent)
