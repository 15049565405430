import React from 'react'
import MySignIn from 'components/auth/SignIn'
import MyWorkspace from 'components/auth/EnterWorkspace'
import MyForgotWorkspace from 'components/auth/ForgotWorkspace'
import MyForgotPassword from 'components/auth/ForgotPassword'
import MyRequireNewPassword from 'components/auth/RequireNewPassword'
import MyVerifyContact from 'components/auth/VerifyContact'
import MyConfirmSignUp from 'components/auth/ConfirmSignUp'
import AcceptEula from 'components/auth/AcceptEula'
import MySignUp from 'components/auth/SignUp'
import theme from 'components/auth/theme'
import { ConfirmSignIn } from 'aws-amplify-react'

import Authenticator from './auth/Authenticator'
import { LockOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons'

const signUpConfig = {
  header: 'Setup your account',
  hideAllDefaults: false,
  defaultCountryCode: '+1',
  signUpFields: [
    {
      label: 'Email',
      key: 'username',
      required: true,
      displayOrder: 1,
      type: 'string',
      icon: MailOutlined,
    },
    {
      label: 'First name',
      key: 'given_name',
      required: true,
      displayOrder: 3,
      type: 'string',
      icon: UserOutlined,
    },
    {
      label: 'Last name',
      key: 'family_name',
      required: true,
      displayOrder: 4,
      type: 'string',
      icon: UserOutlined,
    },
    {
      label: 'Cell number',
      key: 'phone_number',
      required: true,
      displayOrder: 5,
      type: 'phone',
      icon: PhoneOutlined,
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      displayOrder: 2,
      type: 'password',
      icon: LockOutlined,
    },
  ],
}

const Auth = () => (
  <Authenticator usernameAttributes="email" signUpConfig={signUpConfig} theme={theme} hideDefault>
    <MyWorkspace key="enterWorkspace" />
    <MySignIn key="signIn" />
    <MyRequireNewPassword key="newPassword" />
    <MyForgotPassword key="forgotPassword" />
    <MyForgotWorkspace key="forgotWorkspace" />
    <MyConfirmSignUp key="confirmSignOut" />
    <MyVerifyContact key="verifyContact" />
    <ConfirmSignIn key="confirmSignIn" />
    <MySignUp key="signUp" signUpConfig={signUpConfig} />
    <AcceptEula key="acceptEula" />
  </Authenticator>
)

export default Auth
