/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Form, FormInstance, Input, Typography } from 'antd'
import { noop } from 'lodash'
import React from 'react'
import { smartfieldStore } from 'stores/smartfields'
import { GenericProviderErrorAlert } from '../../smartfield.alerts'
import { FormItemLabel } from '../../smartfield.styles'
import { ERRORS } from './provider.errors'

export interface ProviderSmartFieldsProps {
  // form instance to attach fields to
  form: FormInstance
  // the unique id of the smartfield
  uid: string
  // optional label to add at top
  label?: string
  // errors to display
  error?: string
  // show the children form items even if showing an error
  showOnError?: boolean
  // enable/disable overriding a value if an error occurs
  allowOverride?: boolean
  // props renderer for content to render even when error showing
  renderOnError?: (children: React.ReactElement) => React.ReactNode
}

export interface ProviderInternalProps {
  children: React.ReactNode
}

export const ProviderSmartFields: React.FC<ProviderSmartFieldsProps & ProviderInternalProps> = ({
  children,
  uid,
  label,
  error,
  allowOverride = true,
  renderOnError = noop,
}) => {
  return (
    <>
      {/* Error components */}
      {error && (
        <>
          <GenericProviderErrorAlert error={error} style={{ marginBottom: 16 }} testid="provider-error" />
          <br />
        </>
      )}

      {label && <Typography.Title level={5}>{label}</Typography.Title>}

      {renderOnError()}

      {/* Form Items required to be selected/filled by user, before document generation, for specific integration provider */}
      {!error && children}

      {/* This input field serves 2 purposes:
          1) In normal mode, it is hidden, but serves as the actual form field for carrying the merge field value.
          2) If not data exists, or there is any error, the input is displayed so a manual value can be added. */}
      {error !== ERRORS.INTEGRATION_ERROR && allowOverride && (
        <Form.Item
          name={uid}
          label={<FormItemLabel label="Manual Override" description="Add a manual override to build your document" />}
          data-testid="manual-override-input"
          hidden={!error}
          initialValue={smartfieldStore?.currentValue}
        >
          <Input size="large" autoFocus={!!error} />
        </Form.Item>
      )}
    </>
  )
}
