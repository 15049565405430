const channels = {
  WS_CHANGE: 'workspace:change',
  AUTH_INIT: 'auth:init',
  ADD_STRATEGY: 'strategies:add',
  MENTIONS_CLEAR: 'mentions:clear',
  EDITOR_SETCONTENT: 'editor:setContent',
  ADD_ASSET: 'assets:add',
  ADD_BLOCK: 'block:add:modal',
  REMOVE_BLOCK: 'block:remove:modal',
  BLOCK_DOWN: 'block:move:down',
  BLOCK_UP: 'block:move:up',
  SESSION_RESET: 'session:reset',
  CLIENT_UPDATE: 'client:update',
  APPT_UPDATE: 'appt:update',
  TOPIC_UPDATE: 'topic:update',
}

class EventBus {
  matchers = {}

  constructor() {
    Object.keys(channels).forEach((t) => (this[t] = channels[t]))
  }

  /**
   * Adds a event handler to be invoked when a post to this channel is received
   *
   * @param message Use one of the './handlers' enums
   * @param callback Function to invoke when message received
   */
  on = (event, callback, scope) => {
    if (!scope) {
      throw new Error('bus.on() requires callback scope')
    }
    // if no handlers for event type, create
    if (!this.matchers.hasOwnProperty(event)) {
      this.matchers[event] = []
    }
    // add the callback to the list of handlers
    // only if there isn't one already added
    const hndlrs = this.matchers[event]
    const hndlr = hndlrs.find((h) => h.callback === callback && h.scope === scope)
    if (!hndlr) {
      hndlrs.push({ event, callback, scope })
    }
    // return disposer
    return () => this.off(event, callback, scope)
  }

  /**
   * Removes a streaming handler from the list
   *
   * @param event Use one of the './handlers' enums
   * @param callback Function to invoke when event received
   */
  off = (event, callback, scope) => {
    if (!scope) {
      throw new Error('bus.off() requires callback scope')
    }
    let hndlrs = this.matchers[event]
    if (hndlrs && hndlrs.length) {
      hndlrs = hndlrs.filter((h) => h.callback !== callback && h.scope !== scope)
      this.matchers[event] = hndlrs
      // remove map if no handlers left
      if (!hndlrs.length) {
        delete this.matchers[event]
      }
    }
  }

  /**
   *
   */
  post = (event, data = {}) => {
    const hndlrs = this.matchers[event]
    if (hndlrs && hndlrs.length) {
      hndlrs.forEach((h) => {
        h.callback.apply(h.scope, [data])
      })
    }
  }

  /* ---------- private ---------- */
}

export default EventBus
