export const SmartFieldKind = {
  DATE: 'date',
  CONTACT: 'contact',
  CUSTOM: 'custom',
  SMARTFIELD: 'smartfield',
  PLACERHOLDER: 'placeholder',
  TOPIC: 'topic',
  USER: 'user',
} as const

/**
 * Used for SmartFields coming from an empty Flexible Field
 */
export const SMARTFIELD_NO_NAME = 'N/A'