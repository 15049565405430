import BaseMapper from './base'
import { Types } from '../utils'
import MergeMap from '../_merge-map'

class CustomMapper extends BaseMapper {
  static placeHolders = [{ id: 'custom-placeholder', label: 'Custom Placeholder' }]
  type = Types.CUSTOM

  /**
   * @param {*} options = {}
   */
  constructor(node: HTMLElement, options = {}, mergeMap: MergeMap) {
    super(node, options, mergeMap)
  }

  /**
   * Checks if this mapper matches the soon to be created mapper.
   * @param {*} options
   */
  match = (options: { id?: string; customText?: string } = {}) => {
    return this.label === options.customText
  }

  /**
   * Updates the custom value.
   * @param {*} value
   */
  update = async (value: any) => {
    this.value = value
    this.mergeMap?.replace(this)
  }
}

export default CustomMapper
