import styles from 'styles'

const AmplifyTheme = {
  toast: {
    borderTop: `2px solid ${styles.colors.error}`,
    position: 'absolute',
    bottom: -60,
    left: 0,
    right: 0,
    height: 60,
    top: 'unset',
    lineHeight: '60px',
    verticalAlign: 'center',
    padding: '0 30px',
    backgroundColor: styles.colors.black,
    textAlign: 'center',
    display: 'inline-block',
    fontFamily: 'proxima-nova',
    fontWeight: 600,
    fontSize: 18,
    animation: 'slideUp .5s normal forwards ease-in-out',
    overflowY: 'hidden',
    color: styles.colors.white,
  },
}

export default AmplifyTheme
