export const AUDIT = {
  appointment: 'appointment',
  block: 'block',
  collection: 'collection',
  files: 'files',
  note: 'note',
  auth: 'auth',
  practice: 'practice',
  scratch: 'scratch',
  session: 'session',
  tag: 'tag',
  task: 'task',
  taskTemplate: 'task library',
  template: 'library',
  workflow: 'workflow',
  workflowTemplate: 'workflow library',
}

const audit = (...args) => {
  const {
    audit: { log },
  } = global.data
  log.apply(log, args)
}
/**
 * Helper Function that checks if a note is audit (file sent by email)
 * @param {*} note
 * @returns { audit:boolean, uploaded:boolean }
 */
export function isAuditNote(note) {
  if (!note || !note.text) {
    return { audit: false, uploaded: false }
  }

  const sentOnText = note.text.includes(' - on ')
  if (!sentOnText) {
    return { audit: false, uploaded: false }
  }

  const SentByText = note.text.includes(' - was sent by ')
  const hasFileAttached = note.file1

  const uploadedAndSentByText = note.text.includes(' - was uploaded and sent by ')
  if (SentByText) {
    return { audit: true, uploaded: !hasFileAttached }
  } else {
    return { audit: uploadedAndSentByText, uploaded: uploadedAndSentByText }
  }
}

export default audit
