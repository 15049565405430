import { captureExceptionSilently } from 'helpers/sentry'
import { makeObservable, observable } from 'mobx'
import { PRECISEFP_RECIPIENT_TYPE } from './connections/precisefp/constants'
import precisefpService from './services/precisefp.service'

class PreciseFPStore {
  showModal = null
  _accounts = []
  _templates = []

  constructor() {
    makeObservable(this, {
      showModal: observable,
      _templates: observable,
      _accounts: observable,
    })
  }

  createEngagement = async ({ templateId, accountId, emailReminders, emailCompleted, recipientSecondary, auth }) => {
    const engagementArgs = {
      template_id: String(templateId),
      account_id: Number(accountId),
      recipient: PRECISEFP_RECIPIENT_TYPE.CLIENT,
      recipient_secondary: recipientSecondary,
      email_new: false,
      email_reminders: emailReminders,
      email_completed: emailCompleted,
      auth,
    }

    return precisefpService.createEngagement(engagementArgs)
  }

  listAccounts = async () => {
    try {
      global.app.loading = true
      this._accounts = await precisefpService.listAccounts()
      return this._accounts
    } catch (err) {
      captureExceptionSilently(err, { message: 'preciseFPListAccounts', data: {} })
      throw err
    } finally {
      global.app.loading = false
    }
  }

  listTemplates = async () => {
    try {
      global.app.loading = true
      this._templates = await precisefpService.listTemplates()
      return this._templates
    } catch (err) {
      captureExceptionSilently(err, { message: 'preciseFPListTemplates', data: {} })
      throw err
    } finally {
      global.app.loading = false
    }
  }

  searchAccount = async (query) => {
    try {
      global.app.loading = true
      const result = await precisefpService.listAccounts(query)
      return result
    } catch (err) {
      captureExceptionSilently(err, { message: 'preciseFPListAccounts', data: {} })
      throw err
    } finally {
      global.app.loading = false
    }
  }
}

export default PreciseFPStore
