import * as cheerio from 'cheerio'

export function htmlAdapter(froalaHTML: string) {
  const $ = cheerio.load(froalaHTML)
  emptyTH($)
  emptyTD($)
  tableTextAlign($)
  thBackgroundColor($)
  heading($)
  poorlyCreatedLists($)
  paragraphGray($)
  paragraphBordered($)
  paragraphUpper($)
  // fontSizes($) not running on the front end is cpu intensive

  return $('body').html() || ''
}

function emptyTD($: cheerio.CheerioAPI) {
  $('td > br').each(function (i, el) {
    if ($(this).siblings().length === 0) {
      $(this).remove()
    }
  })
}
function emptyTH($: cheerio.CheerioAPI) {
  $('th > br').each(function (i, el) {
    if ($(this).siblings().length === 0) {
      $(this).remove()
    }
  })
}

function heading($: cheerio.CheerioAPI) {
  const fontSizes = {
    h1: 28,
    h2: 21,
    h3: 16,
    h4: 14,
  } as const

  $('h1, h2, h3, h4').each(function (i, el) {
    const content = $(this).contents()
    const style = $(this).prop('style')
    const attrs = $(this).attr() || {}

    const tagName = ($(this).get(0)?.tagName || 'h4') as 'h1' | 'h2' | 'h3' | 'h4'

    const $p = $('<p></p>')
    const $span = $('<span></span>')

    if (style && style.length > 0 && style['font-size']) {
      $span.css('font-size', '' + style['font-size'])
    } else {
      $span.css('font-size', `${fontSizes[tagName]}px`)
    }

    $span.append(content)

    if (tagName === 'h1' || tagName === 'h2') {
      const $bold = $('<strong></strong>')
      $bold.append($span)
      $p.append($bold)
    } else {
      $p.append($span)
    }

    if (attrs) {
      $p.attr(attrs)
    }

    $(this).replaceWith($p)
  })
}

function poorlyCreatedLists($: cheerio.CheerioAPI) {
  $('ul > p').each(function (i, el) {
    if ($(this).text().trim() === '') {
      $(this).remove()
    }
  })
  $('ol > p').each(function (i, el) {
    if ($(this).text().trim() === '') {
      $(this).remove()
    }
  })
}

function paragraphGray($: cheerio.CheerioAPI) {
  $('p.fr-text-gray').each(function (i, el) {
    const content = $(this).contents()
    const style = $(this).prop('style')
    const attrs = $(this).attr() || {}

    const $p = $('<p></p>')
    const $span = $('<span></span>')

    if (style && style.length > 0 && style['color']) {
      $span.css('color', '' + style['color'])
    } else {
      $span.css('color', '#aaaaaa')
    }

    $span.append(content)

    $p.append($span)

    if (attrs) {
      $p.attr(attrs)
    }

    $(this).replaceWith($p)
  })
}

function paragraphBordered($: cheerio.CheerioAPI) {
  $('p.fr-text-bordered').each(function (i, el) {
    const $hrBefore = $('<hr />')
    const $hrAfter = $('<hr />')

    $(this).before($hrBefore)
    $(this).after($hrAfter)
  })
}

function paragraphUpper($: cheerio.CheerioAPI) {
  $('p.fr-text-uppercase').each(function (i, el) {
    const content = $(this).contents()
    const $p = $('<p></p>')
    const $span = $('<span></span>')
    const attrs = $(this).attr() || {}

    $span.append(content)

    $p.append($span)

    if (attrs) {
      $p.attr(attrs)
    }

    $span.css('text-transform', 'uppercase')
    $(this).replaceWith($p)
  })
}

function tableTextAlign($: cheerio.CheerioAPI) {
  $('td, th').each(function (i, el) {
    const cellAlign = $(this).css('text-align')
    const childAlign: string[] = []
    $(this)
      .children('div, p')
      .each(function (i, el) {
        const textAlign = $(this).css('text-align')
        if (textAlign) {
          childAlign.push(textAlign)
        }
      })

    if (childAlign.length) {
      if (childAlign[0] !== cellAlign) {
        $(this).css('text-align', childAlign[0])
      }
    }
  })
}

function thBackgroundColor($: cheerio.CheerioAPI) {
  $('th').each(function (i, el) {
    const backgroundColor = $(this).css('background-color')
    if (backgroundColor) {
      $(this).css('background-color', backgroundColor)
    } else {
      $(this).css('background-color', '#ececec')
    }
  })
}

function fontSizes($: cheerio.CheerioAPI) {
  $('*[style]').each(function (i, el) {
    const fontSize = $(this).css('font-size')
    if (fontSize) {
      const fontSizePx = convertFontSizeToPx(fontSize)
      if (fontSizePx) {
        console.log(`From: ${fontSize} to: ${fontSizePx}`)
        $(this).css('font-size', fontSizePx)
      }
    }
  })
}

function convertFontSizeToPx(fontSize: string) {
  const numberPattern = /\d+/g
  const fontSizeNumbers = fontSize.match(numberPattern)
  const defaultFontSize = 14

  const fontSizeNumber = fontSizeNumbers?.join('.')

  if (!fontSizeNumber) {
    return undefined
  }

  if (fontSize.includes('rem')) {
    const remToPx = Number(fontSizeNumber) * defaultFontSize
    return fontSize.replace(fontSizeNumber, '' + remToPx).replace('rem', 'px')
  } else if (fontSize.includes('em')) {
    const emToPx = Number(fontSizeNumber) * defaultFontSize
    return fontSize.replace(fontSizeNumber, '' + emToPx).replace('em', 'px')
  } else if (fontSize.includes('pt')) {
    const PIXELS_PER_INCH = 96
    const POINTS_PER_INCH = 72
    const ptToPx = Number(fontSizeNumber) * (PIXELS_PER_INCH / POINTS_PER_INCH)
    return fontSize.replace(fontSizeNumber, '' + ptToPx).replace('pt', 'px')
  } else {
    return fontSize
  }
}
