import * as apiService from 'lib/apollo/client'
import { EntityId } from 'types/entity.interface'
import {
  AddAppointmentDocument,
  AddAppointmentMutation,
  AddAppointmentMutationVariables,
  AppointmentInput,
  AppointmentsInRangeDocument,
  AppointmentsInRangeQuery,
  AppointmentsInRangeQueryVariables,
  ClientAppointmentByIdDocument,
  ClientAppointmentByIdQuery,
  ClientAppointmentByIdQueryVariables,
  ClientAppointmentsDocument,
  ClientAppointmentsQuery,
  ClientAppointmentsQueryVariables,
  DeleteAppointmentDocument,
  DeleteAppointmentMutation,
  DeleteAppointmentMutationVariables,
  GetAppointmentsCountDocument,
  GetAppointmentsCountQuery,
  GetAppointmentsCountQueryVariables,
  GetLastAndNextAppointmentsDocument,
  GetLastAndNextAppointmentsQuery,
  GetLastAndNextAppointmentsQueryVariables,
  UpdateAppointmentDocument,
  UpdateAppointmentMutation,
  UpdateAppointmentMutationVariables,
  UpdateInMeetingNotesDocument,
  UpdateInMeetingNotesMutation,
  UpdateInMeetingNotesMutationVariables,
} from 'types/graphql'
import DataError, { IsError } from '../../helpers/dataError'

class AppointmentService {
  async getAppointmentById(appointmentId: EntityId) {
    const { clientAppointmentById: resp } = await apiService.query<
      ClientAppointmentByIdQuery,
      ClientAppointmentByIdQueryVariables
    >(ClientAppointmentByIdDocument, { appointmentId }, { fetchPolicy: 'no-cache' })

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async getAppointmentByClientId(clientId: EntityId) {
    const { clientAppointments: resp } = await apiService.query<
      ClientAppointmentsQuery,
      ClientAppointmentsQueryVariables
    >(ClientAppointmentsDocument, { clientId }, { fetchPolicy: 'no-cache' })

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.appointments
  }

  async getLastAndNextAppointments(clientId: EntityId) {
    const { getLastAndNextAppointments: resp } = await apiService.query<
      GetLastAndNextAppointmentsQuery,
      GetLastAndNextAppointmentsQueryVariables
    >(GetLastAndNextAppointmentsDocument, { clientId }, { fetchPolicy: 'no-cache' })

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.appointments
  }

  async getAppointmentsInRange(start: string, end: string) {
    const { appointmentsInRange: resp } = await apiService.query<
      AppointmentsInRangeQuery,
      AppointmentsInRangeQueryVariables
    >(AppointmentsInRangeDocument, { start, end })

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.appointments
  }

  async getAppointmentsCount() {
    const { getAppointmentsCount: resp } = await apiService.query<
      GetAppointmentsCountQuery,
      GetAppointmentsCountQueryVariables
    >(GetAppointmentsCountDocument)

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.count
  }

  async createAppointment(payload: { appointment: AppointmentInput }) {
    const { addAppointment: resp } = await apiService.mutate<AddAppointmentMutation, AddAppointmentMutationVariables>(
      AddAppointmentDocument,
      payload,
    )

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async updateAppointment(payload: UpdateAppointmentMutationVariables) {
    const { updateAppointment: resp } = await apiService.mutate<
      UpdateAppointmentMutation,
      UpdateAppointmentMutationVariables
    >(UpdateAppointmentDocument, payload)

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async deleteAppointment(payload: DeleteAppointmentMutationVariables) {
    const { deleteAppointment: resp } = await apiService.mutate<
      DeleteAppointmentMutation,
      DeleteAppointmentMutationVariables
    >(DeleteAppointmentDocument, payload)

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async updateInMeetingNotes(payload: UpdateInMeetingNotesMutationVariables) {
    const { updateInMeetingNotes: resp } = await apiService.mutate<
      UpdateInMeetingNotesMutation,
      UpdateInMeetingNotesMutationVariables
    >(UpdateInMeetingNotesDocument, payload)

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }
}

export default new AppointmentService()
