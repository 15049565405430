export const rules = [
  {
    message: 'Password must be 8 or more characters',
    min: 8,
  },
  // {
  //   message: 'Password must have 1 or more upper case',
  //   pattern: /.*[A-Z].*/
  // },
  {
    message: 'Password must have 1 or more lower case',
    pattern: /.*[a-z].*/,
  },
  {
    message: 'Password must have at least 1 digit',
    pattern: /.*[0-9].*/,
  },
]
