/* eslint-disable no-underscore-dangle */
import { FilterTypes } from 'components/routes/tasks/notes/notes.constants'
import { queryParams as qp } from 'helpers/href'
import { BrowserHistory, Listener, Update, createBrowserHistory } from 'history'
import { get, isEmpty } from 'lodash'
import { computed, makeObservable, observable, observe } from 'mobx'
import qs from 'qs'
import type { Location, RouteProps } from 'react-router'
import { matchPath } from 'react-router'

class AppRouter {
  history: BrowserHistory = createBrowserHistory()
  lastLocation: Partial<Location> = { search: '' }
  location: Partial<Location> = { search: '' }
  _initialLocation?: Partial<Location>
  _historyPerRoute: Record<string, string> = {}

  /**
   * location.pathname shortcut
   */
  get path() {
    const path = this.location?.pathname
    return path ?? ''
  }

  get pathQuery() {
    const path = this.path
    const query = this.location?.search
    return `${path}${query ?? ''}`
  }

  /**
   * location.pathname shortcut
   */
  get initialPath() {
    return get(this._initialLocation, 'pathname', '')
  }

  /**
   * location.pathname shortcut - mo query, no leading/trailing slashes
   */
  get pathname() {
    const path = this.path.split('?')[0]
    return path.replace(/^\/+|\/+$/g, '')
  }

  /**
   * location.pathname shortcut - mo query, no leading/trailing slashes
   */
  get initialPathname() {
    const path = this.initialPath.split('?')[0]
    return path.replace(/^\/+|\/+$/g, '')
  }

  /**
   * location.search shortcut - parsed object
   */
  get params() {
    const { search = '' } = this.location
    const query = isEmpty(search) ? '' : search
    return qp(query.replace(/^\?/, ''))
  }

  /**
   * location.search shortcut - parsed object
   */
  get initialParams() {
    if (this._initialLocation) {
      const { search = '' } = this._initialLocation
      const query = isEmpty(search) ? '' : (search as string)
      return qp(query.replace(/^\?/, ''))
    }

    return ''
  }

  /**
   * location.search shortcut - parsed object
   */
  get hash() {
    const { hash = '' } = this.location
    const val = isEmpty(hash) ? '' : hash
    // eslint-disable-next-line
    return qp(val.replace(/^\#/, ''))
  }

  get state() {
    return get(this.location, 'state', {})
  }

  constructor() {
    makeObservable(this, {
      history: observable.ref,
      lastLocation: observable.ref,
      location: observable,
      path: computed,
      pathQuery: computed,
      initialPath: computed,
      pathname: computed,
      initialPathname: computed,
      params: computed,
      initialParams: computed,
      hash: computed,
      state: computed,
    })

    this.location = this.history.location
    this.history.listen(this._onLocationChange)
    this.history.listen(this._onRouteChange)
    // this._onLocationChange(this.history.location)

    // router should always start on homepage unless sso enabled
    // const notWS = this.history.location.pathname !== '/workspace'
    // if (!workspace.sso && notWS) this.goto('/')
  }

  /* ---------- public ---------- */

  /**
   * Watches for matching route changes
   */
  watch = (path: any, callback: any) => {
    const config =
      typeof path === 'string'
        ? {
            path,
            exact: true,
          }
        : path

    let oldParams = {}

    return observe(
      this,
      'params',
      (c) => {
        const match = this.match(this.path, config)
        if (match) {
          callback(this.params, oldParams)
          oldParams = this.params
        }
      },
      true,
    )
  }

  /**
   * Store the initial entry point location
   */
  set initialLocation(loc: Partial<Location>) {
    this._initialLocation = loc
  }

  /* ---------- public ---------- */

  /**
   * Pick up the original intended route, after any redirects such as for Authentication
   */
  get initialLocation(): Partial<Location> {
    if (this.useInitial() && this._initialLocation) {
      return this._initialLocation
    }

    return {
      pathname: global.app.homePage,
      hash: '',
      search: '',
      state: {},
    }
  }

  /**
   * Use the initial location as start page?
   */
  useInitial() {
    if (!this._initialLocation) {
      return false
    }
    const path = this._initialLocation.pathname
    const blacklist = ['/', '/workspace', global.app.homePage]
    return blacklist.every((r) => r !== path)
  }

  /**
   * Sends browser to specified path
   * @param pathname The path of the URL
   * @param search The URL query string
   * @param state Some extra state for this location
   * @param hash The URL hash fragment
   *
   * either:
   *  .goto() // navigate to homepage
   *  .goto({ pathname: 'dashboard', state: { a: 'A' }) // use location object
   *  .goto(pathname, state) // pathname, state params
   */
  goto = (...args: Partial<Location>[] | string[]) => {
    switch (true) {
      // homepage
      case !args.length:
        this.history.push('/')
        break
      // location object
      case typeof args[0] !== 'string':
        this.history.push(args[0])
        break
      // pathname, state params
      default:
        this.history.push(args[0], args[1] || {})
        break
    }
  }

  /**
   * @param clientId
   * @param eventId
   */
  gotoEvent = (
    clientId: any,
    eventId: any,
    filter: string = FilterTypes.NOTES,
    goto: boolean = true,
    searchParams?: string,
  ) => {
    const path = `/notes/client?userId=${clientId}&filter=${filter}&apptId=${eventId}${
      searchParams ? '&' + searchParams : ''
    }`
    if (goto) {
      this.goto(path)
    }
    return path
  }

  /**
   * @param clientId
   */
  gotoClient = (clientId: any, filter: string = FilterTypes.NOTES, goto: boolean = true) => {
    const path = `/notes/client?userId=${clientId}&filter=${filter}`
    if (goto) {
      this.goto(path)
    }
    return path
  }

  /**
   * @param params
   */
  gotoParams = (params: Record<any, any>, merge = true) => {
    const newParams = merge ? { ...global.router.params, ...params } : { ...params }
    const filteredParams = Object.keys(newParams).reduce((all, key) => {
      if (newParams[key] !== 'DELETE') {
        ;(all as any)[key] = newParams[key]
      }
      return all
    }, {})

    console.log(`🐛 -> RouteParams`, JSON.stringify(filteredParams))

    this.goto(`${this.path}?${qs.stringify(filteredParams)}`)
  }

  /**
   * Sends browser to home page
   */
  goHome = () => {
    this.goto(global.app.homePage)
  }

  /**
   *
   */
  gotoIntended = () => {
    if (this.useInitial()) {
      this.goto(this.initialLocation)
    }
  }

  /**
   * Opens a new browser tab/window for a route
   */
  open = (pathname: string, target = '_blank', focus = true) => {
    const path = this.history.createHref({ pathname })
    const win = window.open(path, target)
    if (focus) {
      win?.focus()
    }
  }

  /**
   * Helper to match paths to Routes
   * @param path pathname to match
   * @param params object containing the following default options:
   *  { path = '/', exact = false, strict = false, sensitive = false }
   * @returns {*}
   */
  match = (path: string, params: any | RouteProps) => matchPath(params, path)

  /**
   * Replace the url state with a non-hash, non-query url
   */
  dehash = () => {
    this.history && this.history.replace(window.location.pathname)
  }

  /* ---------- private ---------- */

  _onLocationChange: Listener = ({ location }: Update) => {
    this.lastLocation = this.location
    this.location = location

    this.registerSearchHistoryPerRoute(location)
  }

  /**
   * Action route change analytics/updates etc
   */
  _onRouteChange = ({ location }: Update) => {
    global.log(`Route: ${location.pathname}`)
    this.registerSearchHistoryPerRoute(location)
  }

  registerSearchHistoryPerRoute = (state: Partial<Location>) => {
    if (!state || !state.pathname) {
      return
    }
    const params = new URLSearchParams(state.search).toString()
    if (!params) {
      return
    }
    this._historyPerRoute[state.pathname] = params
  }

  getLastParamsOfRoute = (pathname?: string): string => {
    const path = pathname || this.location?.pathname

    if (path && this._historyPerRoute[path]) {
      return this._historyPerRoute[path]
    }

    return ''
  }

  getParamsFromLocation(location: Partial<Location>) {
    const { search = '' } = location
    const query = isEmpty(search) ? '' : search
    return qp(query.replace(/^\?/, ''))
  }
}

export default AppRouter
