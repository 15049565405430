/* eslint-disable import/prefer-default-export, no-unused-expressions */
import { forEach } from 'lodash'
import { action, makeObservable, observable, toJS } from 'mobx'
import { observer } from 'mobx-react'
import { Component } from 'react'

export class AbstractStore {
  _superInitialState = {}
  props = {}
  constructor(props) {
    makeObservable(this, {
      props: observable.ref,
      storeState: action,
    })

    this._disposers = []
    this.props = props
  }

  /**
   * https://github.com/mobxjs/mobx/issues/814#issuecomment-446797155
   */
  storeState = (scope) => {
    this._superInitialState = toJS(scope || this)
    this._disposers.push(
      global.bus.on(
        global.bus.SESSION_RESET,
        () => {
          const initialState = { ...this._superInitialState }
          console.log(`Draining: ${Object.getPrototypeOf(this).constructor.name}`)
          for (const key in initialState) {
            if (!initialState.hasOwnProperty(key)) {
              continue
            }
            if (key === '_superInitialState') {
              continue
            }
            if (key === '_disposer') {
              continue
            }
            this[key] = initialState[key]
            this.restoreState()
          }
        },
        this,
      ),
    )
  }

  /**
   * Hook to add additional state rebuilding logic
   * @Override super
   */
  restoreState = () => {}
}

/**
 * @param {*} Presenter
 */
export const pmShape = (Presenter) => ({
  pm(props, propName, componentName) {
    if (!Presenter) {
      return new Error(`No Presenter Class ${Presenter.type} supplied to ${componentName}.`)
    }
    if (!(Presenter.prototype instanceof AbstractStore)) {
      return new Error(
        `Invalid Presenter Class ${Presenter.type} supplied to ${componentName}.` +
          `All Presenter PM's must subclass AbstractStore`,
      )
    }
  },
})

/**
 * Observer function / decorator
 */
export const connect = (Presenter, View, statics) => {
  if (Presenter && View) {
    const ViewComp = observer(View)
    class WrappedView extends Component {
      static View = View
      constructor(props) {
        super(props)
        this.allProps = Object.assign({}, View.defaultProps, props)
        this.pm = props.pm || new Presenter(this.allProps, this)
        this.view = View
      }

      componentDidMount() {
        this.pm.mounted && this.pm.mounted(this.props)
      }

      componentWillUnmount() {
        this.pm._disposers.forEach((disposer) => disposer())
        this.pm.unmounted && this.pm.unmounted()
      }

      shouldComponentUpdate(nextProps, nextState) {
        if (this.pm.shouldComponentUpdate) {
          return this.pm.shouldComponentUpdate(nextProps, nextState)
        }
        return true
      }

      render() {
        return <ViewComp {...this.props} pm={this.pm} />
      }
    }
    WrappedView.propTypes = {
      ...pmShape(Presenter),
    }
    if (statics) {
      // eslint-disable-next-line
      forEach(statics, (val, key) => (WrappedView[key] = val))
    }
    return WrappedView
  }

  // invoked as a decorator
  return (componentClass) => connect(Presenter, componentClass, statics)
}
