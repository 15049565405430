import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale-provider/en_US'
import { observer } from 'mobx-react'
import React from 'react'
import { GlobalStyle } from 'styles'
import App2 from './App'
import Auth from './AuthWrapper'

class Index extends React.Component {
  constructor() {
    super()
    global.router.initialLocation = global.router.history && global.router.history.location
  }

  render() {
    const { loggedIn } = global.stores.auth
    const Comp = loggedIn ? App2 : Auth
    return (
      <ConfigProvider locale={enUS}>
        <React.Fragment>
          <GlobalStyle />
          <Comp />
        </React.Fragment>
      </ConfigProvider>
    )
  }
}

export default observer(Index)
