import * as apiService from 'lib/apollo/client'
import {
  RiskalyzeClient,
  RiskalyzeCreateQuestionnaireDocument,
  RiskalyzeCreateQuestionnaireMutation,
  RiskalyzeCreateQuestionnaireMutationVariables,
  RiskalyzeFindClientsDocument,
  RiskalyzeFindClientsQuery,
  RiskalyzeFindClientsQueryVariables,
  RiskalyzeGetClientDocument,
  RiskalyzeGetClientQuery,
  RiskalyzeGetClientQueryVariables,
  RiskalyzeGetModelsDocument,
  RiskalyzeGetModelsQuery,
  RiskalyzeGetModelsQueryVariables,
  RiskalyzeModel,
  RiskalyzeQuestionnaireInput,
} from 'types/graphql'
import DataError, { IsError } from '../../helpers/dataError'

export const findClients = async () => {
  const { riskalyzeFindClients: resp } = await apiService.mutate<
    RiskalyzeFindClientsQuery,
    RiskalyzeFindClientsQueryVariables
  >(RiskalyzeFindClientsDocument, {})
  if (IsError(resp)) {
    throw new DataError(resp)
  }

  return resp as RiskalyzeClient[]
}

export const getClient = async (clientId: string): Promise<any> => {
  const { riskalyzeGetClient: resp } = await apiService.query<
    RiskalyzeGetClientQuery,
    RiskalyzeGetClientQueryVariables
  >(RiskalyzeGetClientDocument, {
    clientId,
  })

  if (IsError(resp)) {
    throw new DataError(resp)
  }

  return resp as any
}

export const createQuestionnaire = async (questionnaire: RiskalyzeQuestionnaireInput) => {
  const { riskalyzeCreateQuestionnaire: resp } = await apiService.mutate<
    RiskalyzeCreateQuestionnaireMutation,
    RiskalyzeCreateQuestionnaireMutationVariables
  >(RiskalyzeCreateQuestionnaireDocument, {
    questionnaire,
  })
  if (IsError(resp)) {
    throw new DataError(resp)
  }

  return resp?.link
}

export const getModels = async (): Promise<RiskalyzeModel[]> => {
  const { riskalyzeGetModels: resp } = await apiService.query<
    RiskalyzeGetModelsQuery,
    RiskalyzeGetModelsQueryVariables
  >(RiskalyzeGetModelsDocument)

  if (IsError(resp)) {
    throw new DataError(resp)
  }

  return resp as RiskalyzeModel[]
}
