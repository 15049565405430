import { CalendarOutlined, CarryOutOutlined } from '@ant-design/icons'
import { blockToHtml } from 'components/froala/util'
import { clearImagesURLOnBody } from 'helpers/file'
import { AbstractStore } from 'helpers/pm'
import { trackEvent } from 'helpers/posthog'
import { captureExceptionSilently } from 'helpers/sentry'
import { remirrorJSONToHtml } from 'lib/remirror/util/remirror.util'
import { orderBy, sortBy } from 'lodash'
import { action, computed, makeObservable, observable, observe, remove } from 'mobx'
import { msg } from 'stores/msg'
import { EntityId } from 'types/entity.interface'
import { Block, BlockInput, Template, TemplateCategory, TemplateInput } from 'types/graphql'
import blockService from '../services/block.service'
import templateService from '../services/template.service'

export const TemplateIcons = {
  'task-summary': {
    type: CarryOutOutlined,
    color: 'text',
    bgColor: 'lightGreen',
  },
  'task-agenda': {
    type: CalendarOutlined,
    color: 'text',
    bgColor: 'lightBlue',
  },
}

export type LocalTemplateCategory = Omit<TemplateCategory, 'templates'>

export type LocalTemplate = Template & {
  category: Omit<TemplateCategory, 'templates'>
}
export type LocalBlock = Block & { fullyLoaded: boolean }

class Templates extends AbstractStore {
  templateCategories: Omit<TemplateCategory, 'templates'>[] = []

  /** Array containing all templates */
  templates: LocalTemplate[] = []

  /** Object with all blocks, with each key being the block id */
  templateBlocks: Record<EntityId, LocalBlock> = {}

  /** Object containing a key with the templateId, and a value of an array with its blocks ids */
  blocksByTemplate: Record<EntityId, EntityId[]> = {}

  selectedTemplate: LocalTemplate | null
  loading = true

  constructor() {
    super()

    makeObservable(this, {
      blocks: computed,
      templateCategories: observable,
      templates: observable,
      templateBlocks: observable,
      selectedTemplate: observable,
      onCreateTemplate: action.bound,
      onDeleteTemplate: action.bound,
      onUpdateTemplate: action.bound,
      findOtherDefaultsOnCategory: action.bound,
      markTemplateAsDefaultForCategory: action.bound,
      getTemplate: action.bound,
      loadTemplateByCategoryId: action.bound,
      getTemplateBlocksById: action.bound,
      addBlockToTemplate: action.bound,
      removeBlockFromTemplate: action.bound,
      updateTemplateBlockOrder: action.bound,
      onBlockReplace: action.bound,
      onBlockUpdate: action.bound,
      onBlockCreate: action.bound,
      onBlockDelete: action.bound,
      loadBlocks: action.bound,
      loadFullBlock: action.bound,
      setTemplate: action.bound,
      addTemplateCategory: action.bound,
      loading: observable,
    })

    observe(
      global.stores.auth,
      'loggedIn',
      (c) => {
        if (c.newValue) {
          this.loadData()
        }
      },
      true,
    )
    this.storeState()
  }

  /**
   * @param param0
   */
  setTemplate = (template: LocalTemplate) => {
    this.selectedTemplate = template
  }

  /**
   * Unload current template
   */
  unloadTemplate = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.selectedTemplate = undefined
  }

  get blocks() {
    return Object.values(this.templateBlocks)
  }

  async loadData() {
    this.loading = true
    this.templates = []
    this.templateCategories = []
    this.templateBlocks = {}
    this.blocksByTemplate = {}
    try {
      const [templateCategories, blocks] = await Promise.all([
        templateService.allCategoryTemplates(),
        blockService.getAllBlocks(),
      ])

      this.templateCategories = (this.orderTemplateCategories(templateCategories) as TemplateCategory[]).map(
        ({ templates, ...category }) => {
          const name = String(category.name).replace(' Docs', '')
          const categoryType = category.type! as keyof typeof TemplateIcons
          const icon = TemplateIcons[categoryType]
          return { ...category, name, icon }
        },
      )

      const templates = templateCategories.reduce<Template[]>((acum, dataItem) => [...acum, ...dataItem.templates], [])

      this.setLoadedBlocks(blocks, true)
      this.setTemplates(templates)

      templates.map((template) => {
        const templateId = template.id
        const templateBlocksIds = template.blocks?.map((block) => block.id) || []

        if (Number.isInteger(templateId) && templateBlocksIds.length) {
          const blocksWithOrder = blocks
            .filter((block) => templateBlocksIds.includes(block.id))
            .map((block) => ({ ...block, order: template.blocks?.find((b) => block.id === b.id)?.order }))
          this.setBlocksByTemplate(templateId as number, blocksWithOrder)
        }
      })
    } catch (error) {
      this.templateCategories = []
      msg.error(
        "There was an error fetching your templates. We'll take a look. Meanwhile try refreshing the page!",
        undefined,
      )
      captureExceptionSilently(error, { message: 'loadData', data })
    } finally {
      this.loading = false
    }
  }

  setTemplates(data: Template[]) {
    const lookup = this.templateCategories.reduce<Record<string, Omit<TemplateCategory, 'templates'>>>(
      (acum, val) => ({ ...acum, [val.id]: val }),
      {},
    )

    const templatesWithCategory = data.map((t) => ({ ...t, category: lookup[t.templateCategoryId!] }))

    this.sortTemplatesWithCategory(templatesWithCategory)
    // this.templates = sortBy(templatesWithCategory, ['name'])
  }

  sortTemplatesWithCategory = (templates: LocalTemplate[]) => {
    this.templates = templates
      // @ts-ignore
      .sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
      // @ts-ignore
      .sort((a, b) => (a.isDefault ? -1 : 1))
  }

  /** >>>>>>>>>>>           TEMPLATE METHODS                 ---------------------------- */

  async onCreateTemplate(template: TemplateInput) {
    const templateCreated = await templateService.addTemplate({ template })
    this.setTemplates([...this.templates, templateCreated])

    return templateCreated
  }

  async onDeleteTemplate(id: EntityId) {
    const templateToDeleteIndex = this.templates.findIndex((o) => o.id === id)
    if (templateToDeleteIndex === -1) {
      return
    }
    const templateToDelete = this.templates[templateToDeleteIndex]
    try {
      await templateService.deleteTemplate({ id })
      this.setTemplates(this.templates.filter((t) => t.id !== id))
      if (templateToDelete.isDefault) {
        // @ts-ignore
        this.replaceDefaultTemplateIfTemplateToDeleteWasDefault(templateToDelete)
      }
      msg.success('Template deleted')
    } catch (err) {
      msg.error('Failed to delete template', undefined)
      captureExceptionSilently(err, {
        message: 'onDeleteTemplate',
        data: { id },
      })
      throw err
    }
  }

  async replaceDefaultTemplateIfTemplateToDeleteWasDefault({
    templateCategoryId,
    id,
  }: {
    templateCategoryId: EntityId
    id: EntityId
  }) {
    const newDefault = this.templates.find((t) => t.templateCategoryId === templateCategoryId && t.id !== id)
    return newDefault && this.markTemplateAsDefaultForCategory(+newDefault.id)
  }

  async onUpdateTemplate(id: EntityId, template: TemplateInput) {
    const localTemplate = this.templates.find((o) => o.id === id)

    if (!localTemplate) {
      return
    }

    // FIXME: if this fails, then the caller crashes
    // added a try/catch to log the error but it throws anyway
    try {
      await templateService.updateTemplate({ id, template })
      return Object.assign(localTemplate, template)
    } catch (error) {
      msg.error('There was an error updating your template', undefined)
      captureExceptionSilently(error, {
        message: 'onUpdateTemplate',
        data: { id, template },
      })
      throw error
    }
  }

  // FIXME: there is a bug where multiple templates can be default - we also use this function to find them all and fix them
  findOtherDefaultsOnCategory(newDefaultTemplate: Template, newDefaultTemplateId: EntityId) {
    if (newDefaultTemplate && newDefaultTemplate.isDefault && newDefaultTemplate.templateCategoryId) {
      return this.templates.filter(
        (t) =>
          t.templateCategoryId === newDefaultTemplate.templateCategoryId &&
          t.isDefault &&
          t.id !== newDefaultTemplateId,
      )
    }
    return null
  }

  removeOtherDefaultsOnCategory(newDefaultTemplate: Template, newDefaultTemplateId: EntityId) {
    const otherDefaults = this.findOtherDefaultsOnCategory(
      { ...newDefaultTemplate!, isDefault: true },
      newDefaultTemplateId,
    )

    const removeOldDefaultPromises = otherDefaults
      ? otherDefaults.map((oldDefaultTemplate) => this.onUpdateTemplate(+oldDefaultTemplate.id, { isDefault: false }))
      : []

    return removeOldDefaultPromises
  }

  async markTemplateAsDefaultForCategory(id: EntityId) {
    const template = this.templates.find((t) => t.id === id)
    if (!template) {
      msg.error('Template was not found', undefined)
      return
    }

    const removeOldDefaultPromises = this.removeOtherDefaultsOnCategory(template, +template.id)

    await Promise.all([this.onUpdateTemplate(id, { isDefault: true }), ...removeOldDefaultPromises])
    this.sortTemplatesWithCategory(this.templates)
    msg.success('Template marked as default')
  }

  async onDuplicateTemplate(id: EntityId, templateData: TemplateInput) {
    const newTemplate = await templateService.duplicateTemplate({ id, templateData })
    this.setTemplates([...this.templates, newTemplate])

    // Could be async but just to make sure it's done before the next step
    if (templateData.isDefault) {
      await Promise.all(this.removeOtherDefaultsOnCategory(newTemplate, +newTemplate.id))
    }

    return newTemplate
  }

  /** >>>>>>>>>>>           TEMPLATE BLOCK METHODS                 ---------------------------- */

  /**
   * Load a template and it's blocks. Lazy loaded (it loads as it needs)
   */
  async getTemplate(templateId: EntityId, forceFetch = false): Promise<LocalTemplate | null> {
    if (!Number.isInteger(templateId)) {
      return null
    }
    // this can be further improved by loading all the
    if (!this.blocksByTemplate[templateId] || forceFetch) {
      try {
        const data = await blockService.getTemplateBlocks(templateId)

        this.setBlocksByTemplate(templateId, data)
      } catch (err) {
        if (err instanceof Error) {
          msg.error(err.message, undefined)
        }
        captureExceptionSilently(err, {
          message: 'getTemplateBlocks',
          data: { templateId },
        })
      }
    }

    const template = this.templates.find(({ id }) => Number(templateId) === id)

    if (!template) {
      return null
    }

    return { ...template, blocks: this.getTemplateBlocksById(templateId) }
  }

  /**
   * @param categoryId
   */
  getTemplateCategory = (categoryId: string) => {
    return this.templateCategories.find((template) => template.id === categoryId)
  }

  async loadTemplateByCategoryId(categoryId: EntityId) {
    const template = this.templates
      .filter((t) => t.templateCategoryId === Number(categoryId))
      .sort((a, b) => +b.isDefault! - +a.isDefault!)[0]

    return this.getTemplate(+template.id)
  }

  getTemplateBlocksById(templateId: EntityId) {
    return (this.blocksByTemplate[templateId] || [])
      .map((blockId) => this.templateBlocks[blockId])
      .filter(Boolean)
      .map((block, order) => ({ ...block, order }))
  }

  async addBlockToTemplate(templateId: EntityId, blockId: EntityId, silenced: boolean) {
    try {
      const blocks = await blockService.addBlockToTemplate(templateId, blockId)
      trackEvent('Block added to template', { templateId, blockId })
      !silenced && msg.success('Block added to template')
      this.blocksByTemplate[templateId] = sortBy(blocks, ['order']).map(({ id }) => +id)
      const templateIndex = this.templates.findIndex((t) => t.id === templateId)
      if (templateIndex) {
        this.setTemplates([
          ...this.templates.slice(0, templateIndex),
          {
            ...this.templates[templateIndex],
            blocks: sortBy(blocks, ['order']),
          },
          ...this.templates.slice(templateIndex + 1),
        ])
      }
    } catch (err) {
      captureExceptionSilently(err, {
        message: 'addBlockToTemplate',
        data: { templateId, blockId },
      })
      if (err instanceof Error) {
        msg.error(err.message, undefined)
      }
    }
    return this.getTemplateBlocksById(templateId)
  }

  async removeBlockFromTemplate(templateId: EntityId, blockId: EntityId, silenced: boolean) {
    try {
      await blockService.deleteBlockFromTemplate(templateId, blockId)
      trackEvent('Block removed from template', { templateId, blockId })
      !silenced && msg.success('Block removed from Template')
      this.blocksByTemplate[templateId] = this.blocksByTemplate[templateId].filter((id) => blockId !== id)
    } catch (err) {
      captureExceptionSilently(err, {
        message: 'deleteBlockFromTemplate',
        data: { templateId, blockId },
      })
      if (err instanceof Error) {
        msg.error(err.message, undefined)
      }
    }
    return this.getTemplateBlocksById(templateId)
  }

  async updateTemplateBlockOrder(templateId: EntityId, templateBlockIds: EntityId[], silenced: boolean) {
    try {
      const updatedId = await blockService.reorderBlocks(templateId, templateBlockIds)
      !silenced && msg.success('Block order Updated')
      this.blocksByTemplate[templateId] = templateBlockIds
      const template = this.templates.find((t) => t.id === templateId)
      if (template) {
        template.blocks = templateBlockIds.map((id, order) => ({ ...this.templateBlocks[id], order }))
      }
      return updatedId
    } catch (err) {
      captureExceptionSilently(err, {
        message: 'reorderBlocks',
        data: { templateId, templateBlockIds },
      })
      if (err instanceof Error) {
        msg.error(err.message, undefined)
      }
    }
    return null
  }

  async onBlockReplace(
    templateId: EntityId,
    blockToReplaceId: EntityId,
    { markup, name: newName, content }: { markup: string; name: string; content: string },
  ) {
    const oldOrder = this.blocksByTemplate[templateId]
    const oldBlockIndex = oldOrder.findIndex((id) => id === blockToReplaceId)
    const templateBlock = this.templateBlocks[blockToReplaceId]
    if (!templateBlock) {
      throw new Error('Block was not found')
    }

    const block = await this.onBlockCreate({ markup, name: newName, desc: templateBlock.desc!, content }, true)
    if (!block) {
      // FIXME: easy fix but why do we return a null?
      return null
    }

    this.removeBlockFromTemplate(templateId, blockToReplaceId, true)

    const blockId = +block.id
    await this.addBlockToTemplate(templateId, blockId, false)
    const newOrder = [...oldOrder.slice(0, oldBlockIndex), blockId, ...oldOrder.slice(oldBlockIndex + 1)]
    const newBlocks = await this.updateTemplateBlockOrder(templateId, newOrder, false)
    if (newBlocks) {
      msg.success('New block copy created in place!')
      return newBlocks
    }
    return null
  }

  newUniqueBlockName(name: string, index = 0, blocksByName: Record<string, LocalBlock>): string {
    if (!index) {
      const numberedBlockRegex = /(\s\((\d+)\))$/g
      const numberedBlockReplacer = /(\s\((\d+)\))/g
      const check = numberedBlockRegex.exec(name)
      if (check && check.length && Number.isInteger(Number(check[check.length - 1]))) {
        index = Number(check[check.length - 1])
        name = name.replaceAll(numberedBlockReplacer, '')
      }
    }

    const nameToTry = `${name}${index ? ` (${index})` : ''}`
    const blocks =
      blocksByName ||
      Object.values(this.templateBlocks).reduce<Record<string, LocalBlock>>(
        (acum, block) => ({ ...acum, [block.name!]: { ...block } }),
        {},
      )
    if (blocks[nameToTry]) {
      return this.newUniqueBlockName(name, index + 1, blocks)
    }
    return nameToTry
  }

  /** >>>>>>>>>>>           BLOCK METHODS                 ---------------------------- */

  async loadBlocks() {
    try {
      const data = await blockService.getAllBlocks()
      this.setLoadedBlocks(data, true)
      return this.templateBlocks
    } catch (err) {
      captureExceptionSilently(err, { message: 'loadBlocks', data: {} })
      if (err instanceof Error) {
        msg.error(err.message, undefined)
      }
    }
  }

  async loadFullBlock(blockId: EntityId, forceFetch: boolean) {
    if (!Number.isInteger(blockId)) {
      return null
    }
    if (!this.templateBlocks[blockId]?.fullyLoaded || forceFetch) {
      try {
        const data = await blockService.getTemplateBlock(blockId)
        this.templateBlocks[blockId] = {
          ...this.templateBlocks[blockId],
          ...data,
          fullyLoaded: true,
        }
      } catch (err) {
        captureExceptionSilently(err, {
          message: 'loadFullBlock',
          data: { blockId },
        })
      }
    }
    return this.templateBlocks[blockId]
  }

  async onBlockUpdate(blockId: EntityId, fields: BlockInput) {
    if (fields.markup) {
      fields.markup = clearImagesURLOnBody(fields.markup)
    }

    if (fields.content) {
      fields.content = clearImagesURLOnBody(fields.content)
    }

    try {
      const updatedBlock = await blockService.updateBlock(blockId, fields)
      this.templateBlocks[blockId] = {
        ...this.templateBlocks[blockId],
        ...fields,
        ...updatedBlock,
      }
      msg.success('Block Saved')
    } catch (err) {
      captureExceptionSilently(err, {
        message: 'updateBlock',
        data: { blockId, fields },
      })
      if (err instanceof Error) {
        msg.error(err.message, undefined)
      }
    }
    return this.templateBlocks[blockId]
  }

  async onBlockCreate(
    { markup: rawMarkup, name, desc, content }: { markup: string; name: string; desc: string; content: string },
    silenced: boolean,
  ) {
    const markup = clearImagesURLOnBody(rawMarkup)
    try {
      const createdBlock = await blockService.createBlock(name, markup, desc, content)
      const blockId = Number(createdBlock.id!)
      this.templateBlocks[blockId] = { ...createdBlock, fullyLoaded: true }
      !silenced && msg.success('Block Created')
      return this.templateBlocks[blockId]
    } catch (err) {
      captureExceptionSilently(err, {
        message: 'createBlock',
        data: { markup, name, desc },
      })
      if (err instanceof Error) {
        msg.error(err.message, undefined)
      }
    }
    return null
  }

  async checkBlockUsage(blockId: EntityId) {
    try {
      const response = await blockService.checkBlockUsage(blockId)
      if (response) {
        const { templateIds, usageCount } = response
        const templateNames = this.templates
          .filter((t) => templateIds.includes(t.id))
          .map((t) => t.name || '')
          .filter(Boolean)
        return { templateIds, usageCount, templateNames }
      }
      return { templateIds: [], usageCount: 0 }
    } catch (err) {
      throw err
    }
  }

  async onBlockDelete(blockId: EntityId) {
    try {
      await blockService.deleteBlock(blockId)
      remove(this.templateBlocks, '' + blockId)
      msg.success('Block deleted')
    } catch (err) {
      msg.error(`The block was not deleted, because it's being used in some template at the moment`, undefined)
      throw new Error(`The block was not deleted, because it's being used in some template at the moment`)
    }
    return this.templateBlocks
  }

  private setBlocksByTemplate(templateId: number, data: Block[] = []) {
    this.blocksByTemplate[templateId] = sortBy(data, ['order']).map(({ id }) => +id)
  }

  private setLoadedBlocks(blocks: Block[], isFullyLoaded: boolean) {
    this.templateBlocks = blocks.reduce(
      (acum, block) => ({
        ...acum,
        [block.id]: {
          ...block,
          ...(this.templateBlocks[Number(block.id!)] || {}),
          fullyLoaded: isFullyLoaded,
        },
      }),
      this.templateBlocks,
    )
  }

  /** >>>> TEMPLATE CATEGORY >>>>>> */
  async addTemplateCategory(category: TemplateCategory) {
    this.templateCategories = this.orderTemplateCategories([...this.templateCategories, category])
  }

  async renameTemplateCategory(categoryId: number, name: string) {
    const categoryIndex = this.templateCategories.findIndex((c) => c.id === categoryId)
    this.templateCategories = this.orderTemplateCategories([
      ...this.templateCategories.slice(0, categoryIndex),
      { ...this.templateCategories[categoryIndex], name },
      ...this.templateCategories.slice(categoryIndex + 1),
    ])
  }

  async recentlyUsedTemplates(limit: number) {
    const templates = await templateService.recentlyUsedTemplates({ limit })

    return templates
  }

  orderTemplateCategories(templateCategories: (TemplateCategory | Omit<TemplateCategory, 'templates'>)[]) {
    return orderBy(templateCategories, [(templateCategory) => templateCategory.name?.toLowerCase()])
  }

  getBlockHtml = async (blockId?: number) => {
    if (blockId) {
      const block = await global.data.templates.loadFullBlock(blockId, false)
      if (block) {
        const { markup, content } = block
        if (content) {
          const html = remirrorJSONToHtml(content)
          return blockToHtml(html)
        } else if (markup) {
          return blockToHtml(markup)
        }
      }
    }
    return null
  }
}

export default Templates
