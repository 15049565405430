import { Modal } from 'antd'
import { dropRight, find } from 'lodash'
import { action, computed, makeObservable, observable, observe } from 'mobx'
import { fileParts } from '../../helpers/file'
import { AbstractStore } from '../../helpers/pm'

class Documents extends AbstractStore {
  documents = []
  rootFolder = ''

  constructor() {
    super()

    makeObservable(this, {
      documents: observable,
      rootFolder: observable,
      files: computed,
      attachments: computed,
      paths: computed,
      delete: action.bound,
      update: action.bound,
      loadDocuments: action.bound,
    })

    observe(
      global.stores.auth,
      'loggedIn',
      (c) => {
        if (c.newValue) {
          this.documents = []
          this.isSharedBucketPractice = global.data.practice.bucket[0] === '/'
          this.rootFolder = this.isSharedBucketPractice ? global.data.practice.bucket.split('/')[1] : ''
          this.loadDocuments()
        }
      },
      true,
    )

    this.storeState()
  }

  /**
   *
   */
  get = (id) => {
    if (!id) {
      return null
    }
    return find(this.documents, { id })
  }

  get files() {
    return this.documents.reduce((acum, file) => {
      acum[file.key] = fileParts(global.data.practice.bucket, file)
      return acum
    }, {})
  }

  /**
   * Returns the list of attachable files
   * */
  get attachments() {
    if (!this.documents.length) {
      return []
    }
    const docsAsObject = this.documents.reduce((acum, val) => {
      if (val.path.includes('common/img') || val.path.includes('advisor/') || val.path.includes('common/notes_imgs')) {
        return acum
      }
      if (!val.name.match(/\.[0-9a-z]+$/i)) {
        return acum
      }
      acum[val.name] = val
      return acum
    }, {})
    return Object.values(docsAsObject)
  }

  get paths() {
    const commonFolderPath = this.isSharedBucketPractice ? `${this.rootFolder}/common` : 'common'
    const paths = this.documents.reduce(this.fileOrganizerReducer, {
      [this.rootFolder]: [
        { id: commonFolderPath, key: commonFolderPath, name: 'common', path: commonFolderPath, size: 0 },
      ],
      [commonFolderPath]: [{ id: this.rootFolder, key: this.rootFolder, name: '..', path: this.rootFolder, size: 0 }],
    })

    return paths
  }

  /**
   *
   */
  delete(fileToDelete) {
    const key = fileToDelete.file.key
    if (key.startsWith('advisor') || key.startsWith(`${global.data.practice.bucket.split('/')[1]}/advisor`)) {
      Modal.error({
        title: 'Delete prohibited!',
        content: 'Advisor specific files cannot be deleted from here!',
        okType: 'danger',
        okText: 'Ok',
        onOk: () => {},
      })
    } else {
      Modal.confirm({
        title: 'Are you sure delete this file?',
        okText: 'Yes',
        onOk: async () => {
          try {
            await global.files.delete(key)
            this.documents = this.documents.filter((file) => file.key !== key)
          } catch (err) {
            throw new Error(err)
          }
        },
      })
    }
  }

  /**
   *
   */
  async update(file, values) {
    try {
      await global.files.update(file, { file: values })
      return this.documents
    } catch (err) {
      throw new Error(err)
    }
  }

  /**
   *
   */
  async loadDocuments(path = '') {
    try {
      this.documents = await global.files.load(path)
      return this.documents
    } catch (err) {
      throw new Error(err)
    }
  }

  /**
   *
   */
  async upload(file, key) {
    try {
      const shortKey =
        this.rootFolder && key.split('/')[0] === this.rootFolder ? key.split('/').slice(1).join('/') : key
      const sameKeyFile = this.documents.find((f) => f.key === key || f.key === shortKey)
      key = shortKey
      if (sameKeyFile) {
        return new Promise((resolve) => {
          Modal.confirm({
            title: `Seems like there's already a file with that name.`,
            content: `Only the last file with that name is going to be accessible. Are you sure you want to overwrite the current version?`,
            okText: 'Yes',
            onOk: async () => {
              global.app.loading = 'Uploading File'
              const doc = await global.files.upload(file, key)
              this.documents = [...this.documents, doc]
              resolve(doc)
            },
            onCancel() {
              resolve()
            },
          })
        })
      } else {
        global.app.loading = 'Uploading File'
        const doc = await global.files.upload(file, key)
        this.documents = [...this.documents, doc]
        global.app.loading = false
        return doc
      }
    } catch (err) {
      throw new Error(err)
    }
  }

  /**
   *
   */
  createFolder = async (key) => {
    try {
      const doc = await global.files.createFolder(key)
      this.documents = [...this.documents, doc]
      return doc
    } catch (err) {
      throw new Error(err)
    }
  }

  /* ---------- private ---------- */

  /**
   * Organize the file in the path-organized data structure
   * @param {*} acumulator
   * @param {*} file
   */
  fileOrganizerReducer = (acumulator, file) => {
    const parsedItem = fileParts(global.data.practice.bucket, file)
    const { key, path } = parsedItem
    this.makeItemReachable(path, acumulator)

    const item = { ...parsedItem.file, ...parsedItem, id: key, size: file.size }
    const positionInFolder = acumulator[path].findIndex((item) => item.key === key)
    if (file.size) {
      // is an object
      if (positionInFolder !== -1) {
        // is it a duplicated database entry? (uploaded 1+ times the same object in the same folder)
        acumulator[path].splice(positionInFolder, 1, item)
      } else {
        acumulator[path].push(item)
      }
    } else {
      // is a folder
      if (positionInFolder !== -1) {
        // the folder object wasn't previously created by makeItemReachable?
        acumulator[path].splice(positionInFolder, 1, item)
      }
      if (path && !parsedItem.name.includes('.')) {
        // for empty folder objects that are NOT on the root folder
        this.makeItemReachable(key, acumulator)
      }
    }
    return acumulator
  }

  /**
   * Inserts accessor items so that every item is accesible from the root and the user can go back and forth
   * @param {*} acumulator
   * @param {*} path of the item to make reachable
   */
  makeItemReachable = (path, acumulator) => {
    if (!acumulator[path]) {
      acumulator[path] = []
    }

    const upperPath = dropRight(path.split('/')).join('/')
    if (path !== this.rootFolder && !acumulator[path].some((item) => item.size === 0 && item.key === upperPath)) {
      // adds a 'up one level' fake item
      acumulator[path].push({
        name: '..',
        key: upperPath,
        size: 0,
        id: upperPath,
        path: upperPath,
      })
    }
    if (path && (!acumulator[upperPath] || !acumulator[upperPath].some((el) => el.key === path))) {
      // inserts intermediate folders between the root folder and the actual item
      const accessorItemPathParts = path.split('/')
      const folderName = accessorItemPathParts[accessorItemPathParts.length - 1]
      const logicalFolder = {
        name: folderName,
        key: path,
        size: 0,
        id: path,
        path,
      }

      if (!acumulator[upperPath]) {
        acumulator[upperPath] = [logicalFolder]
      } else {
        acumulator[upperPath].push(logicalFolder)
      }
      this.makeItemReachable(upperPath, acumulator) // yeah, this is recursive, sorry
    }
  }
}

export default Documents
