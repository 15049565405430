import { FileAttachment } from "components/routes/tasks/notes/notes.utils"
import { autorun } from "mobx"
import { useEffect, useMemo, useState } from "react"
import { FileObject } from "types/graphql"
import { filterSelected, filterSystemFiles } from "./file.utils"

export const useFiles = () => {
  const [files, setFiles] = useState<FileObject[]>([])
  const [attachments, setAttachments] = useState<FileObject[]>([])

  useEffect(() => {
    return autorun(() => {
      setFiles(global.data.docs.documents)
    })
  }, [])

  useEffect(() => {
    setAttachments(filterSystemFiles(files))
  }, [files])

  return useMemo(() => ({
    files,
    attachments
  }), [
    files,
    attachments
  ])
}

/**
 * Filter all 'attachments' by files present in the 'selected' array
 * @param current
 * @param all
 * @returns
 */
export const useFilteredAttachments = (selected: FileAttachment[]) => {
  const [filtered, setFiltered] = useState<FileObject[]>([])

  const { attachments } = useFiles()

  useEffect(() => {
    setFiltered(filterSelected(selected, attachments))
  }, [attachments, selected])

  return useMemo(() => ({
    filtered
  }), [
    filtered
  ])
}
