import { pause } from './time'

export const removeReadonly = <T extends any>(arr: T[] | readonly T[]): T[] => arr as T[]

export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>

export const throttle = async <T>(func: () => any, duration = 2000): Promise<T> => {
  if (duration === 0) return func()

  const start = new Date()
  const result = await func()

  const dur = new Date().getTime() - start.getTime()
  if (dur < duration) {
    await pause(duration - dur)
  }

  return result as T
}
