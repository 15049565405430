import { Attrs, Fragment, Node } from '@remirror/pm/model'
import { cloneDeep, compact, isEqual } from 'lodash'
import { EditorState, isProsemirrorFragment, isProsemirrorNode, Mark, ProsemirrorNode, Transaction } from 'remirror'
import {
  AvailableTopics,
  ListContentItem,
  ListItem,
  MergeFieldToReplace,
  MergeFieldToReplaceContainerType,
  Note,
  NoteInput,
  NoteTag,
  NotesGroup,
  PlaceholderListItem,
  ProsemirrorFragmentExtended,
  ProsemirrorNodeExtended,
  SubListItem,
} from './NoteReplacer.interfaces'
import { TagTypes } from 'constants/tags'

export function groupNotesByType(notes: NoteInput[]): NotesGroup[] {
  return notes.reduce((notesGroup: NotesGroup[], currentNote: NoteInput) => {
    const currentNoteData: Note = {
      content: currentNote.content,
      id: currentNote.id,
      tags: currentNote.tags.map((tag) => tag.value),
      status: currentNote.status,
    }
    const type = currentNote.type
    const noteGroupTypeIndex = notesGroup.findIndex((noteGroup) => noteGroup.type === type)
    if (noteGroupTypeIndex !== -1) {
      const noteGroup = notesGroup[noteGroupTypeIndex]
      const updatedGroupType = {
        ...noteGroup,
        notes: [...noteGroup.notes, currentNoteData],
      }
      return [...notesGroup.slice(0, noteGroupTypeIndex), updatedGroupType, ...notesGroup.slice(noteGroupTypeIndex + 1)]
    } else {
      const newNoteGroup = {
        type,
        notes: [currentNoteData],
      }
      return [...notesGroup, newNoteGroup]
    }
  }, [])
}

export function insertNodeAtPosition({
  tr,
  pos,
  content,
}: {
  tr: Transaction
  pos: number
  content: Fragment | ProsemirrorNode | ProsemirrorNode[]
}) {
  if (tr) {
    tr.insert(pos, content)
  }
  return tr
}

export function getListChildren(
  listNode: ProsemirrorNodeExtended,
  parent: ProsemirrorNode,
  parentPos: number,
  isRecursive = false,
  subtopics?: NoteTag[],
): { type: string; items: any[]; pos?: undefined } | { pos: number; type: string; items: any[] } {
  // OL / UL
  if (listNode.content.childCount > 0) {
    // LI(s)
    const listItemsNodesRaw = listNode.content.content as ProsemirrorNodeExtended[]
    const parentListType = listNode.type.name as MergeFieldToReplaceContainerType
    const listItemsChildren = listItemsNodesRaw.map((listItemNode) =>
      processListItem(listItemNode, listNode, parentPos, parentListType, subtopics),
    )
    if (isRecursive) {
      return {
        type: 'sublist',
        items: compact(listItemsChildren.flat()),
      }
    }
    return {
      pos: parentPos,
      type: 'list',
      items: compact(listItemsChildren.flat()),
    }
  }
  if (isRecursive) {
    return {
      type: 'sublist',
      items: [],
    }
  }
  return {
    pos: parentPos,
    type: 'list',
    items: [],
  }
}

function processListItem(
  listItemNode: ProsemirrorNodeExtended,
  listNode: ProsemirrorNodeExtended,
  parentPos: number,
  parentListType: MergeFieldToReplaceContainerType,
  subtopics?: NoteTag[],
): (
  | { type: string; items: any[]; pos?: undefined }
  | { pos: number; type: string; items: any[] }
  | { children: (MergeFieldToReplace | ListContentItem)[] }
  | undefined
)[] {
  // P
  const listItemNodeContent = listItemNode.content.content as ProsemirrorNodeExtended[]
  return listItemNodeContent.map((listItemChildrenWrapper) => {
    if (listItemChildrenWrapper.type.name === 'orderedList' || listItemChildrenWrapper.type.name === 'bulletList') {
      return getListChildren(
        listItemChildrenWrapper as ProsemirrorNodeExtended,
        listItemNode as ProsemirrorNodeExtended,
        parentPos,
        true,
        subtopics,
      )
    } else if (listItemChildrenWrapper.type.name === 'paragraph') {
      const doesParagraphHaveOneElement = listItemChildrenWrapper.content.childCount === 1
      if (doesParagraphHaveOneElement) {
        const [listItemChild] = listItemChildrenWrapper.content.content
        const item = processListItemChild(
          listItemChild,
          listItemChildrenWrapper,
          listNode,
          parentPos,
          parentListType,
          subtopics,
        )
        return {
          children: [item],
        }
      } else {
        const subListItems = listItemChildrenWrapper.content.content.map((listItemChild) =>
          processListItemChild(listItemChild, listItemChildrenWrapper, listNode, parentPos, parentListType, subtopics),
        )
        return {
          children: subListItems,
        }
      }
    }
  })
}

/**
 * Gets information from a `ListItemChild`.
 * A `ListItemChild` refers to the last element in the List Hierarchy
 *
 * - List
 *   - ListItem
 *     - ListItemChildrenWrapper
 *        - ListItemChild
 *
 * @param listItemChild
 * @param listItemChildrenWrapper
 * @param parentNode
 * @param parentPos
 * @param parentListType
 * @returns Information from ListItem. Either a `MergeFieldToReplace` or `ListContentItem` (generic content)
 */
function processListItemChild(
  listItemChild: ProsemirrorNode,
  listItemChildrenWrapper: ProsemirrorNodeExtended,
  parentNode: ProsemirrorNodeExtended,
  parentPos: number,
  parentListType: MergeFieldToReplaceContainerType,
  subtopics?: NoteTag[],
): MergeFieldToReplace | ListContentItem {
  const itemType = listItemChild.type.name
  const tags = subtopics
    ? subtopics.filter((subtopic) => subtopic.value === listItemChild.attrs.tags)
    : listItemChild.attrs.tags
  if (itemType === 'mentionAtom') {
    const mentionAtom: MergeFieldToReplace = {
      node: listItemChild,
      // FIXME: change way to get the real merge field type name
      mergeFieldType:
        listItemChild.attrs.kind === 'topic' ? listItemChild.attrs.label.split('::')[0] : listItemChild.attrs.id,
      kind: listItemChild.attrs.kind || '',
      pos: parentPos,
      containerType: parentListType,
      displayMode: listItemChild.attrs.displayMode,
      header: listItemChild.attrs.header,
      tags,
      id: listItemChild.attrs.id,
      marks: listItemChild.marks,
      parentAttrs: listItemChildrenWrapper.attrs,
    }
    return mentionAtom
  } else {
    return { node: listItemChild, displayMode: parentListType }
  }
}

export function checkIfListHasMentionAtom(listItems: PlaceholderListItem[]): boolean {
  if (listItems.length) {
    return compact(listItems).some((listItem) => {
      if (listItem.children) {
        return listItem.children!.some((item) => 'mergeFieldType' in item)
      } else {
        return checkIfListHasMentionAtom(listItem.items)
      }
    })
  }
  return false
}

export function getPlaceholderTypesFromList(items: ListItem[]): Array<string> {
  return items.reduce((accumulator: string[], currentListItem: ListItem) => {
    if (currentListItem.children) {
      const placeholderTypes = currentListItem.children
        .filter((child): child is MergeFieldToReplace => 'mergeFieldType' in child)
        .map((child) => child.mergeFieldType)
      return [...accumulator, ...placeholderTypes]
    }
    return accumulator
  }, [])
}

export function isNodeEmptyText(node: ProsemirrorNode): boolean {
  if (node.type.name === 'text') {
    const text = node.text?.trim()
    return text?.length === 0
  }
  return false
}

export function isNodeEmptyParagraph(node: ProsemirrorNode): boolean {
  if (node.type.name === 'paragraph') {
    const size = node.content.size
    return size === 0
  }
  return false
}

/**
 * Gets all placeholder topics from a `PlaceholderListItem` array, usually present in `PlaceholderArea`(s)
 * @param placeholderListItemsArray
 * @returns `AvailableTopics` array
 */
export function getTopicsFromPlaceholderListItems(placeholderListItemsArray: PlaceholderListItem[]): AvailableTopics[] {
  const topics: AvailableTopics[] = []
  placeholderListItemsArray.map((placeholderListItem) => {
    if ('children' in placeholderListItem) {
      const listItem = placeholderListItem as ListItem
      const listItemChildren = listItem.children
      const listItemChildrenTopics: AvailableTopics[] = listItemChildren
        .filter((listItemChild): listItemChild is MergeFieldToReplace => 'mergeFieldType' in listItemChild)
        .map((listItemChild) => {
          return {
            mergeFieldType: listItemChild.mergeFieldType,
            tags: listItemChild.tags,
          }
        })
      topics.push(...listItemChildrenTopics)
    } else if ('items' in placeholderListItem) {
      const subListItem = placeholderListItem as SubListItem
      const subListItemChildren = subListItem.items
      const subListItemChildrenTopics = getTopicsFromPlaceholderListItems(subListItemChildren)
      topics.push(...subListItemChildrenTopics)
    }
  })

  return topics
}

export function setMarksToNodesBetweenPositions(
  tr: Transaction,
  from: number,
  to: number,
  marks: Readonly<Mark[]>,
): Transaction {
  const nodesToModify: { node: ProsemirrorNode; pos: number }[] = []

  tr.doc.nodesBetween(from, to, (node, pos) => {
    if (node.inlineContent && node.type.allowsMarks(marks)) {
      nodesToModify.push({ node, pos })
    }
  })

  nodesToModify.forEach(({ node, pos }) => {
    marks.forEach((mark) => tr.addMark(pos, pos + node.nodeSize, mark))
  })

  return tr
}

export function removeTextHighlight(marks: Readonly<Mark[]>) {
  return marks.filter((mark) => mark.type.name !== 'textHighlight')
}

export function getNotesForMergeFieldType(notes: NotesGroup[], mergeFieldType: string) {
  return notes.find(({ type }) => type === mergeFieldType)
}

export function getNotesMatchingTag(notes: Note[], mergeFieldTag: string | NoteTag[]): Note[] {
  const isTagArray = Array.isArray(mergeFieldTag)
  let tag: any

  if (isTagArray) {
    const subtopic = mergeFieldTag.find((tag) => tag.type === TagTypes.SUBTOPIC)
    if (subtopic) {
      tag = subtopic?.value
    }
  } else {
    tag = mergeFieldTag
  }

  if (!tag || tag.length === 0) {
    return notes.filter(({ tags }) => tags.length === 0)
  }
  return notes.filter(({ tags }) => tags.some((subtopic) => tag === subtopic))
}

export function getNotesByTypeAndTags(
  notesGroup: NotesGroup[],
  mergeFieldType: string,
  mergeFieldTag: string | NoteTag[],
): Note[] | null {
  const noteGroup = getNotesForMergeFieldType(notesGroup, mergeFieldType)
  if (noteGroup && noteGroup.notes) {
    const notes = getNotesMatchingTag(noteGroup.notes, mergeFieldTag)
    if (notes) {
      return notes
    }
  }
  return null
}

export function isNodeParagraph(node: ProsemirrorNode): boolean {
  return node.type.name === 'paragraph'
}

export function getNotesNodes(
  state: Readonly<EditorState>,
  notes: Note[],
  mergeFieldToReplace: MergeFieldToReplace,
): ProsemirrorNode[] {
  const { parentAttrs, marks } = mergeFieldToReplace
  return notes.flatMap((note) => {
    if (isProsemirrorNode(note.content)) {
      return formatContent({ node: note.content, attrs: parentAttrs, marks, state })
    }

    if (isProsemirrorFragment(note.content)) {
      const nodes: ProsemirrorNode[] = []
      note.content.forEach((node) => {
        nodes.push(formatContent({ node, attrs: parentAttrs, marks, state }))
      })

      return nodes
    }

    return state.schema.nodes.paragraph.create(parentAttrs, note.content, marks)
  })
}

export function getNotesNodesList(
  state: Readonly<EditorState>,
  notes: Note[],
  mergeFieldToReplace: MergeFieldToReplace,
): (ProsemirrorNode | Fragment)[] {
  const { parentAttrs, marks } = mergeFieldToReplace
  return notes.flatMap((note) => {
    if (isProsemirrorNode(note.content)) {
      return formatContent({ node: note.content, attrs: parentAttrs, marks, state })
    }

    if (isProsemirrorFragment(note.content)) {
      const nodes: ProsemirrorNode[] = []
      note.content.forEach((node) => {
        nodes.push(formatContent({ node, attrs: parentAttrs, marks, state }))
      })

      return Fragment.fromArray(nodes)
    }

    return state.schema.nodes.paragraph.create(parentAttrs, note.content, marks)
  })
}

export function getNotesPlacehoderNodes(
  state: Readonly<EditorState>,
  notes: Note[],
  mergeFieldToReplace: MergeFieldToReplace,
): ProsemirrorNode[] {
  const { parentAttrs, marks } = mergeFieldToReplace
  return notes.flatMap((note) => {
    if (isProsemirrorNode(note.content)) {
      return formatContent({ node: note.content, attrs: parentAttrs, marks, state })
    }

    if (isProsemirrorFragment(note.content)) {
      const nodes: ProsemirrorNode[] = []
      note.content.forEach((node) => {
        nodes.push(formatContent({ node, attrs: parentAttrs, marks, state }))
      })

      return nodes
    }

    return note.content
  })
}

/**
 * Applies formatting specified to a node and its descendants.
 * 
 * This function is recursive in case it needs to apply formatting to certain Node elements and its children,
 * such as `paragraph`, `bulletList`, etc.
 * @param Object with
 * - `node`: Node to apply formatting,
 * - `attrs`: Node attrs to reapply on the process,
 * - `marks`: Marks (formatting) to apply in the node,
 * - `state`: EditorState, to be able to access Node recreation.
 * @returns Node `ProsemirrorNode` Node formatted
 */
function formatContent({
  node,
  attrs,
  marks,
  state,
}: {
  node: Readonly<Node>
  attrs?: Readonly<Attrs>
  marks?: Readonly<Mark[]>
  state: Readonly<EditorState>
}) {
  const nodeType = node.type.name
  if (nodeType === 'text') {
    const textMarks = mergeTextMarks(node.marks, marks)
    return state.schema.text(node.text!, textMarks)
  }

  if (nodeType === 'paragraph' || nodeType === 'doc') {
    const content: Node[] = []
    node.content.forEach((node) => content.push(formatContent({ node, attrs, marks, state })))

    const paragraphAttrs = attrs && hasDefaultAttrs(node.attrs) ? attrs : node.attrs

    return state.schema.nodes.paragraph.create(paragraphAttrs, content)
  }

  if (nodeType === 'bulletList' || nodeType === 'orderedList' || nodeType === 'listItem') {
    const content: Node[] = []
    const nodeContent = (node.content as ProsemirrorFragmentExtended).content as ProsemirrorNodeExtended[]
    nodeContent.forEach(node => content.push(formatContent({ node, attrs, marks, state })))

    return state.schema.nodes[nodeType].create(node.attrs, content)
  }

  return state.schema.nodes[nodeType].create(node.attrs, node.content)
}

export function formatInlineContent({
  node,
  attrs,
  marks,
  state,
}: {
  node: Readonly<Node>
  attrs?: Readonly<Attrs>
  marks?: Readonly<Mark[]>
  state: Readonly<EditorState>
}) {
  const nodeType = node.type.name
  if (nodeType === 'text') {
    const textMarks = mergeTextMarks(node.marks, marks)
    return [state.schema.text(node.text!, textMarks)]
  }

  if (nodeType === 'paragraph') {
    const content: Node[] = []
    node.content.forEach((node) => content.push(formatContent({ node, attrs, marks, state })))

    return content
  }

  return [state.schema.nodes[nodeType].create(node.attrs, node.content)]
}

function hasDefaultAttrs(attrs: Readonly<Attrs>) {
  return (
    isEqual(attrs, {
      dir: 'ltr',
      ignoreBidiAutoUpdate: null,
      nodeIndent: 0,
      nodeLineHeight: null,
      nodeTextAlignment: '',
      style: '',
    }) ||
    isEqual(attrs, {
      dir: null,
      ignoreBidiAutoUpdate: null,
      nodeIndent: 0,
      nodeLineHeight: null,
      nodeTextAlignment: '',
      style: '',
    }) ||
    isEqual(attrs, {
      dir: null,
      ignoreBidiAutoUpdate: null,
      nodeIndent: null,
      nodeLineHeight: null,
      nodeTextAlignment: null,
      style: '',
    })
  )
}

function mergeTextMarks(marks: Readonly<Mark[]>, parentMarks: Readonly<Mark[]> | undefined) {
  if (!marks.length) {
    return parentMarks
  }

  if (!parentMarks) {
    return undefined
  }

  const mergedMarks = cloneDeep(parentMarks).concat()

  marks.forEach((mark) => {
    const index = mergedMarks.findIndex((mergedMark) => mark.type.name === mergedMark.type.name)

    if (index >= 0) {
      // override
      mergedMarks[index] = mark
    } else {
      // add
      mergedMarks.push(mark)
    }
  })

  return mergedMarks
}
