import { extension, PlainExtension, Transaction } from '@remirror/core'
import { MentionAtomOptions } from '@remirror/extension-mention-atom'
import { command, DispatchFunction, EditorState, ProsemirrorNode } from 'remirror'

import { CommandFunction } from '@remirror/core'
import { replaceInlineMentionAtom } from './handlers/NoteReplacerInline.helpers'
import {
  checkIfListHasMentionAtom,
  getListChildren,
  getPlaceholderTypesFromList,
  groupNotesByType,
  isNodeParagraph,
} from './NoteReplacer.helpers'
import {
  MergeFieldArea,
  MergeFieldToReplace,
  NoteInput,
  NotesGroup,
  NoteTag,
  ProsemirrorNodeExtended,
} from './NoteReplacer.interfaces'

import { Node } from '@remirror/pm/dist-types/model'
import { DISPLAY_MODE } from '../smartFieldAtom/utils/displayMode.constant'
import { replaceListMentionAtoms } from './handlers/NoteReplacerList.helper'
import { AreaTypes, AREA_TYPES, CONTAINER_TYPES } from './NoteReplacer.constants'
import { prepareTemplate } from './utils/prepareTemplate.util'

export function replaceMergeFields() {
  return (content: NoteInput[], subtopics?: Array<NoteTag>): CommandFunction =>
    ({ state, dispatch }) => {
      if (!dispatch) {
        return false
      }

      const tr = prepareTemplate(state.tr, state)

      const placeholderAreas = getMentionAtoms(tr, subtopics)
      const groupedNotes = groupNotesByType(content)

      replacePlaceholderWithNotes(tr, state, dispatch, placeholderAreas, groupedNotes, subtopics)

      return true
    }
}

// TODO: check options, were copied and pasted from another extension
@extension<MentionAtomOptions>({
  defaultOptions: {
    selectable: true,
    draggable: false,
    mentionTag: 'span' as const,
    matchers: [],
    appendText: ' ',
    suggestTag: 'span' as const,
    disableDecorations: false,
    invalidMarks: [],
    invalidNodes: [],
    isValidPosition: () => true,
    validMarks: null,
    validNodes: null,
  },
  handlerKeyOptions: { onClick: { earlyReturnValue: true } },
  handlerKeys: ['onChange', 'onClick'],
  staticKeys: ['selectable', 'draggable', 'mentionTag', 'matchers'],
})
export class NoteReplacerExtension extends PlainExtension {
  get name() {
    return 'noteReplacer'
  }

  @command()
  replaceMergeFieldsWithContent(content: NoteInput[], subtopics?: Array<NoteTag>): CommandFunction {
    return replaceMergeFields()(content, subtopics)
  }
}

export function getPosToReplaceMentionAtom(node: Node, parent: Node | null, pos: number, doc: Node) {
  // case: By configuration P is always the parent, but let's check it to allow for unbroken configuration changes
  if (!(parent && isNodeParagraph(parent))) {
    return pos
  }

  // case: Inline placeholders
  // Placeholders can be displayed as paragraphs or inline.
  // In the case of inline placeholders, they can be followed by text so we are not going to create a wrapper, nor remove the wrapper provided by the template.
  // The wrapper is the one in charge of applying the formatting and it is for this reason that we always keep it.
  // in turn, the wrapper allows remirror not to create new wrappers randomly.
  const isInlinePlaceholder =
    node.type.name === 'mentionAtom' &&
    node.attrs.kind === 'placeholder' &&
    node.attrs.displayMode === DISPLAY_MODE.INLINE

  // case: The paragraph contains only the mention atom
  // returns the position of the parent. We need to remove the paragraph, since the display will create its own representation.
  if (parent.childCount === 1 && !isInlinePlaceholder) {
    return pos - 1
  }

  return pos
}

export function getMentionAtoms(tr: Transaction, subtopics?: Array<NoteTag>): MergeFieldArea[] {
  const placeholderAreas: MergeFieldArea[] = []

  tr.doc.descendants((node, pos, parent, index) => {
    if (['orderedList', 'bulletList', 'taskList'].includes(node.type.name)) {
      const possibleList = getListChildren(
        node as ProsemirrorNodeExtended,
        parent as ProsemirrorNodeExtended,
        pos,
        false,
        subtopics,
      )
      if (checkIfListHasMentionAtom(possibleList.items)) {
        const placeholderTypes = getPlaceholderTypesFromList(possibleList.items)
        const placeholderArea: MergeFieldArea = {
          pos,
          node,
          items: possibleList.items,
          areaType: node.type.name as AreaTypes,
          placeholderTypes,
        }
        placeholderAreas.push(placeholderArea)
      }

      return false
    }

    if (node.type.name === 'mentionAtom') {
      const mentionAtom = getMergeFieldToReplace(node, parent, pos, tr, subtopics)
      const placeholderArea: MergeFieldArea = {
        node,
        pos,
        placeholderTypes: [mentionAtom.mergeFieldType],
        areaType: AREA_TYPES.INLINE,
        items: [{ children: [mentionAtom] }],
      }
      placeholderAreas.push(placeholderArea)

      return false
    }
  })

  const sortedPlaceholderAreas = placeholderAreas.sort((a, b) => b.pos - a.pos)
  return sortedPlaceholderAreas
}

function replacePlaceholderWithNotes(
  tr: Transaction,
  state: Readonly<EditorState>,
  dispatch: DispatchFunction,
  placeholderAreas: MergeFieldArea[],
  notes: NotesGroup[],
  subtopics?: NoteTag[],
): void {
  for (const placeholderArea of placeholderAreas) {
    const lists: Omit<AreaTypes, typeof AREA_TYPES.INLINE>[] = [
      AREA_TYPES.ORDERED_LIST,
      AREA_TYPES.BULLET_LIST,
      AREA_TYPES.TASK_LIST,
    ]

    if (lists.includes(placeholderArea.areaType)) {
      replaceListMentionAtoms(tr, state, placeholderArea, notes, subtopics)
    } else if (placeholderArea.areaType === AREA_TYPES.INLINE) {
      replaceInlineMentionAtom(tr, state, placeholderArea, notes)
    }
  }

  dispatch(tr)
}

/**
 * Creates MergeFieldToReplace item with Node, Parent, Position and Transaction.
 * @param node `ProsemirrorNode` Node in which the merge field is instanced.
 * @param parent `ProsemirrorNode | null` Parent node, if available.
 * @param pos `number` Position number of the node
 * @param tr `Transaction` Prosemirror Transaction
 * @returns
 */
function getMergeFieldToReplace(
  node: ProsemirrorNode,
  parent: ProsemirrorNode | null,
  pos: number,
  tr: Transaction,
  subtopics?: Array<NoteTag>,
): MergeFieldToReplace {
  const tags = subtopics ? subtopics.filter((subtopic) => subtopic.value === node.attrs.tags) : node.attrs.tags
  return {
    node,
    // FIXME: change way to get the real merge field type name
    mergeFieldType: node.attrs.kind === 'topic' ? node.attrs.label.split('::')[0] : node.attrs.id,
    kind: node.attrs.kind || '',
    // if the mentionAtom is the only child, then it takes the position of the parent and replaces the whole block.
    pos: getPosToReplaceMentionAtom(node, parent, pos, tr.doc),
    containerType: CONTAINER_TYPES.INLINE,
    displayMode: node.attrs.displayMode,
    header: node.attrs.header,
    tags,
    id: node.attrs.id,
    marks: node.marks,
    parentAttrs: parent?.attrs || undefined,
  }
}
