import { debounce } from 'lodash'

export function debouncePromise<A, B>(
  func: (a: A) => Promise<B>,
  debounceDelay?: number,
): (a: A) => Promise<B | 'skipped'> {
  const promiseResolverRef: { current: (b: B | 'skipped') => void } = {
    current: () => {},
  }

  const debouncedFunc = debounce((a: A) => {
    const promiseResolverSnapshot = promiseResolverRef.current
    func(a).then((b) => {
      if (promiseResolverSnapshot === promiseResolverRef.current) {
        promiseResolverRef.current(b)
      }
    })
  }, debounceDelay)

  return (a: A) =>
    new Promise<B | 'skipped'>((resolve) => {
      promiseResolverRef.current('skipped')
      promiseResolverRef.current = resolve

      debouncedFunc(a)
    })
}
