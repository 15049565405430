/* eslint-disable no-underscore-dangle */
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { MailOutlined } from '@ant-design/icons'
import { Button, Input } from 'antd'
import { ForgotPassword } from 'aws-amplify-react'
import CenteredLayout from 'components/layouts/CenteredLayout'
import styled from 'styled-components'

const StyledForm = styled(Form)`
  width: 380px;
  margin-top: 6px;
  .ant-form-item,
  .ant-legacy-form-item {
    margin-bottom: 10px !important;
    &.tight {
      margin-bottom: 0px !important;
    }
  }
  .c-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
  }
  .c-button {
    margin-top: 30px;
    .reset-button {
      width: 100%;
    }
  }
`

const stateDefaults = { loading: false, found: null, email: null }
const trim = (str = '') => str.trim().toLowerCase()

class ForgotWorkspaceComponent extends ForgotPassword {
  constructor(props) {
    super(props)
    this._validAuthStates = ['forgotWorkspace']
    this.state = { ...stateDefaults }
  }

  onFindIt = (e) => {
    e && e.preventDefault()
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const email = trim(values.email)
        this.setState({ loading: true })
        const found = await global.workspace.forgot(email)
        this.setState({ found, loading: false, email })
      }
    })
  }

  hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some((field) => fieldsError[field])
  }

  onReset = (e) => {
    this.props.form.resetFields()
    this.setState(stateDefaults)
  }

  showComponent() {
    const { props, state } = this
    return (
      <CenteredLayout title={state.found ? 'Check your email!' : 'Find your Workspace'}>
        {state.found ? (
          <StyledForm>
            <CenteredLayout.P className="tightest">
              We’ve emailed the results to <b>{state.email}</b>
            </CenteredLayout.P>
            <CenteredLayout.P>
              Wrong email? Please{' '}
              <CenteredLayout.Link onClick={this.onReset}>re-enter your address.</CenteredLayout.Link>
            </CenteredLayout.P>
            <CenteredLayout.P>
              If we found a workspace, be sure to make a note of it, and click `Launch` to take you straight there.
            </CenteredLayout.P>
            <div className="c-controls">
              <CenteredLayout.Link
                onClick={(e) => props.onStateChange('enterWorkspace', {})}
              >{`< Back to Workspaces`}</CenteredLayout.Link>
            </div>
          </StyledForm>
        ) : (
          <StyledForm>
            <CenteredLayout.P>
              Start by choosing the email you signed up with. Once we&apos;ve found it we&apos;ll email you your
              details.
            </CenteredLayout.P>

            <Form.Item className="tight">
              {props.form.getFieldDecorator('email', {
                rules: [
                  {
                    type: 'email',
                    message: 'Please input a valid email!',
                  },
                  { required: true, message: 'Enter your signup email!' },
                ],
              })(<Input prefix={<MailOutlined />} placeholder="name@work-email.com" size="large" autoFocus />)}
            </Form.Item>

            <div className="c-controls">
              <CenteredLayout.Link
                onClick={(e) => props.onStateChange('enterWorkspace', {})}
              >{`< Back to Workspaces`}</CenteredLayout.Link>
              <Button
                disabled={this.hasErrors(this.props.form.getFieldsError())}
                style={{ width: 120 }}
                loading={this.state.loading}
                className="c-reset-button"
                onClick={this.onFindIt}
                type="primary"
                size="large"
              >
                Find it
              </Button>
            </div>

            {/* hidden submit button */}
            <button type="submit" style={{ position: 'absolute', left: -9999 }} />
          </StyledForm>
        )}
      </CenteredLayout>
    )
  }
}

export default Form.create()(ForgotWorkspaceComponent)
