/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FileAttachment, validAttachments } from "components/routes/tasks/notes/notes.utils"
import { sortBy } from "lodash"
import { Attachment, FileObject } from "types/graphql"

const SYS_FILE_PATHS = [
  "common/img",
  "advisor/",
  "common/notes_imgs"
]

/**
 * Returns an array of files, with non-user facing system files filtered out
 * @param files
 * @returns
 */
export const filterSystemFiles = (files: FileObject[]): FileObject[] => {
  const nonSysFiles = files.reduce((acum, file) => {
    // if the file contains a system file path, ignore it
    if (SYS_FILE_PATHS.some(sysPath => (file.path?.includes(sysPath)))) {
      return acum
    }

    // What's this regex do?
    if (!(file?.name?.match(/\.[0-9a-z]+$/i))) {
      return acum
    }

    // add file to Map
    return acum.set(file.name, file);
  }, new Map())

  return Array.from(nonSysFiles.values())
}


/**
 * Filter 'all' files by files present in the 'current' array
 * @param current
 * @param all
 * @returns
 */
export const filterSelected = (current: FileAttachment[], all: FileObject[]): FileObject[] => {
  const sorted = sortBy(all, file => {
    return file.name
  })

  if (current.length === 0) {
    return sorted
  }

  return sorted.filter(file => {
    return !current.some(f => (String(file.id) === String(f.file.id)))
  })
}

/**
 * @param attachments
 * @returns
 */
export const filesToAttachments = (attachments: FileAttachment[]): Attachment[] => {
  const attachs = validAttachments(attachments, true)
  return attachs.map(({ file }) => {
    // @ts-ignore
    if (file.content) {
      return {
        filename: file.name,
        // @ts-ignore
        contentType: file.contentType,
        encoding: 'base64',
        // @ts-ignore
        content: file.content,
      }
    }
    return {
      filename: file.name,
      path: file.url,
    }
  })
}
