import * as apiService from 'lib/apollo/client'
import { precisefpListAccounts, precisefpListTemplates, precisefpCreateEngagement } from '../apollo/query/precisefp.gql'
import DataError, { IsError } from '../../helpers/dataError'

class PreciseFPPersistenceService {
  async createEngagement(engagement) {
    const { precisefpCreateEngagement: resp } = await apiService.mutate(precisefpCreateEngagement, { engagement })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.engagementData
  }

  async listAccounts(query) {
    const { precisefpListAccounts: resp } = await apiService.query(precisefpListAccounts, { query })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.accounts
  }

  async listTemplates() {
    const { precisefpListTemplates: resp } = await apiService.query(precisefpListTemplates)
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.templates
  }
}

export default new PreciseFPPersistenceService()
