import { decrypt } from 'helpers/signer'
import { isEmpty } from 'lodash'
import { computed, makeObservable, observable } from 'mobx'
import { Workspace as WorkspaceGQL } from '../types/graphql'
import { msg } from './msg'
import authService from './services/auth.service'

class Workspace {
  _teams = observable.map()
  validated = false
  team?: WorkspaceGQL | null = undefined

  get teams() {
    return Array.from(this._teams.values())
  }

  get pool() {
    if (this.team) {
      return this.team.pool
    }
    return null
  }

  get name() {
    if (this.team) {
      return this.team.name
    }
    return null
  }

  get domain() {
    if (this.team) {
      return this.team.domain
    }
    return null
  }

  get usesPoolGroups() {
    if (this.team) {
      return String(this.team.usesPoolGroups || 0)
    }
    return 0
  }

  get sso() {
    if (this.team) {
      return this.team.sso
    }
    return false
  }

  get meta() {
    if (this.team) {
      const { domain, usesPoolGroups } = this
      return { domain, usesPoolGroups }
    }
    return {}
  }

  constructor() {
    makeObservable(this, {
      _teams: observable,
      validated: observable,
      team: observable,
      teams: computed,
      pool: computed,
      name: computed,
      domain: computed,
      sso: computed,
      usesPoolGroups: computed,
      meta: computed,
    })

    const { aid } = global.router.getParamsFromLocation(window.location)
    let workspace

    if (!isEmpty(aid)) {
      const data = decrypt(aid)
      workspace = data.ws
    }

    this.hydrate(workspace)
  }

  /* ---------- public ---------- */

  /**
   *
   */
  load = async (domain: string) => {
    try {
      const resp = await authService.validateDomain(domain)

      if (!resp) {
        throw new Error('Workspace not found')
      }

      if (!resp.ok) {
        throw new Error(resp?.error?.message || 'Workspace not found - Query failed')
      }

      if (!resp.workspace?.domain) {
        throw new Error('Workspace not found')
      }

      // @ts-ignore
      global.bus.post(global.bus.AUTH_INIT, { ws: resp.workspace })

      this.activate(resp.workspace)
    } catch (error) {
      this.validated = false
      this.remove(domain)

      throw new Error(`Workspace '${domain}' is not recognised!`)
    }
  }

  /**
   * Activate the specified workspace if one provided
   */
  activate = (ws = { domain: 'none' }) => {
    // update/add workspace if exists
    if (ws.domain !== 'none') {
      this._teams.set(ws.domain, ws)
    }
    // activate appropriate workspace
    this._teams.forEach((value, key) => {
      value.active = key === ws.domain
    })
    // get the active team
    this.team = this._teams.get(ws.domain)
    this.validated = true

    // @ts-ignore
    global.bus.post(global.bus.WS_CHANGE)
    this.dehydrate()
  }

  /**
   * @param {*} email
   */
  forgot = async (email: string) => {
    return authService.forgotWorkspace(email)
  }

  /**
   *
   */
  remove = (domain: string) => {
    if (this._teams.has(domain)) {
      this._teams.delete(domain)
      this.dehydrate()
    }
  }

  /**
   * Reset to no active workspace
   */
  reset = () => {
    this._teams.forEach((val) => (val.active = false))
    this.team = null
    // @ts-ignore
    global.bus.post(global.bus.WS_CHANGE)
    this.dehydrate()
  }

  /**
   * Hydrates the app with workspace data from localStorage
   */
  hydrate = (ws: string) => {
    const teams = global.store.getItem('teams', {})
    this._teams.replace(
      Object.keys(teams).map((t: string) => {
        // @ts-ignore
        if (teams[t].active) {
          // @ts-ignore
          this.team = teams[t]
        }
        // @ts-ignore
        return [t, teams[t]]
      }),
    )

    if (ws) {
      return this.load(ws).catch((err) => {
        msg.error(err.message)
      })
    }

    if (this.team) {
      return this.load(this.team.domain).catch((err) => {
        msg.error(err.message)
      })
    }

    // set flag to notify that we've checked for a valid domain
    this.validated = true
  }

  /**
   * Dehydrates the app workspace data to localStorage
   */
  dehydrate = () => {
    const teams = Array.from(this._teams.entries())
    const obj = teams.reduce((all, [team, val]) => {
      // @ts-ignore
      all[team] = val
      return all
    }, {})
    // @ts-ignore
    global.store.setItem('teams', obj)
  }
}

export default Workspace
