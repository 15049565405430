/* eslint-disable no-underscore-dangle */
import { computed, observable, makeObservable } from 'mobx'
import { queryParams as qp } from 'helpers/href'
import { decrypt } from 'helpers/signer'
import { get, isEmpty } from 'lodash'

class Deeplink {
  loc = null

  /**
   * location.pathname shortcut
   */
  get path() {
    return get(this.loc, 'pathname', '')
  }

  /**
   * location.pathname shortcut - mo query, no leading/trailing slashes
   */
  get pathname() {
    const path = this.path.split('?')[0]
    return path.replace(/^\/+|\/+$/g, '')
  }

  /**
   * location.search shortcut - parsed object
   */
  get params() {
    const { search } = this.loc
    const query = isEmpty(search) ? '' : search
    return qp(query.replace(/^\?/, ''))
  }

  /**
   * location.search shortcut - parsed object
   */
  get wsParams() {
    const { aid } = this.params
    if (!isEmpty(aid)) {
      return decrypt(aid)
    }
    return {}
  }

  /**
   * location.search shortcut - parsed object
   */
  get hash() {
    const { hash } = this.location
    const val = isEmpty(hash) ? '' : hash
    return qp(val.replace(/^#/, ''))
  }

  constructor(auth) {
    makeObservable(this, {
      loc: observable.ref,
      path: computed,
      pathname: computed,
      params: computed,
      wsParams: computed,
      hash: computed,
    })

    this.loc = { ...window.location }
  }

  /* ---------- public ---------- */

  /**
   * Pick up the original intended route, after any redirects such as for Authentication
   */
  get initialLocation() {
    return this.useInitial()
      ? this.loc
      : {
          pathname: global.app.homePage,
        }
  }

  /**
   * Use the initial location as start page?
   */
  useInitial() {
    const blacklist = ['/', '/workspace', global.app.homePage]
    return blacklist.every((r) => r !== this.path)
  }

  /**
   *
   */
  reset = () => {
    this.loc = { pathname: '/' }
    global.router.initialLocation = { pathname: '/' }
    global.router.goto()
  }
}

export default Deeplink
