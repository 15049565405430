import * as apiService from 'lib/apollo/client'
import {
  ForgotWorkspaceDocument,
  ForgotWorkspaceMutation,
  ForgotWorkspaceMutationVariables,
  LoginDocument,
  LoginMutation,
  LoginMutationVariables,
  ResetPasswordDocument,
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
  ValidateDomainDocument,
  ValidateDomainMutation,
  ValidateDomainMutationVariables,
} from 'types/graphql'
import DataError, { IsError } from '../../helpers/dataError'

class AuthService {
  async resetPassword(email: string) {
    const { resetPassword: resp } = await apiService.query<ResetPasswordMutation, ResetPasswordMutationVariables>(
      ResetPasswordDocument,
      {
        email,
      },
    )

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async validateDomain(domain: string) {
    const { validateDomain: resp } = await apiService.mutate<ValidateDomainMutation, ValidateDomainMutationVariables>(
      ValidateDomainDocument,
      { domain },
      { context: { headers: { aaws: true } } },
    )

    return resp
  }

  async login() {
    const { login: resp } = await apiService.mutate<LoginMutation, LoginMutationVariables>(LoginDocument, {})

    return resp
  }

  async forgotWorkspace(email: string) {
    const { forgotWorkspace: resp } = await apiService.mutate<
      ForgotWorkspaceMutation,
      ForgotWorkspaceMutationVariables
    >(ForgotWorkspaceDocument, { email }, { context: { headers: { aaws: true } } })

    return resp.found
  }
}

export default new AuthService()
