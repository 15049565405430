/* eslint-disable no-underscore-dangle */
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { LockOutlined, UnlockOutlined } from '@ant-design/icons'
import Auth from '@aws-amplify/auth'
import { Alert, Button, Input } from 'antd'
import { SignUp } from 'aws-amplify-react'
import CenteredLayout from 'components/layouts/CenteredLayout'
import { isNil } from 'lodash'
import React from 'react'
import PhoneInput from 'react-phone-input-2'
import { msg } from 'stores/msg'
import styled from 'styled-components'
import styles from 'styles'
import phoneValidator from '../../helpers/phone'
import { captureEvent, captureExceptionSilently } from '../../helpers/sentry'
import advisorsService from '../../stores/services/advisors.service'

const StyledForm = styled(Form)`
  width: 460px;
  margin-top: 6px;
  .ant-form-item,
  .ant-legacy-form-item {
    margin-bottom: 10px !important;
    &.tight {
      margin-bottom: 0px !important;
    }
    &.gap {
      margin-bottom: 20px !important;
    }
  }
  .c-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
  }
  .c-button {
    margin-top: 30px;
    .reset-button {
      width: 100%;
    }
  }
  .c-error {
    color: ${styles.colors.error};
    text-align: center;
  }
  .signup-link {
    color: ${styles.colors.blue};
    cursor: pointer;
    font-size: 14px;
    &:hover {
      text-decoration: underline;
    }
  }
`

const trim = (str = '') => str.trim()
const lc = (str = '') => (str ? str.toLowerCase() : '')

const StyledPhoneInput = styled(PhoneInput)`
  .country-list > .country {
    padding: 0 9px !important;
  }
  .flag-dropdown {
    background-color: ${styles.colors.offWhite}!important;
  }
`

const StyledAlert = styled(Alert)`
  margin-top: 10px;
  font-size: 13px;
`

const StyledInput = styled(Input)`
  .ant-input-group-addon {
    padding: 0 12px;
    background: ${styles.colors.offWhite};
  }
`

class SignUpComponent extends SignUp {
  constructor(props) {
    super(props)
    this._validAuthStates = ['signUp']
    this.state = { error: null, loading: false }
  }

  /**
   *
   */
  onPhoneChange = (value, country, e, formattedValue) => {
    const dialCode = country.dialCode
    if (isNil(value)) {
      return
    }

    const phoneNumber = value.substring(dialCode.length)
    const phone = phoneNumber.replace(/^0/, '')

    return `${dialCode}${phone}`
  }

  /**
   *
   */
  onSignUp = (e) => {
    e && e.preventDefault()
    const { form, authData } = this.props
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        values.username = trim(lc(values.username))
        values.redeemCode = trim(values.redeemCode)
        this.setState({ loading: true })
        const valid = await advisorsService.validateRedemption(values.username, values.redeemCode)
        if (!valid) {
          this.error('Invalid redemption code')
          this.setState({ loading: false })
          global.data.audit.log('signUp_invalidRedemption', 'auth', {
            workspace: global.workspace.name,
            redeemCode: values.redeemCode,
            user: values.username,
          })
          return
        }

        const signup_info = this.parseInfo(values)
        if (authData.existed) {
          this.setState({ loading: false })
          this.insertAdvisor({ ...signup_info.attributes, username: signup_info.username, userConfirmed: true })
        } else {
          Auth.signUp(signup_info)
            .then((data) => {
              this.insertAdvisor({
                ...signup_info.attributes,
                username: signup_info.username,
                userConfirmed: data.userConfirmed,
              })
              this.setState({ loading: false })
            })
            .catch((err) => {
              this.setState({ loading: false })
              if (err.code === 'UsernameExistsException') {
                this.insertAdvisor({ ...signup_info.attributes, username: signup_info.username, userConfirmed: true })
                captureEvent({ message: 'Username Exists', data: { payload: { username: signup_info.username } } })
              } else {
                this.error(err)
              }
            })
        }
      } else {
        this.setState({ loading: false })
        this.error('Please address the errors above')
      }
    })
  }

  insertAdvisor = async ({ given_name, family_name, phone_number, username, userConfirmed }) => {
    try {
      await advisorsService.insertAdvisor(trim(username), trim(given_name), trim(family_name), trim(phone_number))
      const state = userConfirmed ? 'signIn' : 'confirmSignUp'
      this.changeState(state, lc(username))
    } catch (err) {
      captureExceptionSilently(err, {
        message: 'insertAdvisor',
        data: { given_name, family_name, phone_number, username, userConfirmed },
      })
      msg.error(err.message)
    }
  }

  /**
   *
   */
  parseInfo = (values) => {
    const { usernameAttributes } = this.props
    return Object.keys(values).reduce(
      (keys, key) => {
        if (key === 'redeemCode') {
          return keys
        }
        if (key === 'confirm') {
          return keys
        }
        if (key === 'username' || key === 'password' || (key === 'email' && usernameAttributes === 'email')) {
          const emailAsUsername = key === 'email' && usernameAttributes === 'email'
          const keyName = emailAsUsername ? 'username' : key
          keys[keyName] = values[key]
        } else {
          if (key !== 'code') {
            if (key === 'phone_number') {
              keys.attributes[key] = `+${values[key]}`
            } else {
              keys.attributes[key] = values[key]
            }
          }
        }
        return keys
      },
      { attributes: {} },
    )
  }

  /**
   *
   */
  renderFields = () => {
    let tabIndex = 0
    const { props } = this
    const { signUpConfig, authData } = props
    const fields = signUpConfig.signUpFields.map((field, i) => {
      if (field.type === 'string') {
        const hidden = !!authData[field.key]
        return (
          <Form.Item key={field.key} style={{ display: hidden ? 'none' : 'block' }}>
            {props.form.getFieldDecorator(field.key, {
              rules: [{ required: field.required, message: `Enter ${field.label}!` }],
              initialValue: authData[field.key],
            })(
              <StyledInput
                addonBefore={<field.icon />}
                type={field.key === 'password' ? 'password' : 'text'}
                autoFocus={field.key === 'given_name'}
                placeholder={field.label}
                tabIndex={tabIndex++}
                size="large"
              />,
            )}
          </Form.Item>
        )
      }
      if (authData.existed && field.type === 'password') {
        return (
          <StyledAlert
            style={{ marginTop: 10, marginBottom: 20 }}
            message={
              <div>
                Looks like you already have a user in Pulse360. Awesome! <br />
                You can use the same password then, how cool is that? 😄.
              </div>
            }
            type="success"
            showIcon
          />
        )
      } else if (field.type === 'password') {
        return (
          <React.Fragment>
            <Form.Item style={{ marginTop: 24 }}>
              {props.form.getFieldDecorator('password', {
                rules: [
                  {
                    message: 'Please enter new password',
                    required: field.required,
                  },
                  {
                    message: 'Password must be 8 or more characters',
                    min: 8,
                  },
                  {
                    message: 'Password must have 1 or more lower case',
                    pattern: /.*[a-z].*/,
                  },
                  {
                    message: 'Password must have at least 1 digit',
                    pattern: /.*[0-9].*/,
                  },
                ],
                initialValue: '',
              })(
                <StyledInput
                  addonBefore={<LockOutlined />}
                  placeholder="Password"
                  tabIndex={tabIndex++}
                  type="password"
                  size="large"
                />,
              )}
            </Form.Item>
            <Form.Item>
              {props.form.getFieldDecorator('confirm', {
                rules: [
                  {
                    message: 'Please confirm your password',
                    required: true,
                  },
                  {
                    message: 'Confirmation does not match new password',
                    validator: (rule, confirmPass, cb) => {
                      const { getFieldValue } = props.form
                      const newPass = getFieldValue('password')
                      const matches = newPass === confirmPass
                      matches ? cb() : cb(true)
                    },
                  },
                ],
              })(
                <StyledInput
                  addonBefore={<UnlockOutlined />}
                  placeholder="Confirm password"
                  tabIndex={tabIndex++}
                  type="password"
                  size="large"
                />,
              )}
            </Form.Item>
            <StyledAlert
              style={{ marginBottom: 0 }}
              message={global.app.passwordRequirements}
              type="warning"
              showIcon
            />
          </React.Fragment>
        )
      }

      if (field.type === 'phone') {
        const phoneError = props.form.getFieldError('phone_number')
        const hasError = phoneError && phoneError.length
        return (
          <React.Fragment>
            <Form.Item>
              {props.form.getFieldDecorator('phone_number', {
                getValueFromEvent: this.onPhoneChange,
                rules: [
                  { required: true, message: `Enter cell phone` },
                  {
                    validator: async (rule, phone, cb) => {
                      const phoneNumber = `+${phone}`
                      const validNumber = await phoneValidator.isValid(phoneNumber)
                      if (!validNumber) {
                        return cb('Not a valid cell number.')
                      }
                      // const type = await phoneValidator.getType(phoneNumber)
                      // const valid = type === 'MOBILE' || type === 'FIXED_LINE_OR_MOBILE'
                      // if (!valid) return cb('You sure this is a cell phone?')
                      cb()
                    },
                  },
                ],
              })(
                <StyledPhoneInput
                  preserveOrder={['preferredCountries']}
                  preferredCountries={['us', 'gb', 'fr', 'de']}
                  countryCodeEditable
                  placeholder="Cell phone"
                  country="us"
                  inputProps={{
                    tabIndex: tabIndex++,
                  }}
                  specialLabel={false}
                  inputStyle={{
                    border: `1px solid ${hasError ? styles.colors.error : '#d3dce6'}`,
                    borderRadius: 4,
                    width: '100%',
                    height: 40,
                  }}
                />,
              )}
            </Form.Item>
          </React.Fragment>
        )
      }
      return null
    })

    return fields
  }

  showComponent() {
    const { props } = this
    const { authData } = props
    return (
      <CenteredLayout title="Welcome to Pulse360.">
        <StyledForm onSubmit={this.onSignUp}>
          <Form.Item key="redeemCode" style={{ display: 'none' }}>
            {props.form.getFieldDecorator('redeemCode', {
              initialValue: authData.code,
            })(<Input />)}
          </Form.Item>

          <p style={{ marginTop: 0, color: 'black' }}>
            Let&apos;s get your account setup. Start by providing the following details:
          </p>

          {this.renderFields()}

          <div className="c-controls">
            <CenteredLayout.Link onClick={(e) => props.onStateChange('signIn', {})}>
              Back to Sign in
            </CenteredLayout.Link>
            <Button
              loading={this.state.loading}
              className="c-reset-button"
              onClick={this.onSignUp}
              style={{ width: 160 }}
              tabIndex={50}
              type="primary"
              size="large"
            >
              {authData.existed ? 'Add' : 'Setup'} Account
            </Button>
          </div>

          {this.state.error && <div className="c-error">{this.state.error}</div>}

          {/* hidden submit button */}
          <button type="submit" style={{ position: 'absolute', left: -9999 }} />
        </StyledForm>
      </CenteredLayout>
    )
  }
}

export default Form.create()(SignUpComponent)
