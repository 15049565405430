import { ApolloProvider } from '@apollo/client'
import AddTag from 'components/modals/AddTag'
import ImageUpload from 'components/modals/ImageUpload'
import { SendEmail } from 'components/modals/SendEmail'
import { useApollo } from 'lib/apollo/client'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useSmartfieldFactory } from 'stores/smartfields/factory.hooks'
import { AppModalsAndDrawers } from './App.modals'
import ProductFruitsWrapper from './base/ProductFruits/ProductFruitsWrapper'
import Routes from './routes'

const App = () => {
  const apolloClient = useApollo()
  useSmartfieldFactory()

  useEffect(() => {
    global.router.gotoIntended()
  }, [])

  return (
    <ApolloProvider client={apolloClient}>
      <React.Fragment>
        <ProductFruitsWrapper />
        <Routes />
        <SendEmail />
        <ImageUpload />
        <AddTag />
        <AppModalsAndDrawers />
      </React.Fragment>
    </ApolloProvider>
  )
}

export default observer(App)
