import { findIndex } from 'lodash'

const levels = ['USER', 'MANAGER', 'ADMIN', 'SUPER_ADMIN', 'PULSE_ADMIN'] as const

type Levels = typeof levels
export type Level = Levels[number]

// const levels = [
//   'TEAM_MEMBER',
//   'SENIOR_TEAM_MEMBER',
//   'ADVISOR',
//   'ADMIN_MANAGER',
//   'SUPER_ADMIN'
// ]

export const atLeast = (userRole: Level, required: Level) => {
  const userLevel = findIndex(levels, (r) => r === userRole)
  const requiredLevel = findIndex(levels, (r) => r === required)
  return userLevel >= requiredLevel
}

export const atMost = (userRole: Level, required: Level) => {
  const userLevel = findIndex(levels, (r) => r === userRole)
  const requiredLevel = findIndex(levels, (r) => r === required)
  return userLevel <= requiredLevel
}

// all roles as an object { ADMIN: 'ADMIN' }

const roles = levels.reduce((all, role) => {
  all[role] = role
  return all
}, {} as Record<Level, Level>)

export default roles
