import { useEffect, useState } from 'react'

interface Provider {
  value: string
  label: string
  icon: any
}

export const useProviders = () => {
  const [providers, setProviders] = useState<Provider[]>([])

  useEffect(() => {
    const connections = global.connections.installed
    setProviders(
      connections.map((conn) => ({
        value: conn.identifier,
        label: conn.name,
        icon: conn.Icon,
      })),
    )
  }, [])

  return {
    providers,
  }
}
