/* eslint-disable react-hooks/exhaustive-deps */
import { PROVIDERS, isConnected } from "constants/connections"
import { useEffect, useMemo, useState } from "react"
import { msg } from "stores/msg"

export const usePreciseFP = () => {
  const [templates, setTemplates] = useState([])
  const [accounts, setAccounts] = useState([])
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [connected, setConnected] = useState(false)

  const onSearchAccount = async (query: any) => {
    return global.precisefp.searchAccount(query)
  }

  const onAccountSelect = (accountData: any) => {
    setSelectedAccount(accountData)
  }

  const onTemplateSelect = (templateId: string) => {
    const template = templates.find(({ id }) => id === templateId)
    setSelectedTemplate(template!)
  }

  useEffect(() => {
    const connection = global.connections.getConnection(PROVIDERS.PRECISEFP)
    setConnected(connection && isConnected(connection.state) || false)
  }, [])

  useEffect(() => {
      global.precisefp
        .listAccounts()
        .then((res: any) => {
          if (res.error) {
            throw res.error.code
          }
          setAccounts(res)
        })
        .catch(() => {
          msg.error(
            'Your PreciseFP needs to be re-connected. Please update by going to Settings > Integration > PreciseFP and re-authenticate', ''
          )
        })
  }, [])

  useEffect(() => {
      global.precisefp
        .listTemplates()
        .then((res: any) => {
          if (res.error) {
            throw res.error.code
          }
          setTemplates(res)
        })
        .catch(() => {
          msg.error(
            'Your PreciseFP needs to be re-connected. Please update by going to Settings > Integration > PreciseFP and re-authenticate', ''
          )
        })
  }, [])

  return useMemo(() => ({
    connected,
    templates,
    accounts,
    selectedAccount,
    selectedTemplate,
    onSearchAccount,
    onAccountSelect,
    onTemplateSelect,
  }), [
    connected,
    templates,
    accounts,
    selectedAccount,
    selectedTemplate,
    onSearchAccount,
    onAccountSelect,
    onTemplateSelect,
  ])
}
