import { AbstractStore } from 'helpers/pm'
import { makeObservable, observable } from 'mobx'
import permissionService from 'stores/services/permission.service'
import { Plan } from 'types/graphql'

class UpgradePlanPM extends AbstractStore {
  plans: Plan[] = []
  constructor() {
    super()

    makeObservable(this, {
      plans: observable,
    })

    this.loadPlans()
  }

  loadPlans = async () => {
    const { plans } = await permissionService.plans()
    this.plans = plans.filter((plan) => plan.enabled || plan.id === global.data.practice.planId)
  }

  upgradePlan = async (planId: string) => {
    global.app.wait = 'Upgrading plan...'
    await permissionService.changePlan({ planId })
    window.location.reload()
    global.app.wait = false
  }
}

export default UpgradePlanPM
