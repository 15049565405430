import AES from 'crypto-js/aes'
import Utf8 from 'crypto-js/enc-utf8'
import { isEmpty } from 'lodash'

/**
 * TODO restore functionality based on hash (id/passkey)
 */
export function encrypt(data) {
  // return CryptoJS.AES.encrypt(JSON.stringify(data), process.env.PULSE_ACCESS).toString()
  return AES.encrypt(JSON.stringify(data), 'burnthill').toString()
}

/**
 * AWS
 */

export const signToken = () => {
  try {
    return encrypt(global.stores.auth.token)
  } catch (err) {
    return ''
  }
}

/**
 * TODO restore functionality based on hash (id/passkey)
 */
export function decrypt(data) {
  // const bytes = CryptoJS.AES.decrypt(data, process.env.PULSE_ACCESS)
  const bytes = AES.decrypt(data, 'burnthill')
  const keyStr = bytes.toString(Utf8)
  return isEmpty(keyStr) ? false : JSON.parse(keyStr)
}
