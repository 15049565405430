import { observable, action, computed, makeObservable, runInAction } from 'mobx'
import { STATES, CATEGORIES } from 'constants/connections'
import { AbstractStore } from 'helpers/pm'
import { filter, find, isEmpty } from 'lodash'

import google_drive from './google_drive'
import googletest from './googletest'
import riskalyze from './riskalyze'
import holistiplan from './holistiplan'
import precisefp from './precisefp'
import hubspot from './hubspot'
import zoho from './zoho'

const activeConnections = {
  google_drive,
  googletest,
  riskalyze,
  holistiplan,
  precisefp,
  hubspot,
  zoho,
}

class Connections extends AbstractStore {
  connections = new Map()
  categories = CATEGORIES
  states = STATES

  get installed() {
    return filter(this._connections, (i) => {
      let allowed = true
      if (!isEmpty(i.permissionKey)) {
        allowed = global.perms.get(i.permissionKey)
      }

      return i.state !== STATES.HIDDEN && allowed
    })
  }

  get _connections() {
    return Array.from(this.connections.values())
  }

  get connected() {
    return filter(this._connections, { state: STATES.CONNECTED })
  }

  get disconnected() {
    return filter(this._connections, { state: STATES.DISCONNECTED })
  }

  constructor(props) {
    super(props)

    makeObservable(this, {
      connections: observable,
      installed: computed,
      connected: computed,
      disconnected: computed,
      _connections: computed,
      setConnections: action.bound,
      updateConnection: action.bound,
    })

    this.storeState()
  }

  /**
   * Add connections from login response
   * @param {*} connections
   */
  setConnections(connections = [], adminConnections = []) {
    const all = connections.concat(adminConnections)
    const conns = all
      .filter(({ state }) => state !== 'HIDDEN')
      .map((c) => {
        const name = c.identifier
        // local connection identifier with advisor id removed
        const local = c.identifier.split('-')[0]
        const Connection = activeConnections[local]
        const conn = { ...Connection, ...c, local }
        this[name] = conn
        return [name, conn]
      })

    this.connections.replace(conns)
  }

  /**
   * Add connections from login response
   * @param {*} connections
   */
  updateConnection(identifier, connection) {
    const conn = this.getConnection(identifier)

    runInAction(() => {
      Object.keys(connection).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(conn, key)) {
          conn[key] = connection[key]
        }
      })
    })
  }

  /**
   *
   */
  getConnection = (identifier) => {
    let connection = find(this._connections, { identifier })

    // Advisor level connection identifiers, have the advisor id appended to the usual connection identifier.
    // If no connection is found, we try to find by connection's 'local' identifier - advisor id removed
    // eg.
    //  identifier = precisefp-2
    //  local = precisefp
    //
    if (!connection) {
      connection = find(this._connections, { local: identifier })
    }

    return connection
  }
}

export default Connections
