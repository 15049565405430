import { Transaction } from 'remirror'

import { EditorState, replaceNodeAtPosition } from 'remirror'
import { DISPLAY_MODE } from '../../smartFieldAtom/utils/displayMode.constant'
import { insertNodeAtPosition } from '../NoteReplacer.helpers'
import { MergeFieldArea, MergeFieldContentToReplace, MergeFieldToReplace, NotesGroup } from '../NoteReplacer.interfaces'

import { processMergeFieldToReplace as processMergeFieldToReplaceBulletList } from './displayHandlers/NoteReplacerBulletListDisplay.handler'
import { processMergeFieldToReplace as processMergeFieldToReplaceCheckList } from './displayHandlers/NoteReplacerCheckListDisplay.handler'
import { processMergeFieldToReplace as processMergeFieldToReplaceInline } from './displayHandlers/NoteReplacerInlineDisplay.handler'
import { processMergeFieldToReplace as processMergeFieldToReplaceOrderedList } from './displayHandlers/NoteReplacerOrderedListDisplay.handler'
import { processMergeFieldToReplace as processMergeFieldToReplaceParagraph } from './displayHandlers/NoteReplacerParagraphDisplay.handler'
import { processMergeFieldToReplace as processMergeFieldToReplacePlaceholder } from './displayHandlers/NoteReplacerPlaceholderDisplay.handler'
import { removeEmptyNode } from './utils/removeEmptyNode.util'

export function replaceInlineMentionAtom(
  tr: Transaction,
  state: Readonly<EditorState>,
  placeholderArea: MergeFieldArea,
  notes: NotesGroup[],
) {
  const [placeholder] = placeholderArea.items
  const [mergeFieldToReplace] = placeholder.children! as [MergeFieldToReplace]

  const contentToReplace = processInlineItem(state, mergeFieldToReplace as MergeFieldToReplace, notes)

  if (contentToReplace) {
    const { content, pos, displayMode } = contentToReplace

    if (!content.length) {
      removeEmptyNode({ pos: pos, tr })
      return
    }
    if (displayMode === DISPLAY_MODE.INLINE) {
      replaceNodeAtPosition({
        tr,
        pos,
        content,
      })
    } else {
      content.reverse().forEach((element, index) => {
        if (index === 0) {
          replaceNodeAtPosition({
            tr,
            pos,
            content: element,
          })
        } else {
          insertNodeAtPosition({
            tr,
            pos,
            content: element,
          })
        }
      })
    }
  } else {
    removeEmptyNode({ pos: placeholderArea.pos, tr })
  }
}

/**
 * Wraps all elements inside a ListItem in a `listItem`.
 * The input in `placeholderListItem` can either be a `ListItem` (top level list item) or a `SubListItem` (second and so on list items).
 * @param state
 * @param listItem
 * @param notesGroup
 * @returns `ProsemirrorNode` | `null`
 */
function processInlineItem(
  state: Readonly<EditorState>,
  mergeFieldToReplace: MergeFieldToReplace,
  notesGroup: NotesGroup[],
): MergeFieldContentToReplace | null {
  const contentToReplace = processMergeFieldToReplaceByDisplayMode(state, mergeFieldToReplace, notesGroup)
  if (contentToReplace) {
    return {
      ...contentToReplace,
      content: contentToReplace.content,
    }
  }
  return null
}

function processMergeFieldToReplaceByDisplayMode(
  state: Readonly<EditorState>,
  mergeFieldToReplace: MergeFieldToReplace,
  content: NotesGroup[],
): MergeFieldContentToReplace | null {
  if (mergeFieldToReplace.kind === 'placeholder') {
    return processMergeFieldToReplacePlaceholder(state, mergeFieldToReplace, content)
  } else if (mergeFieldToReplace.displayMode === DISPLAY_MODE.ULLIST) {
    return processMergeFieldToReplaceBulletList(state, mergeFieldToReplace, content)
  } else if (mergeFieldToReplace.displayMode === DISPLAY_MODE.OLLIST) {
    return processMergeFieldToReplaceOrderedList(state, mergeFieldToReplace, content)
  } else if (mergeFieldToReplace.displayMode === DISPLAY_MODE.INLINE) {
    return processMergeFieldToReplaceInline(state, mergeFieldToReplace, content)
  } else if (mergeFieldToReplace.displayMode === DISPLAY_MODE.CHECKLIST) {
    return processMergeFieldToReplaceCheckList(state, mergeFieldToReplace, content)
  } else {
    return processMergeFieldToReplaceParagraph(state, mergeFieldToReplace, content)
  }
}
