import { GetStaticAndDynamic, Static } from 'remirror'
import { ToolbarConfig } from '../components/toolbar/Toolbar.component'
import { PulseOptions, PulsePreset } from './pulse.preset'

export type ExcludeExtensionKey = PulsePreset['name']

interface Config {
  options: GetStaticAndDynamic<PulseOptions> & { embedTableColwidth?: boolean }
  excludeExtensions?: Static<ExcludeExtensionKey[]>
  toolbarConfig: ToolbarConfig
}

export const CONFIG_NAMES = {
  PREVIEW: 'preview',
  FULL: 'full',
  NOTE: 'note',
  STANDARD: 'standard',
  DOC_EDITOR: 'doc_editor',
  EXPORT: 'export',
  AI_WRITER: 'ai_writer',
} as const

export type EDITOR_CONFIG = (typeof CONFIG_NAMES)[keyof typeof CONFIG_NAMES]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const REMIRROR_CONFIGS: Record<(typeof CONFIG_NAMES)[keyof typeof CONFIG_NAMES], Config> = {
  [CONFIG_NAMES.PREVIEW]: {
    // @ts-ignore
    options: {},
    toolbarConfig: {},
  },
  [CONFIG_NAMES.DOC_EDITOR]: {
    options: {
      enableResizing: true,
    },
    excludeExtensions: [],
    toolbarConfig: {},
  },
  [CONFIG_NAMES.FULL]: {
    options: {
      enableResizing: true,
    },
    toolbarConfig: {
      mergeFields: true,
    },
  },
  [CONFIG_NAMES.NOTE]: {
    options: {
      enableResizing: true,
    },
    // excludeExtensions: ['fontFamily', 'fontSize', 'mentionAtom'],
    excludeExtensions: ['fontFamily', 'fontSize'],
    toolbarConfig: {
      showPrecisefp: true,
      showRiskalyze: true,
      showLineHeight: false,
      undoRedo: false,
    },
  },
  [CONFIG_NAMES.STANDARD]: {
    options: {
      enableResizing: true,
    },
    excludeExtensions: ['mentionAtom'],
    toolbarConfig: {},
  },
  [CONFIG_NAMES.EXPORT]: {
    options: {
      enableResizing: true,
      embedTableColwidth: true,
    },
    toolbarConfig: {
      mergeFields: true,
    },
  },
  [CONFIG_NAMES.AI_WRITER]: {
    options: {
      enableResizing: true,
    },
    excludeExtensions: ['fontFamily', 'fontSize', 'mentionAtom'],
    toolbarConfig: {
      showPrecisefp: false,
      showRiskalyze: false,
      showLineHeight: false,
      undoRedo: false,
    },
  },
}
