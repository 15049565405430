import { AbstractStore } from 'helpers/pm'
import { makeObservable, observable, observe } from 'mobx'
import appointmentTypeService from '../services/appointmentType.service'

class AppointmentTypes extends AbstractStore {
  appointmentTypes = []
  appointmentTypesExternal = []

  constructor() {
    super()

    makeObservable(this, {
      appointmentTypes: observable,
    })

    observe(
      global.stores.auth,
      'loggedIn',
      (c) => {
        if (c.newValue) {
          this.loadAppointmentTypes()
        }
      },
      true,
    )

    this.storeState()
  }

  async loadAppointmentTypes() {
    const appointmentTypes = await appointmentTypeService.getAppointmentTypes()
    this.appointmentTypes = appointmentTypes
    return appointmentTypes
  }

  async loadAppointmentTypesExternal() {
    const appointmentTypesExternal = await appointmentTypeService.getAppointmentTypesExternal()
    this.appointmentTypesExternal = appointmentTypesExternal
    return appointmentTypesExternal
  }

  async updateAppointmentTypes(appointmentTypes) {
    await appointmentTypeService.setAppointmentTypes({ appointmentTypes })
    await this.loadAppointmentTypes()
    return true
  }
}

export default AppointmentTypes
