import initIntercom, { update } from 'helpers/intercom'
import { PERMS_KEYS } from '../constants/perms.constants'
import { isPROD } from '../helpers/env'
import { identifyUser } from '../helpers/posthog'
import { captureExceptionSilently } from '../helpers/sentry'
import { Advisor, Connection, Integration, IntercomPayload, Practice } from '../types/graphql'
import statsService from './services/stats.service'

export const PRODUCTFRUITS_TOURS = {
  ADD_NOTES_OPTIONS: 53593,
  NOTE_BEST_PRACTICES: 53594,
} as const

class SupportAndMarketing {
  intercomPayload: IntercomPayload | null = null

  getIntercomPayload = async ({ forceUpdate } = { forceUpdate: false }) => {
    if (!this.intercomPayload || forceUpdate) {
      this.intercomPayload = await statsService.intercomPayload()
    }

    return this.intercomPayload
  }

  /** START INTERCOM */
  async initializeIntercom() {
    const isAdmin = !!global.data.advisors.isAdmin

    if (!isAdmin && isPROD && global.perms.get(PERMS_KEYS.INTERCOM)) {
      try {
        const payload = await this.getIntercomPayload()

        initIntercom(payload)
        this._initializeIntercomUpdates()
      } catch (error) {
        // TODO: handle this error in a better way
        console.error('Error trying to get data to identify')

        captureExceptionSilently(error, {
          message: 'initializeIntercom',
          data: {},
        })
      }
    }
  }

  _initializeIntercomUpdates() {
    const tenMinutes = 1000 * 60 * 10

    const intervalId = setInterval(() => {
      this._updateIntercom().catch(() => {
        clearInterval(intervalId)
      })
    }, tenMinutes)

    const logData = () => {
      if (document.visibilityState === 'hidden') {
        this._updateIntercom().catch(() => {})
      }
    }

    document.addEventListener('visibilitychange', logData)
  }

  async _updateIntercom() {
    const advisor = global.data.advisors.me
    if (advisor) {
      const payload = await this.getIntercomPayload({ forceUpdate: true })

      update(payload)
    } else {
      throw new Error()
    }
  }

  /** END INTERCOM */
  /** START POSTHOG */
  initializePosthog = async (
    {
      advisor,
      practice,
      connections,
      integrations,
    }: { advisor: Advisor; practice: Practice; connections: Connection[]; integrations: Integration[] },
    domain: string,
  ): Promise<void> => {
    if (isPROD) {
      try {
        const [advisorsCount, appointmentsCount, clientsCount, notesCount] = await statsService.statsCount()
        const user = advisor || null

        const posthogData = {
          name: domain,
          user,
          practice,
          connections,
          integrations,
          advisorsCount,
          appointmentsCount,
          clientsCount,
          notesCount,
        }
        identifyUser(posthogData)
      } catch (error) {
        // TODO: handle this error in a better way
        console.error('Error trying to get data to identify')

        captureExceptionSilently(error, {
          message: 'initializePosthog',
          data: { advisor, practice, connections, integrations },
        })
      }
    }
  }
  /** END POSTHOG */

  //** START PRODUCTFRUITS */

  initializeProductFruits = async (advisor: Advisor) => {
    const payload = await this.getIntercomPayload()

    return {
      username: advisor.email!,
      email: advisor.email!,
      firstname: advisor.firstName!,
      lastname: advisor.lastName || '',
      signUpAt: advisor.createdAt!,
      role: advisor.role!,
      props: {
        workspace: global.data.practice.domain,
        CRM: payload.CRM,
        Riskalyze: payload.Riskalyze,
        PreciseFP: payload.PreciseFP,
        WorkspaceNotes: payload.NoteCount,
        WorkspaceEmailSent: payload.WorkspaceEmailCount,
        WorkspaceTemplateCount: payload.TemplateCount,
        WorkspaceLibraryCount: payload.NoteTemplateCount,
        WorkspaceCollectionCount: payload.CollectionsCount,
        WorkspaceDocumentCount: payload.DocumentCount,
      },
    }
  }

  //** END PRODUCTFRUITS */
}

export default new SupportAndMarketing()
