import { STATES } from 'constants/integrations'
import * as apiService from 'lib/apollo/client'
import qs from 'qs'
import { connectCRM, disconnectCRM, syncCRM } from 'stores/apollo/query/integration.gql'
import { msg } from 'stores/msg'

/**
 * Disable the integration
 */
const disconnect = (integration) => {
  update({ state: STATES.DISABLED }, integration)
}

/**
 * @param {*} fields
 */
const connect = async (fields) => {
  const { connectCRM: resp } = await apiService.mutate(connectCRM, {
    credentials: fields,
  })

  const integration = global.ext.getIntegration(fields.identifier)
  if (resp.ok) {
    if (fields.identifier === 'salesforce') {
      connectSalesForce(fields)
    } else {
      msg.success('Integration connected, starting initial sync ..', 5)
      integration.state = STATES.ENABLED
    }
  } else {
    msg.error(resp.error.message, 20)
    integration.state = STATES.DISABLED
  }
}

/**
 * @param {*} fields
 */
const connectSalesForce = async (fields) => {
  const params = qs.stringify({
    redirect_uri: window.location.href,
    /* client_secret: fields.key2, */
    client_id: fields.key1,
    response_type: 'code',
  })
  const inctanceURL = `${fields.baseUrl}/services/oauth2/authorize?${params}`
  document.location.replace(inctanceURL)
}

/**
 * Update the integration
 */
const update = async (fields, integration) => {
  const { disconnectCRM: success } = await apiService.mutate(disconnectCRM, {
    integration: fields,
    id: integration.id,
  })
  if (success) {
    Object.keys(fields).forEach((key) => {
      integration[key] = fields[key]
    })
  }
}

/**
 * refresh synchronization
 */
const manualSync = async () => {
  await apiService.mutate(syncCRM).then((response) => {
    if (response) {
      msg.success('Synchronized')
    } else {
      msg.error('Synchronize failed')
    }
  })
}

const methods = {
  manualSync,
  disconnect,
  connect,
  update,
}

export default methods
