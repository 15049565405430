import * as apiService from 'lib/apollo/client'
import DataError, { IsError } from '../../helpers/dataError'
import {
  holistiplanAllClients,
  holistiplanLoadClient,
  holistiplanLoadTaxRecords
} from '../apollo/query/holistiplan.gql'

export const allClients = async () => {
  const { holistiplanAllClients: resp } = await apiService.mutate(holistiplanAllClients, {})
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.clients
}

export const loadClient = async (clientId) => {
  const { holistiplanLoadClient: resp } = await apiService.mutate(holistiplanLoadClient, { clientId })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.client
}

export const loadTaxRecords = async (clientId) => {
  const { holistiplanLoadTaxRecords: resp } = await apiService.mutate(holistiplanLoadTaxRecords, { clientId })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.records
}


