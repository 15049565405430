import { BidiExtension, BidiOptions } from '@remirror/extension-bidi'
import { BlockquoteExtension } from '@remirror/extension-blockquote'
import { BoldExtension, BoldOptions } from '@remirror/extension-bold'
import { CodeExtension } from '@remirror/extension-code'
import { CodeBlockExtension, CodeBlockOptions } from '@remirror/extension-code-block'
import { CountExtension } from '@remirror/extension-count'
import { DropCursorExtension, DropCursorOptions } from '@remirror/extension-drop-cursor'
import { IframeExtension } from '@remirror/extension-embed'
import { FontFamilyExtension } from '@remirror/extension-font-family'
import { FontSizeExtension } from '@remirror/extension-font-size'
import { GapCursorExtension } from '@remirror/extension-gap-cursor'
import { HardBreakExtension } from '@remirror/extension-hard-break'
import { HistoryExtension } from '@remirror/extension-history'
import { HorizontalRuleExtension } from '@remirror/extension-horizontal-rule'
import { ImageExtension, ImageOptions } from '@remirror/extension-image'
import { ItalicExtension } from '@remirror/extension-italic'
import { LinkOptions } from '@remirror/extension-link'
import { BulletListExtension, OrderedListExtension, TaskListExtension } from '@remirror/extension-list'
import { MentionAtomExtension } from '@remirror/extension-mention-atom'
import { NodeFormattingExtension } from '@remirror/extension-node-formatting'
import { ParagraphExtension } from '@remirror/extension-paragraph'
import { ShortcutsExtension } from '@remirror/extension-shortcuts'
import { StrikeExtension } from '@remirror/extension-strike'
import { SubExtension } from '@remirror/extension-sub'
import { SupExtension } from '@remirror/extension-sup'
import { TextCaseExtension } from '@remirror/extension-text-case'
import { TextColorExtension } from '@remirror/extension-text-color'
import { TextHighlightExtension } from '@remirror/extension-text-highlight'
import { TrailingNodeExtension } from '@remirror/extension-trailing-node'
import { UnderlineExtension } from '@remirror/extension-underline'
import { DocExtension, DocOptions, EmojiExtension, TextExtension } from 'remirror/extensions'
import { ClearFormattingExtension } from '../extensions/clearFormatting/ClearFormatting.extension'
import { EmptyExtension } from '../extensions/empty/Empty.extension'
import { FontFamilyCustomExtension } from '../extensions/fontFamily/FontFamilyCustom.extension'
import { LinkExtension } from '../extensions/link/LinkCustom.extension'
import { ListItemSharedCustomExtension } from '../extensions/list/ListItemSharedCustom.extension'
import { SmartFieldAtomExtension } from '../extensions/smartFieldAtom/extension'
import { smartFieldAtomConfig } from '../extensions/smartFieldAtom/config'
import { NodeFormattingCustomExtension } from '../extensions/nodeFormatting/NodeFormatingCustom.extension'
import { NodeTypeExtension } from '../extensions/nodeType/NodeType.extension'
import { NoteReplacerExtension } from '../extensions/noteReplacer/NoteReplacer.extension'
import { TableCustomExtension } from '../extensions/table/TableCustom.extension'
import { TextColorCustomExtension } from '../extensions/textColor/TextColorCustom.extension'
import { TextHighlightCustomExtension } from '../extensions/textHighlight/TextHighlightCustom.extension'

import { ExtensionPriority } from 'remirror'
import data from 'svgmoji/emoji.json'
import { HorizontalRuleCustomExtension } from '../extensions/horizontalRule/HorizontalRuleCustom.extension'
import { ImageCustomExtension } from '../extensions/image/ImageCustom.extension'
import { ImageCustomOptions } from '../extensions/image/ImageCustom.options'
import { TableCustomForExportExtension } from '../extensions/table/tableExport/TableCustomForExport.extension'
import { EDITOR_CONFIG, ExcludeExtensionKey, REMIRROR_CONFIGS } from './configs'

export interface PulseOptions
  extends BidiOptions,
    BoldOptions,
    CodeBlockOptions,
    DropCursorOptions,
    LinkOptions,
    DocOptions,
    ImageOptions {}

const DEFAULT_OPTIONS = {
  ...DocExtension.defaultOptions,
  ...ParagraphExtension.defaultOptions,
  ...BidiExtension.defaultOptions,
  ...BoldExtension.defaultOptions,
  ...CodeBlockExtension.defaultOptions,
  ...DropCursorExtension.defaultOptions,
  ...ImageExtension.defaultOptions,
}

/**
 * Create the wysiwyg preset which includes all the more exotic extensions
 * provided by the `remirror` core library.
 */

/* IMPORTANT! IF YOU ADD AN EXTENSION THAT MODIFIES THE ATTRIBUTES, PLEASE UPDATE THE FOLLOWING FUNCTION
NoteReplacer.helpers.ts/hasDefaultAttrs */
export function pulsePreset(configName: EDITOR_CONFIG): PulsePreset[] {
  const config = REMIRROR_CONFIGS[configName]
  const options = { ...DEFAULT_OPTIONS, ...config.options }
  const excludeMap: Partial<Record<ExcludeExtensionKey, boolean>> = {}

  for (const name of config.excludeExtensions ?? []) {
    excludeMap[name] = true
  }

  const pulseExtensions: PulsePreset[] = []

  // This is always enabled. we added manually just in case
  const historyExtension = new HistoryExtension()
  pulseExtensions.push(historyExtension)

  // DOC is required
  const { content } = options
  const docExtension = new DocExtension({ content })
  pulseExtensions.push(docExtension)

  // Text is required
  const textExtension = new TextExtension()
  pulseExtensions.push(textExtension)

  // Paragraph is required
  const paragraphExtension = new ParagraphExtension()
  pulseExtensions.push(paragraphExtension)

  // GapCursor is required
  const gapCursorExtension = new GapCursorExtension()
  pulseExtensions.push(gapCursorExtension)

  // CountExtension is required. We use this to check if empty
  const countExtension = new CountExtension()
  pulseExtensions.push(countExtension)

  if (!excludeMap['hardBreak']) {
    const hardBreakExtension = new HardBreakExtension()
    pulseExtensions.push(hardBreakExtension)
  }

  if (!excludeMap['horizontalRule']) {
    const horizontalRuleExtension = new HorizontalRuleCustomExtension()
    pulseExtensions.push(horizontalRuleExtension)
  }

  if (!excludeMap['image']) {
    const { enableResizing } = options
    const imageExtension = new ImageCustomExtension({ ...ImageCustomOptions, enableResizing })
    pulseExtensions.push(imageExtension)
  }

  if (!excludeMap['emoji']) {
    const emojiExtension = new EmojiExtension({ data: data, moji: 'noto', identifier: 'emoji', plainText: true })
    pulseExtensions.push(emojiExtension)
  }

  if (!excludeMap['italic']) {
    const italicExtension = new ItalicExtension()
    pulseExtensions.push(italicExtension)
  }

  if (!excludeMap['strike']) {
    const strikeExtension = new StrikeExtension({ priority: ExtensionPriority.Lowest })
    pulseExtensions.push(strikeExtension)
  }

  if (!excludeMap['underline']) {
    const underlineExtension = new UnderlineExtension({ priority: ExtensionPriority.Lowest + 1 })
    pulseExtensions.push(underlineExtension)
  }

  if (!excludeMap['blockquote']) {
    const blockquoteExtension = new BlockquoteExtension()
    pulseExtensions.push(blockquoteExtension)
  }

  if (!excludeMap['code']) {
    const codeExtension = new CodeExtension()
    pulseExtensions.push(codeExtension)
  }

  if (!excludeMap['iframe']) {
    const iframeExtension = new IframeExtension()
    pulseExtensions.push(iframeExtension)
  }

  if (!excludeMap['bulletList']) {
    const bulletListExtension = new BulletListExtension()
    pulseExtensions.push(bulletListExtension)
  }

  if (!excludeMap['orderedList']) {
    const orderedListExtension = new OrderedListExtension()
    pulseExtensions.push(orderedListExtension)
  }

  if (!excludeMap['taskList']) {
    const taskListExtension = new TaskListExtension({})
    pulseExtensions.push(taskListExtension)
  }

  if (!excludeMap['listItemSharedCustom']) {
    const listItemSharedCustomExtension = new ListItemSharedCustomExtension()
    pulseExtensions.push(listItemSharedCustomExtension)
  }

  if (!excludeMap['shortcuts']) {
    const shortcutsExtension = new ShortcutsExtension()
    pulseExtensions.push(shortcutsExtension)
  }

  if (!excludeMap['link']) {
    const { selectTextOnClick } = options
    const linkExtension = new LinkExtension({
      autoLink: true,
      selectTextOnClick,
      defaultTarget: '_blank',
      defaultProtocol: 'https:',
    })
    pulseExtensions.push(linkExtension)
  }

  if (!excludeMap['bidi']) {
    const { autoUpdate, defaultDirection, excludeNodes } = options
    const bidiExtension = new BidiExtension({
      autoUpdate,
      defaultDirection,
      excludeNodes,
    })
    pulseExtensions.push(bidiExtension)
  }

  if (!excludeMap['bold']) {
    const { weight } = options
    const boldExtension = new BoldExtension({ weight })
    pulseExtensions.push(boldExtension)
  }

  if (!excludeMap['fontSize']) {
    const fontSizeExtension = new FontSizeExtension({
      unit: 'px',
      defaultSize: '14',
    })
    pulseExtensions.push(fontSizeExtension)
  }

  if (!excludeMap['textHighlight']) {
    const textHighlightExtension = new TextHighlightCustomExtension()
    pulseExtensions.push(textHighlightExtension)
  }

  if (!excludeMap['textColor']) {
    const textColorExtension = new TextColorCustomExtension()
    pulseExtensions.push(textColorExtension)
  }

  if (!excludeMap['fontFamily']) {
    const fontFamilyExtension = new FontFamilyCustomExtension()
    pulseExtensions.push(fontFamilyExtension)
  }

  if (!excludeMap['sub']) {
    const subExtension = new SubExtension()
    pulseExtensions.push(subExtension)
  }

  if (!excludeMap['sup']) {
    const supExtension = new SupExtension()
    pulseExtensions.push(supExtension)
  }

  if (!excludeMap['textCase']) {
    const textCaseExtension = new TextCaseExtension()
    pulseExtensions.push(textCaseExtension)
  }

  if (!excludeMap['codeBlock']) {
    const { defaultLanguage, formatter, toggleName, syntaxTheme, supportedLanguages } = options
    const codeBlockExtension = new CodeBlockExtension({
      defaultLanguage,
      formatter,
      toggleName,
      syntaxTheme,
      supportedLanguages,
    })
    pulseExtensions.push(codeBlockExtension)
  }

  if (!excludeMap['dropCursor']) {
    const { color, width } = options
    const dropCursorExtension = new DropCursorExtension({
      color,
      width,
    })
    pulseExtensions.push(dropCursorExtension)
  }

  if (!excludeMap['mentionAtom']) {
    const smartFieldAtomExtension = new SmartFieldAtomExtension(smartFieldAtomConfig)
    pulseExtensions.push(smartFieldAtomExtension)
  }

  if (!excludeMap['table']) {
    const tableExtension = options.embedTableColwidth ? new TableCustomForExportExtension() : new TableCustomExtension()
    pulseExtensions.push(tableExtension)
  }

  if (!excludeMap['nodeFormatting']) {
    const nodeFormattingExtension = new NodeFormattingCustomExtension()
    pulseExtensions.push(nodeFormattingExtension)
  }

  if (!excludeMap['clearFormatting']) {
    const clearFormattingExtension = new ClearFormattingExtension()
    pulseExtensions.push(clearFormattingExtension)
  }

  if (!excludeMap['nodeType']) {
    const nodeTypeExtension = new NodeTypeExtension()
    pulseExtensions.push(nodeTypeExtension)
  }

  if (!excludeMap['noteReplacer']) {
    const noteReplacerExtension = new NoteReplacerExtension()
    pulseExtensions.push(noteReplacerExtension)
  }

  if (!excludeMap['empty']) {
    const emptyExtension = new EmptyExtension()
    pulseExtensions.push(emptyExtension)
  }

  return pulseExtensions
}

/**
 * The union of types for all the extensions provided by the `pulsePreset`
 * function call.
 */
export type PulsePreset =
  | BidiExtension
  | BlockquoteExtension
  | BoldExtension
  | BulletListExtension
  | ClearFormattingExtension
  | CodeBlockExtension
  | CodeExtension
  | CountExtension
  | DocExtension
  | DropCursorExtension
  | EmojiExtension
  | EmptyExtension
  | FontFamilyExtension
  | FontSizeExtension
  | GapCursorExtension
  | HardBreakExtension
  | HistoryExtension
  | HorizontalRuleExtension
  | IframeExtension
  | ImageCustomExtension
  | ItalicExtension
  | LinkExtension
  | ListItemSharedCustomExtension
  | MentionAtomExtension
  | NodeFormattingExtension
  | NodeTypeExtension
  | NoteReplacerExtension
  | OrderedListExtension
  | ParagraphExtension
  | ShortcutsExtension
  | StrikeExtension
  | SubExtension
  | SupExtension
  | TableCustomExtension
  | TableCustomForExportExtension
  | TaskListExtension
  | TextCaseExtension
  | TextColorExtension
  | TextExtension
  | TextHighlightExtension
  | TrailingNodeExtension
  | UnderlineExtension
