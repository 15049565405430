/* eslint-disable @typescript-eslint/ban-ts-comment */

import { trackEvent } from "helpers/posthog"
import { compact } from "lodash"
import { EmailInput } from "types/graphql"

/**
 * @param to
 * @param cc
 * @param bcc
 * @returns
 */
export const totalRecipients = (config: EmailInput) => {
  return [...(config.to || []), ...(config.cc || []), ...(config.bcc || [])].length
}

/**
 * @param config
 */
export const trackSendEmail = (config: EmailInput) => {
  const { getTemplateCategory } = global.data.templates
    const templateCategory = getTemplateCategory(config.templateCategoryId as string)

  const emailStats = {
    numberOfAttachments: (config.files || []).length,
    numberOfRecipients: totalRecipients(config),
    category: templateCategory?.name ?? ""
  }

  trackEvent('Sent Email', emailStats)
}

/**
 * @param config
 * @param client
 */
export const addIsClientFacing = (config: EmailInput) => {
  const { client } = global.data.clients

  // @ts-ignore
  const addresses = compact([...config.to, ...config.cc, ...config.bcc])
  const includesClient = addresses
    .map(({ email }) => String(email).trim())
    .includes(String(client?.email))

  if (includesClient) {
    config.clientFacing = true
  }
}
