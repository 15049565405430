import { Placeholder } from 'components/froala/types/mapper'
import { computed, makeObservable, observable } from 'mobx'
import { Nullable } from 'types/helpers'
import { uniqueId } from '../utils'
import MergeMap from '../_merge-map'

class BaseMapper {
  mergeMap: Nullable<MergeMap> = null
  _value?: string | HTMLElement
  nodes: HTMLElement[] = []
  id: string
  options: any
  label: string
  type: Nullable<string> = null
  static isCustom = true

  get nodeIds() {
    return this.nodes.map((n) => n.dataset.mid)
  }

  get value() {
    return this._value
  }

  set value(val) {
    this._value = val
    this.tooltip(val)
  }

  get resolved() {
    return !!this._value
  }

  constructor(node: HTMLElement, options: any, mergeMap: MergeMap) {
    this.mergeMap = mergeMap

    makeObservable(this, {
      _value: observable,
      nodes: observable,
      resolved: computed,
      nodeIds: computed,
    })

    this.id = uniqueId()
    this.options = options
    this.nodes = [node]

    this.label = node.dataset.customText || ''
    node.dataset.mid = this.id
  }

  /**
   * Adds a node to this mapper
   * @param {*} node
   */
  addNode = (node: HTMLElement) => {
    node.dataset.mid = uniqueId()
    this.nodes.push(node)
    this.tooltip()
  }

  update = (value: any) => {}

  /**
   * Uses any specified mapping function for this placeHolder, to transform the val.
   * @param {*} id
   * @param {*} val
   * @returns
   */
  // don't use arrow function here
  mapValue({ placeHolders, value }: { placeHolders?: Placeholder[]; value: string }) {
    const { id } = this.options

    const placeHolder = placeHolders?.find((ph: { id: any }) => ph.id === id)
    if (placeHolder && typeof placeHolder.func === 'function') {
      return placeHolder.func(value)
    }

    return value
  }

  /**
   * Sets the tooltip on mapper nodes
   * @param {*} value
   */
  tooltip = (value = this.value) => {
    const emptyMessage = 'Value is empty'
    this.nodes.forEach((node) => {
      if (!(value instanceof HTMLElement)) {
        node.setAttribute('data-tooltip', value || emptyMessage)
      }
    })
  }

  match = (options: { id?: string; customText?: string }) => {}

  /**
   *
   */
  toString = () => {
    console.log(this)
  }
}

export default BaseMapper
