import { Form } from 'antd'
import ContactAutoComplete from 'components/base/AutoComplete/ContactAutoComplete/ContactAutoComplete'
import ContactAutocompletePM from 'components/base/AutoComplete/ContactAutoComplete/ContactAutocomplete.pm'
import { useRef } from 'react'

const contactAutocompletePM = new ContactAutocompletePM()

export const SelectContactPage = ({ onSelect }: { onSelect?: () => void }) => {
  const contactRef = useRef<HTMLSelectElement>()
  const [form] = Form.useForm<{ contact: string }>()

  const onSelectContact = async (clientId: string) => {
    onSelect?.()
    form.resetFields()

    global.router.goto(`/contacts/${clientId}`)
  }

  return (
    <Form form={form}>
      <Form.Item name="contact" style={{ margin: 0 }}>
        <ContactAutoComplete
          onSelect={onSelectContact}
          placeholder="Search contacts"
          autoFocus={false}
          style={{ width: '100%' }}
          // @ts-ignore
          ref={contactRef}
          pm={contactAutocompletePM}
        />
      </Form.Item>
    </Form>
  )
}
