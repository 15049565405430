import { isEmpty } from 'lodash'
import { Nullable } from 'types/helpers'

const TAG = 'span.p360-ph:not(.missing)'

export const DISPLAY_DATA_ATTRIBUTE = 'phdisplay'
export const DISPLAY = {
  INLINE: 'inline',
  PARAGRAPH: 'paragraph',
  OLLIST: 'ol-list',
  ULLIST: 'ul-list',
} as const
export const DISPLAY_OPTIONS = [
  { value: DISPLAY.INLINE, name: 'Inline ' },
  { value: DISPLAY.PARAGRAPH, name: 'Paragraph ' },
  { value: DISPLAY.OLLIST, name: 'Ordered List' },
  { value: DISPLAY.ULLIST, name: 'Unordered List' },
]

export function placeHolder(editor: any) {
  const $ = editor.$

  let currentPlaceHolder: Nullable<[HTMLElement]> = null
  let touchScroll = false

  /**
   * Shortcut to get the currently selected placeHodler
   */
  const get = function () {
    return currentPlaceHolder
  }

  /**
   * Initialize the popup
   */
  const initEditPopup = function () {
    const btns = editor.opts.placeHolderEditButtons
    const btnList = editor.button.buildList(btns)
    const buttons = `<div class="fr-buttons">${btnList}</div>`
    return editor.popups.create('placeHolder.edit', {
      buttons,
      /* custom_layer: configurePlaceholderTemplate, */
    })
  }

  /**
   * Called when clicking on a placeHolder
   */
  // @ts-ignore
  const editPlaceHolder = function (e) {
    // @ts-ignore
    const $placeHolder = $(this)
    if (touchScroll && e && e.type === 'touchend') {
      return true
    }

    if (editor.edit.isDisabled()) {
      if (e) {
        e.stopPropagation()
        e.preventDefault()
      }
      return false
    }
    editor.toolbar.disable()

    // Hide keyboard.
    if (editor.helpers.isMobile()) {
      editor.events.disableBlur()
      editor.$el.blur()
      editor.events.enableBlur()
    }

    if (currentPlaceHolder) {
      // @ts-ignore
      currentPlaceHolder.removeClass('fr-active')
    }
    currentPlaceHolder = $placeHolder
    $placeHolder.addClass('fr-active')

    if (editor.opts.iframe) {
      editor.size.syncIframe()
    }
    editor.placeHolder.showEditPopup($placeHolder)

    editor.selection.clear()
    const range = editor.doc.createRange()
    range.selectNode($placeHolder[0])
    editor.selection.get().addRange(range)

    editor.button.bulkRefresh()
    return false
  }

  /**
   * Called when placeHolder loses focus
   * @param {*} placeHolder
   */
  // @ts-ignore
  const stopEditing = function (placeHolder) {
    if (!placeHolder) {
      placeHolder = editor.$el.find('.p360-ph')
    }
    if (!placeHolder.length) {
      return
    }
    placeHolder.removeClass('fr-active')
    currentPlaceHolder = null
    editor.toolbar.enable()
  }

  /**
   * Show the edit placeHolder popup
   * @param {*} $placeHolder
   */
  // @ts-ignore
  const showEditPopup = function ($placeHolder) {
    const popup = 'placeHolder.edit'

    if (!editor.popups.get(popup)) {
      initEditPopup()
    }
    editor.popups.setContainer(popup, editor.$wp)
    editor.popups.refresh(popup)

    const leftOffset = $placeHolder.offset().left
    const left = leftOffset + $placeHolder.outerWidth() / 2
    const top = $placeHolder.offset().top + $placeHolder.outerHeight()

    editor.popups.show(popup, left - 51, top, $placeHolder.outerHeight())
  }

  /**
   * Adds a tag as a child of the placeHolder
   * @param {*} val
   */
  // @ts-ignore
  const insertTag = function (val) {
    const placeHolder = editor.placeHolder.get()
    if (!placeHolder) {
      return false
    }

    const dataTags = placeHolder.data('tags')
    let tags = isEmpty(dataTags) ? [] : dataTags.split('|')
    // @ts-ignore
    const tag = global.tags.get(val)
    let text = placeHolder.text()

    if (tags.includes(val)) {
      // @ts-ignore
      tags = tags.filter((t) => t !== val)
      placeHolder.data('tags', tags.join('|'))

      const regex = new RegExp(`::${tag.name}`, 'g')
      text = text.replace(regex, '')
      placeHolder.text(text)
    } else {
      placeHolder.data('tags', tags.concat(val).join('|'))
      placeHolder.text(`${text}::${tag.name}`)
    }

    editor.selection.clear()
    editor.popups.hideAll()
  }

  const addTopicHeaders = function () {
    const placeHolder = editor.placeHolder.get()
    if (!placeHolder) {
      return false
    }

    const currentVal = placeHolder.data('header')
    if (currentVal === 'add') {
      return placeHolder.data('header', '')
    }

    placeHolder.data('header', 'add')
  }

  const removeTopicHeaders = function () {
    const placeHolder = editor.placeHolder.get()
    if (!placeHolder) {
      return false
    }

    const currentVal = placeHolder.data('header')
    if (currentVal === 'remove') {
      return placeHolder.data('header', '')
    }

    placeHolder.data('header', 'remove')
  }

  const selectDisplay = function (value: string) {
    const placeHolder = editor.placeHolder.get()
    if (!placeHolder) {
      return false
    }

    placeHolder.data(DISPLAY_DATA_ATTRIBUTE, value)
    editor.selection.clear()
    editor.popups.hideAll()
  }

  /**
   * Removes a placeHolder
   */
  const remove = function () {
    const placeHolder = editor.placeHolder.get()
    if (placeHolder) {
      placeHolder.remove()
    }
    editor.popups.hideAll()
  }

  /**
   * Main initialization method
   */
  const _init = function () {
    if (editor.helpers.isMobile()) {
      editor.events.$on(editor.$el, 'touchstart', TAG, function () {
        touchScroll = false
      })

      editor.events.$on(editor.$el, 'touchmove', function () {
        touchScroll = true
      })
    }
    // @ts-ignore
    editor.events.$on(editor.$el, 'mousedown', TAG, function (e) {
      e.stopPropagation()
    })
    editor.events.$on(editor.$el, 'click touchend', TAG, editPlaceHolder)
    // @ts-ignore
    editor.events.on('mouseup window.mouseup', () => stopEditing())
    // @ts-ignore
    editor.events.on('commands.mousedown', function ($btn) {
      if ($btn.parents('.fr-toolbar').length) {
        // @ts-ignore
        stopEditing()
      }
    })
  }

  return {
    _init: _init,
    showEditPopup: showEditPopup,
    removeTopicHeaders: removeTopicHeaders,
    addTopicHeaders: addTopicHeaders,
    insertTag: insertTag,
    remove: remove,
    get: get,
    selectDisplay,
  }
}

// @ts-ignore
export default function configurePlaceholdersPlugin(FroalaEditor) {
  FroalaEditor.POPUP_TEMPLATES = {
    ...FroalaEditor.POPUP_TEMPLATES,
    'placeHolder.edit': '[_BUTTONS_]',
    /* 'placeHolder.edit': '[_BUTTONS_][_CUSTOM_LAYER_]', */
  }

  FroalaEditor.DEFAULTS = Object.assign(FroalaEditor.DEFAULTS, {
    placeHolderEditButtons: [
      'placeHolderAddTopicHeaders',
      'placeHolderRemoveTopicHeaders',
      'placeHolderInsertTag',
      'placeHolderSelectDisplay',
      'placeHolderRemoveTag',
    ],
  })

  FroalaEditor.PLUGINS.placeHolder = placeHolder

  FroalaEditor.DefineIcon('placeHolderAddTopicHeaders', {
    NAME: 'plus-square',
    template: 'fa',
  })
  FroalaEditor.RegisterCommand('placeHolderAddTopicHeaders', {
    title: 'Always show header for this topic',
    refreshAfterCallback: true,
    plugin: 'placeHolder',
    callback: function callback() {
      this.placeHolder.addTopicHeaders()
    },
    // @ts-ignore
    refresh: function refresh($btn) {
      const placeHolder = this.placeHolder.get()
      const headerVal = placeHolder.data('header')
      const active = headerVal === 'add'
      $btn[active ? 'addClass' : 'removeClass']('fr-active')
      $btn.addClass('placeHolderAddTopicHeaders')
    },
  })

  FroalaEditor.DefineIcon('placeHolderRemoveTopicHeaders', {
    NAME: 'minus-square',
    template: 'fa',
  })
  FroalaEditor.RegisterCommand('placeHolderRemoveTopicHeaders', {
    title: 'Never show header for this topic',
    plugin: 'placeHolder',
    callback: function callback() {
      this.placeHolder.removeTopicHeaders()
    },
    // @ts-ignore
    refresh: function refresh($btn) {
      const placeHolder = this.placeHolder.get()
      const headerVal = placeHolder.data('header')
      const active = headerVal === 'remove'
      $btn[active ? 'addClass' : 'removeClass']('fr-active')
      $btn.addClass('placeHolderRemoveTopicHeaders')
    },
  })

  FroalaEditor.RegisterCommand('placeHolderTopicHeaders', {
    title: 'Topic Headers',
    type: 'dropdown',
    html: function html() {
      let c = '<ul class="fr-dropdown-list" role="presentation">'

      const options = [
        { value: 'show', name: 'Show Topic Header' },
        { value: 'hide', name: 'Hide Topic Header' },
      ]

      options.forEach((option) => {
        c +=
          '<li role="presentation"><a class="fr-command" tabIndex="-1" role="option" data-cmd="placeHolderTopicHeaders" data-param1="'
            .concat(option.value, '">')
            .concat(this.language.translate(option.name), '</a></li>')
      })

      c += '</ul>'
      return c
    },
    // @ts-ignore
    callback: function callback(cmd, val) {
      this.placeHolder.topicHeader(val)
    },
    // @ts-ignore
    refreshOnShow: function refreshOnShow($btn, $dropdown) {
      const $ = this.$
      const placeHolder = this.placeHolder.get()

      if (placeHolder) {
        $dropdown.find('.fr-command').each(function () {
          // @ts-ignore
          const header = $(this).data('param1')
          const headerVal = placeHolder.data('header')
          const active = headerVal === header
          // @ts-ignore
          $(this).toggleClass('fr-active', active).attr('aria-selected', active)
        })
      }
    },
    // @ts-ignore
    refresh: function refresh($btn) {
      const placeHolder = this.placeHolder.get()
      const isTopic = placeHolder[0] && placeHolder[0].dataset.id.includes('topic::')
      $btn[isTopic ? 'removeClass' : 'addClass']('fr-hidden')
    },
    plugin: 'placeHolder',
  })

  FroalaEditor.DefineIcon('placeHolderInsertTag', {
    NAME: 'tags',
    SVG_KEY: 'tags',
  })
  FroalaEditor.RegisterCommand('placeHolderInsertTag', {
    title: 'Tags',
    type: 'dropdown',
    html: function html() {
      let c = '<ul class="fr-dropdown-list" role="presentation">'
      // var { tags } = global.tags

      // build dropdown list of available tags
      // tags.forEach(tag => {
      //   c += '<li role="presentation"><a class="fr-command" tabIndex="-1" role="option" data-cmd="placeHolderInsertTag" data-param1="'.concat(tag.value, '">').concat(this.language.translate(tag.name), '</a></li>')
      // })

      const tag = { value: 'client', name: 'Client ' }
      c +=
        '<li role="presentation" id="tag_id-' +
        tag.value +
        '"><a class="fr-command" tabIndex="-1" role="option" data-cmd="placeHolderInsertTag" data-param1="'
          .concat(tag.value, '">')
          .concat(this.language.translate(tag.name), '</a></li>')
      c += '</ul>'
      return c
    },
    // @ts-ignore
    callback: function callback(cmd, val) {
      this.placeHolder.insertTag(val)
    },
    // @ts-ignore
    refreshOnShow: function refreshOnShow($btn, $dropdown) {
      const $ = this.$
      const placeHolder = this.placeHolder.get()

      if (placeHolder) {
        $dropdown.find('.fr-command').each(function () {
          // @ts-ignore
          const tag = $(this).data('param1')

          const dTags = placeHolder.data('tags')
          const tags = isEmpty(dTags) ? [] : dTags.split('|')
          const active = tags.includes(tag)
          // @ts-ignore
          $(this).toggleClass('fr-active', active).attr('aria-selected', active)
        })
      }
    }, // @ts-ignore
    refresh: function refresh($btn) {
      const placeHolder = this.placeHolder.get()
      const isTopic = placeHolder[0] && placeHolder[0].dataset.id.includes('topic::')
      $btn[isTopic ? 'removeClass' : 'addClass']('fr-hidden')
    },
    plugin: 'placeHolder',
  })

  FroalaEditor.DefineIcon('placeHolderRemoveTag', {
    NAME: 'trash',
    SVG_KEY: 'remove',
  })
  FroalaEditor.RegisterCommand('placeHolderRemoveTag', {
    title: 'Remove Merge Field',
    callback: function callback() {
      this.placeHolder.remove()
    },
    plugin: 'placeHolder',
  })

  FroalaEditor.DefineIcon('placeHolderSelectDisplay', {
    NAME: 'placeholderDisplay',
    SVG_KEY: 'imageDisplay',
  })
  FroalaEditor.RegisterCommand('placeHolderSelectDisplay', {
    title: 'Display',
    type: 'dropdown',
    html: function html() {
      let c = '<ul class="fr-dropdown-list" role="presentation">'

      DISPLAY_OPTIONS.forEach((option) => {
        c +=
          '<li role="presentation" id="display_id-' +
          option.value +
          '"><a class="fr-command" tabIndex="-1" role="option" data-cmd="placeHolderSelectDisplay" data-param1="'
            .concat(option.value, '">')
            .concat(this.language.translate(option.name), '</a></li>')
      })

      c += '</ul>'
      return c
    },
    // @ts-ignore
    callback: function callback(cmd, val) {
      this.placeHolder.selectDisplay(val)
    },
    // @ts-ignore
    refreshOnShow: function refreshOnShow($btn, $dropdown) {
      const $ = this.$
      const placeHolder = this.placeHolder.get()

      if (placeHolder) {
        $dropdown.find('.fr-command').each(function () {
          // @ts-ignore
          const selected = $(this).data('param1')

          const display = placeHolder.data(DISPLAY_DATA_ATTRIBUTE)
          const active = display === selected
          // @ts-ignore
          $(this).toggleClass('fr-active', active).attr('aria-selected', active)
        })
      }
    }, // @ts-ignore
    refresh: function refresh($btn) {
      const placeHolder = this.placeHolder.get()
      const isTopic = placeHolder[0] && placeHolder[0].dataset.id.includes('topic::')
      $btn[isTopic ? 'removeClass' : 'addClass']('fr-hidden')
    },
    plugin: 'placeHolder',
  })
}

const configurePlaceholderTemplate = (actions: any) => {
  return `<div class="custom-layer">
  <label class="custom-layer-label">Display</label>
  <input type="text" class="custom-layer-input" placeholder="Enter merge field name" />


  </div>`
}
