/* eslint-disable @typescript-eslint/ban-ts-comment */
import { LogoutOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Button, Dropdown } from 'antd'
import { observer } from 'mobx-react'

const UserMenu = () => {
  const items: MenuProps['items'] = [
    {
      type: 'group',
      // @ts-ignore
      label: `${global.data.advisors.getFullName()}`,
      children: [
        {
          key: 'logout',
          icon: <LogoutOutlined />,
          label: 'Sign-out',
        },
      ],
    },
  ]

  const menuClick = ({ key }: { key: string }) => {
    switch (true) {
      case key === 'logout':
        global.stores.auth.signOut()
        break

      default:
        break
    }
  }

  return (
    <Dropdown menu={{ items, onClick: menuClick }} placement="bottomRight" trigger={['click']}>
      <Button shape="circle">{global.data.advisors.initials}</Button>
    </Dropdown>
  )
}

export default observer(UserMenu)
