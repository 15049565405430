import MainLayout from 'components/layout/MainLayout.component'
import { useMemo } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import MainTemplate from '../layout/templates/Main.template'
import { Lazy, Loading } from './async'

const AppRoutes = () => {
  const Tasks = useMemo(() => Lazy(() => import('./tasks')), [])
  const Help = useMemo(() => Lazy(() => import('./help')), [])
  const Admin = useMemo(() => Lazy(() => import('./admin')), [])
  const Settings = useMemo(() => Lazy(() => import('./settings')), [])
  const Reports = useMemo(() => Lazy(() => import('./reports')), [])
  const Templates = useMemo(() => Lazy(() => import('./templates')), [])
  const Onboard = useMemo(() => Lazy(() => import('./onboard')), [])
  const OAuth = useMemo(() => Lazy(() => import('./oauth2')), [])
  const Vault = useMemo(() => Lazy(() => import('./vault')), [])
  const QuickNote = useMemo(() => Lazy(() => import('./quick/note')), [])
  const Contacts = useMemo(() => Lazy(() => import('./contacts')), [])
  const Documents = useMemo(() => Lazy(() => import('./documents')), [])

  if (!global.data.advisors.me) {
    return <Loading />
  }

  if (!global.data.advisors.me) {
    return <Loading />
  }

  return (
    <MainLayout>
      <MainTemplate>
        <Routes>
          <Route path="/notes/*" element={<Tasks />} />
          <Route path="/documents/*" element={<Documents />} />
          <Route path="/contacts/*" element={<Contacts />} index />
          <Route path="/help/*" element={<Help />} />
          <Route path="/quick/note" element={<QuickNote />} />
          {global.perms.get('ui.route.360view') && <Route path="/reports" element={<Reports />} />}
          <Route path="/settings/*" element={<Settings />} />
          <Route path="/onboard/*" element={<Onboard />} />

          {global.data.advisors.isAdmin && <Route path="/admin/*" element={<Admin />} />}
          <Route path="/templates/*" element={<Templates />} />
          <Route path="/vault" element={<Vault />} />
          <Route path="/oauth2/callback" element={<OAuth />} />
          <Route element={<Navigate to={'/contacts'} replace />} path="/" />
        </Routes>
      </MainTemplate>
    </MainLayout>
  )
}

export default AppRoutes
