/* eslint-disable no-unused-vars */
/* eslint-disable prefer-regex-literals */
import Client from 'components/froala/merge/types/client'
import { encodeImagesURLOnBody } from 'helpers/file'
import { get, isEmpty, isNil } from 'lodash'
import Str from 'stores/util/str.util'
import { IsElement, IsHTMLElement, Nullable } from 'types/helpers'
import { captureExceptionSilently } from '../../../helpers/sentry'
import { DISPLAY } from '../plugins/place_holder'

const DOLLAR_SYMBOL = /[$]/gm
const BLACKLIST = ['UL', 'LI', 'BODY', 'TD']
const STYLE_ELEMENTS = ['STRONG', 'EM']
const STYLES_WHITELIST = [
  'color',
  'background-color',
  'font-family',
  'font-size',
  'font-style',
  'font-weight',
  'line-height',
  'text-align',
  'text-decoration',
  'vertical-align',
]
const STYLE_TAGS = [
  'P',
  'DIV',
  'SPAN',
  'LI',
  'UL',
  'OL',
  'STRONG',
  'B',
  'EM',
  'H1',
  'H2',
  'H3',
  'H4',
  'H5',
  'H6',
  'I',
  'Q',
  'SUB',
  'SUP',
  'U',
]

/* ---------- Element creation ---------- */

export const Placeholder = (
  val: string,
  text: string,
  config?: { tags?: string; header?: string; display?: string },
) => {
  const defaultData = {
    tags: '',
    header: '',
    display: DISPLAY.INLINE,
  }

  const { tags, header, display } = { ...defaultData, ...config }
  // TODO: move data attributes to constants
  // the empty space at the end of the line fixes a froala error. Froala without this space turns the topic into a block element, occupying the entire line.
  return `<span class="p360-ph fr-deletable" contenteditable="false" spellcheck="false" data-type="p360-ph" data-id="${val}" data-tags="${tags}" data-header="${header}" data-phdisplay="${display}">${text}</span>&nbsp;`
}

export const CustomPlaceholder = (id: string, type: string | null, text = 'Placeholder Text', added = false) => {
  return `<span class="p360-custom-ph fr-deletable ${id}" contenteditable="false" spellcheck="false" data-type="p360-custom-ph" data-custom="${type}" data-id="${id}" data-custom-text="${text}" data-added="${added}">${text}</span>`
}

/**
 * Create a Document model shortcut
 * @param {*} html
 */
export const DOM = (html = '', cleanDOM = true): Document => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  return cleanDOM ? (clean(doc) as Document) : doc
}

export const BODY = (html = '', cleanDOM = true): HTMLElement => {
  const doc = DOM(html, cleanDOM)

  return doc.body
}

/**
 * Shortcut to create a Header tag
 *
 */
export const Element = <T extends keyof HTMLElementTagNameMap>(
  tag: T,
  content?: Nullable<string>,
): HTMLElementTagNameMap[T] => {
  const element = document.createElement(tag)
  if (!isNil(content)) {
    element.innerHTML = content
  }
  return element
}

/**
 * Shortcut to create a Paragraph tag
 *
 */
export const P = (content?: Nullable<string>) => {
  return Element('p', content)
}

/**
 * Shortcut to create a Span tag
 *
 */
export const Span = (content: string) => {
  return Element('span', content)
}

/**
 * Shortcut to create a Header tag
 *
 */
export const Header = (level: 1 | 2 | 3 | 4 | 5 | 6, content: string) => {
  return Element(`h${level}`, content)
}

/**
 * Shortcut to create a BR tag
 *
 */
export const BR = () => {
  return Element('br')
}

/* ---------- Html santizers ---------- */

/**
 * Replace placeholder nodes with their correct replacements.
 *
 */
export const replace = (element: HTMLElement, placeholder: string, value: string | HTMLElement | null) => {
  const nodes = element.querySelectorAll(`[data-id="${placeholder}"][data-type="p360-ph"]`)
  // iterate over all instances of the specified placeholder
  nodes.forEach((nn: any) => {
    const root = singleAncestor(nn)
    if (value) {
      const useBlock = isBlock(root) || (IsHTMLElement(root) && isAlignDiv(root))
      let el

      if (!(value instanceof HTMLElement)) {
        el = Element(useBlock ? 'p' : 'span', value)
      } else {
        el = value
      }

      root.parentNode?.insertBefore(el, root)
      applyStyle(el, collectStyles(nn))
    }
    root.parentNode?.removeChild(root)
  })
}

/**
 * Applies placeholder styles to the inserted node
 * @param {*} doc
 * @param {*} node
 * @param {*} styles
 */
export const applyStyle = (node: Node, styles: string) => {
  if (node.nodeType === Node.ELEMENT_NODE && IsHTMLElement(node)) {
    node.style.cssText = styles
  } else if (node.nodeType === Node.TEXT_NODE && !isEmpty(styles)) {
    const span = Element('span', node.textContent)
    span.style.cssText = styles
    node?.parentNode?.replaceChild(span, node)
  }
}

/**
 * Appends styles to the current node styles
 */
export const appendStyle = (node: Node, styles: string) => {
  if (node.nodeType === Node.ELEMENT_NODE && IsHTMLElement(node)) {
    node.style.cssText += styles
  } else if (node.nodeType === Node.TEXT_NODE && !isEmpty(styles)) {
    const span = Element('span', node.textContent)
    span.style.cssText += styles
    node?.parentNode?.replaceChild(span, node)
  }
}

const wrap = (node: Node, tag: keyof HTMLElementTagNameMap = 'p') => {
  const wrapper = Element(tag)
  node?.parentNode?.insertBefore(wrapper, node)
  wrapper.appendChild(node)
  return wrapper
}

/**
 * Wrap child nodes in a paragraph tag, if not already
 * @param {*} node
 */
export const wrapInPara = (body: HTMLElement, parent?: undefined) => {
  Array.from(body.childNodes).forEach((node, i, a) => {
    // block level img tags should be wrapped
    if (IsHTMLElement(node) && node.tagName === 'IMG' && node.classList.contains('fr-dib')) {
      return wrap(node)
    }
  })

  return body
}

const onlyInlineChildren = (node: HTMLElement) => {
  return Array.from(node.childNodes).every((n) => !isBlock(n))
}

/**
 * Convert a templates block's markup to froala html
 * @param {*} block
 */
export const blockToHtml = (markup: string) => {
  const dom = DOM(markup)
  const node = transformBlock(dom.body, true)
  return node.innerHTML
}

/**
 * Utility to santize templates blocks' markup
 * @param {*} blockNode
 */
export const transformBlock = (blockNode: HTMLElement, raw = false) => {
  let node = clean(blockNode) as HTMLElement
  node = transformOldClientBlots(node, raw)
  node = transformOrphanedBlots(node)
  node = transformQuillBlots(node, raw)
  node = transformFroalaBlots(node, raw)
  // node = santizeHTML(node)
  return node
}

/**
 * Utility to santize notes' markup
 */
export const transformNote = (body: HTMLElement, parent?: HTMLElement) => {
  const node = clean(body) as HTMLElement
  // node = wrapInPara(body, parent)
  // node = reparentParaText(node)
  // if (node.nodeType === Node.ELEMENT_NODE) {
  //   node.style.display = 'inline-block'
  // }
  // node = santizeHTML(node)
  return node
}

/**
 * Utility to santize incoming html markup
 * @param {*} blockNode
 */
export const transformHTML = (html = '') => {
  if (isNil(html)) {
    return html
  }
  html = html.replace(DOLLAR_SYMBOL, '&#36;').trim()
  html = html.replace(new RegExp('font-family: Garamond;', 'g'), 'font-family: EB Garamond;')
  html = html.replace(new RegExp('font-family: OpenSans;', 'g'), 'font-family: Open Sans;')
  // eslint-disable-next-line
  html = html.replace(/<p>\s*<br[\/]?>\s*<[\/]?p>/g, '<br/>')

  // html = dehoistStyles(html)
  html = addListParagraph(html)
  html = removeParagraphSpacing(html)
  html = encodeImagesURLOnBody(html)
  return html
}

const addListParagraph = (html: string) => {
  const doc = DOM(html)
  const lists = doc.querySelectorAll<HTMLOListElement | HTMLUListElement>('ul, ol')

  lists.forEach((list) => {
    list.childNodes.forEach((node) => {
      if (!IsHTMLElement(node) || node.tagName !== 'LI') {
        return
      }

      const li = node
      const alreadyWrapped =
        li.childNodes.length === 1 && li.firstChild && IsHTMLElement(li.firstChild) && li.firstChild.tagName === 'P'
      const inlineChildren = li.childNodes.length > 1 && onlyInlineChildren(li)

      if (!alreadyWrapped || inlineChildren) {
        if (li.childNodes.length === 1 && isPhrasingContent(li.childNodes[0])) {
          const p = P()
          p.appendChild(li.childNodes[0])
          li.appendChild(p)
        }
      }
    })
  })

  return doc.body.innerHTML
}

/**
 * Reparents nodes that have previously been wrapped with <p class="no-para-spacing">
 * @param {*} html
 * @returns
 */
const removeParagraphSpacing = (html: string) => {
  const doc = DOM(html)
  const paras = doc.querySelectorAll('p.no-para-spacing')
  const wrapElement = doc.createElement('span')
  wrapElement.classList.add('simulate-br')

  paras.forEach((para) => {
    para.childNodes.forEach((node) => {
      const newNode = wrapElement.cloneNode()
      newNode.appendChild(node)

      para.parentNode?.insertBefore(newNode, para)
    })

    para.parentNode?.removeChild(para)
  })

  return doc.body.innerHTML
}

/**
 * Replaces the root node with the last descendant to have no siblings.
 * Effectively remove the wrapping 'div' applied in templateBlocks:tokenizeBlock,
 * if the block contents only contain a single node
 * @param {*} node
 */
// export const reparentRoot = (node) => {
//   const descendant = singleDescendant(node)
//   node.parentNode.replaceChild(descendant, node)
//   return descendant
// }

/**
 * For notes that only contain text, wrapped in a paragraph, remove the paragraph
 * @param {*} node
 * @returns
 */
// export const reparentParaText = (node) => {
//   if (node.tagName === 'BODY') {
//     const children = node.childNodes
//     const first = node.firstChild
//     if (children.length === 1 && first.tagName === 'P') {
//       if (onlyInlineChildren(first)) {
//         const content = first.innerHTML
//         first.parentNode.removeChild(first)
//         node.innerHTML = content
//       }
//     }
//   }

//   return node
// }

/**
 * UNUSED ATM
 * Cleans 'invisible' whitespace text nodes from the dom structure
 * https://www.sitepoint.com/removing-useless-nodes-from-the-dom/
 * @see util.test.js for exploration of this
 * @param {*} node
 */
// export const walkDown = (node, callback) => {
//   for (let n = 0; n < node.childNodes.length; n++) {
//     const child = node.childNodes[n]

//     if (child.nodeType === 1) {
//       walkDown(child, callback)
//     }
//   }

//   return node
// }

/**
 * Santizes html nodes, removing erroneous whitespace (text) nodes
 * @param {*} node
 */
// export const santizeHTML = (node) => {
//   for (let n = 0; n < node.childNodes.length; n++) {
//     const child = node.childNodes[n]
//     if (child.nodeType === 1) {
//       const empty = isEmptyElement(child)
//       if (empty) {
//         node.removeChild(child)
//         n--
//       }
//     }
//     santizeHTML(child)
//   }

//   return node
// }

/**
 * Cleans 'invisible' whitespace text nodes from the dom structure
 * https://www.sitepoint.com/removing-useless-nodes-from-the-dom/
 * @see util.test.js for exploration of this
 * @param {*} node
 */
export const clean = (node: Document | HTMLElement) => {
  for (let n = 0; n < node.childNodes.length; n++) {
    const child = node.childNodes[n]
    if (isComment(child) || isEmptyText(child)) {
      node.removeChild(child)
      n--
    } else if (child.nodeType === Node.ELEMENT_NODE && IsHTMLElement(child)) {
      clean(child)
    }
  }

  return node
}

/**
 * Transforms Blots that aren't correctly wrapped to new style
 */
export const transformOrphanedBlots = (node: HTMLElement) => {
  const elems = node.querySelectorAll('*:not([data-type="p360-ph"]')
  const placeholders = Array.from(elems).filter((e) => {
    return (
      // ensure node doesn't have a p360-ph below
      /^((?!p360-ph).)*$/.test(e.innerHTML) &&
      // find all 'old style' blots starting with summary or agenda
      e.textContent &&
      (/{{(summary|agenda)::/.test(e.textContent) ||
        // find all non-topic placeholders that don't have :: in the middle
        /{{(?!.+(::)).+}}/.test(e.textContent))
    )
  })

  Array.from(placeholders).forEach((ph) => {
    if (!isQuillBlot(ph) && !isBlot(ph)) {
      // find the placeholder from the ph contents.  We need to do this,
      // as the placeholder might part of a sentance, which we need to maintain
      try {
        let contents = ph.outerHTML
        const tokens = contents.match(/{{[^}}]+}}/gm) || []

        tokens.forEach((token: any) => {
          const val = transformBlot(token)
          const text = getBlotDisplayText(val)
          const placeholder = Placeholder(val, text)
          contents = contents.replace(token, placeholder)
        })
        const children = DOM(contents).body.childNodes
        Array.from(children).forEach((c) => {
          if (ph?.tagName !== 'HTML') {
            // 👆🏻 Avoid trying to attach an element into the template root element (which would fail)
            ph.parentNode?.insertBefore(c, ph)
          }
        })

        ph.parentNode?.removeChild(ph)
      } catch (error) {
        console.error('Froala -> transformOrphanedBlots', error)
        captureExceptionSilently(error, { message: 'transformOrphanedBlots', data: { ph } })
      }
    }
  })
  return node
}

/**
 * Transforms Quill Placeholder blot, into Froala placeholder
 * <span class="generic-class" data-id="email-sign" spellcheck="false"><span contenteditable="false">{{email-sign}}</span></span>
 */
export const transformQuillBlots = (node: HTMLElement, raw = false) => {
  const blots = node.querySelectorAll("[class~='generic-class']")
  return transformBlots(blots, node, raw)
}

/**
 * Transforms Froala 'Old style' placeholders to new style
 */
export const transformFroalaBlots = (node: HTMLElement, raw = false) => {
  const blots = node.querySelectorAll("[data-type='p360-ph']")
  return transformBlots(blots, node, raw)
}

/**
 * Transforms old client placeholders to the new custom placeholder type
 */
export const transformOldClientBlots = (node: HTMLElement, raw = false) => {
  const oldToNew = Client.placeHolders.reduce<{ [key: string]: string }>((acc, c) => {
    const key = c.old || c.id
    acc[key] = c.id
    return acc
  }, {})

  const ids = Client.placeHolders.map((k) => `[data-id="client-${k.old || k.id}"]`)
  const blots = node.querySelectorAll(ids.join(','))

  blots.forEach((blot) => {
    const id = blot.getAttribute('data-id')?.replace('client-', '')

    if (id) {
      blot.setAttribute('data-id', oldToNew[id])
    }

    blot.setAttribute('data-custom', 'CLIENT')
    if (blot.textContent) {
      blot.setAttribute('data-custom-text', blot.textContent)
    }
    blot.setAttribute('data-type', 'p360-custom-ph')
    blot.classList.add('p360-custom-ph')
    blot.classList.remove('p360-ph')
  })

  return transformBlots(blots, node, raw, true)
}

/**
 * Transforms blots to new style
 */
export const transformBlots = (blots: NodeListOf<Element>, node: HTMLElement, raw = false, custom = false) => {
  blots.forEach((blot) => {
    const dataId = blot.getAttribute('data-id')
    if (!dataId) {
      return
    }
    const val = transformBlot(dataId)
    let ph
    let text = ''

    if (custom) {
      const customType = blot.getAttribute('data-custom')
      const customText = blot.getAttribute('data-custom-text') || undefined
      ph = CustomPlaceholder(val, customType, customText)
      // REVIEW: Its imposible to assign a custom type to a custom blot
      // @ts-ignore
      ph.classList?.add(...blot?.classList)
    } else {
      text = getBlotDisplayText(val)

      const dataTag = blot.getAttribute('data-tags')
      const tags = isNil(dataTag) || isEmpty(dataTag) ? [] : dataTag.split('|')

      tags.sort().forEach((tag: string | undefined) => {
        text += `::${Str.startCase(tag)}`
      })

      const htmlText = styleElements(blot)(text)
      const header = blot.getAttribute('data-header')
      const display = blot.getAttribute('data-phdisplay')
      ph = Placeholder(val, htmlText, {
        tags: tags.join('|'),
        ...(header ? { header } : null),
        ...(display ? { display } : null),
      })
    }

    let phNode = DOM(ph).body.firstChild
    let root = blot

    // topic blots should all be block level
    if (/^topic::/.test(text)) {
      root = singleAncestor(blot)
      if (!raw && phNode) {
        phNode = wrap(phNode)
      }
    }
    if (phNode) {
      root.parentNode?.replaceChild(phNode, root)
    }
  })
  return node
}

/**
 * @param {*} txt
 */
const transformBlot = (txt: string) => {
  let text = txt.replace(/^(summary|agenda)::/, 'topic::')
  text = text.replace(/::no-bullet/, '')
  text = text.replace(/::no-title/, '')
  text = text.replace('{{', '')
  return text.replace('}}', '')
}

/**
 * Traverses down the node tree for nodes that only have a single child
 * @param {*} element
 */
export const singleDescendant = (element: Element | ChildNode): Element | ChildNode => {
  if (
    element.firstChild &&
    hasOneChild(element) &&
    element.firstChild.nodeType === Node.ELEMENT_NODE &&
    !isBlot(element) &&
    IsElement(element.firstChild)
  ) {
    return singleDescendant(element.firstChild)
  }
  return element
}

/**
 * Traverses up the node tree for parent nodes that only have a single child, to find the root ancester
 */
export const singleAncestor = (element: Element, blacklist = BLACKLIST): Element => {
  if (
    element.parentNode &&
    IsElement(element.parentNode) &&
    hasOneChild(element.parentNode) &&
    blacklist.every((b) => element.parentNode && IsHTMLElement(element.parentNode) && element.parentNode.tagName !== b)
  ) {
    return singleAncestor(element.parentNode)
  }

  return element
}

/**
 * @param {*} node
 */
export const deepest = (node: Node): Node => {
  if (hasOneChild(node) && node.firstChild?.nodeType === Node.ELEMENT_NODE) {
    return deepest(node.firstChild)
  }

  return node
}

/**
 * Collects style element nodes - <strong>, <em> etc, are returns the provided text wrapped in those nodes
 * @param {*} element
 */
export const styleElements = (
  element: Element,
  styleTags: (keyof HTMLElementTagNameMap)[] = [],
): ((text: string) => string) => {
  if (
    element.firstChild &&
    hasOneChild(element) &&
    element.firstChild.nodeType === Node.ELEMENT_NODE &&
    IsHTMLElement(element.firstChild) &&
    // Improve this code
    STYLE_ELEMENTS.some(
      (t) => element.firstChild && IsHTMLElement(element.firstChild) && element.firstChild.tagName === t,
    )
  ) {
    styleTags = styleTags.concat(element.firstChild.tagName as keyof HTMLElementTagNameMap)
    return styleElements(element.firstChild, styleTags)
  }

  return (text: string) => {
    if (!styleTags.length) {
      return text
    }
    const body = DOM().body

    const ph = styleTags.reduce((node, tag) => {
      return node.appendChild(Element(tag))
    }, body)

    const textNode = document.createTextNode(text)
    ph.appendChild(textNode)

    return body.innerHTML
  }
}

/**
 * Collects style element nodes - <strong>, <em> etc, are returns the provided text wrapped in those nodes
 */
export const styleElementsInline = (
  element: Element,
  styleTags: Uppercase<keyof HTMLElementTagNameMap>[] = [],
): string => {
  if (
    element.firstChild &&
    hasOneChild(element) &&
    element.firstChild.nodeType === Node.ELEMENT_NODE &&
    IsHTMLElement(element.firstChild) &&
    STYLE_ELEMENTS.some(
      (t) => element.firstChild && IsHTMLElement(element.firstChild) && element.firstChild.tagName === t,
    )
  ) {
    styleTags = styleTags.concat(element.firstChild.tagName as Uppercase<keyof HTMLElementTagNameMap>)
    return styleElementsInline(element.firstChild, styleTags)
  }

  if (!styleTags.length) {
    return ''
  }
  return styleTags.reduce((styles, tag) => {
    if (tag === 'STRONG') {
      styles += 'font-weight:bold;'
    }
    if (tag === 'EM') {
      styles += 'font-style:italic;'
    }
    return styles
  }, '')
}

/**
 * Walks up the node tree collecting styles from wrapping elements
 */
export const collectStyles = (node: Node, includeTags = false, styles = ''): string => {
  if (IsHTMLElement(node) && STYLE_TAGS.some((tag) => tag === node.tagName)) {
    styles = `${getElementStyle(node)} ${styles}`
    if (includeTags) {
      styles = `${styleElementsInline(node)} ${styles}`
    }
    if (node.parentNode) {
      return collectStyles(node.parentNode, includeTags, styles)
    }
  }

  return pickWhitelist(styles.trim())
}

/**
 * Retrieves a nodes style either from cssText or derived from the element tag, ie <strong>
 * @param {*} element
 */
const getElementStyle = (element: Element) => {
  if (element.tagName === 'STRONG' || element.tagName === 'B') {
    return 'font-weight:bold;'
  }
  if (element.tagName === 'EM' || element.tagName === 'I') {
    return 'font-style:italic;'
  }
  if (element.tagName === 'U') {
    return 'text-decoration: underline;'
  }
  return get(element, 'style.cssText', '')
}

/**
 * @param {*} styles
 */
const pickWhitelist = (styles: string) => {
  return STYLES_WHITELIST.reduce((s, style) => {
    const reg = new RegExp(`${style}:[^;]+;?`, 'g')
    const match = styles.match(reg)
    if (match && match.length) {
      s = `${s} ${match[0]}`
    }
    return s
  }, '')
}

/* ---------- Utils --------- */

/**
 * Ensure that we have a valid topic identifier.
 */
export const validateTopic = (topic: number | string): string | null => {
  const isId = !isNaN(+topic)

  if (isId) {
    // @ts-ignore
    const { getTopic } = global.data.topics
    const { name } = getTopic(topic)

    return name
  }

  // @ts-ignore
  const topicData = global.data.topics.getTopicByKebabCasedName(topic)
  if (topicData && topicData.name) {
    return topicData.name
  }

  return null
}

/**
 * Is an old style Quill Blot node
 * @param {*} node
 */
export const isQuillBlot = (node: Node) => {
  const correctSpan =
    IsHTMLElement(node) && node.tagName === 'SPAN' && Str.toBool(node.getAttribute('contenteditable')!) === false

  const correctParent =
    node.parentNode &&
    IsHTMLElement(node.parentNode) &&
    node.parentNode.tagName === 'SPAN' &&
    node.parentNode.className === 'generic-class'

  return correctSpan && correctParent
}

/**
 * These blots are basic placeholder blots - ie. Time, Date, Client Name and don't have a data-id beginning with 'topic::'
 * @param {*} node
 * @param {*} topicOnly Only query whether it is a Froala blot, for topic placeholder.
 */
export const isBlot = (node: Node) => {
  return (
    IsHTMLElement(node) &&
    node.tagName === 'SPAN' &&
    node.classList.contains('p360-ph') &&
    node.classList.contains('fr-deletable') &&
    node.hasAttribute('contenteditable') &&
    node.hasAttribute('spellcheck') &&
    node.getAttribute('data-type') === 'p360-ph' &&
    node.hasAttribute('data-id')
  )
}

export const hasBlots = (element: HTMLElement) => {
  const elements = element.querySelectorAll(
    'span.p360-ph.fr-deletable[contenteditable][spellcheck][data-type="p360-ph"][data-id]',
  )

  return elements.length > 0
}

export const hasTopics = (element: HTMLElement) => {
  const elements = element.querySelectorAll(
    'span.p360-ph.fr-deletable[contenteditable][spellcheck][data-type="p360-ph"][data-id^="topic::"]',
  )

  return elements.length > 0
}

export const isList = (element: HTMLElement): element is HTMLUListElement | HTMLOListElement => {
  return element.tagName.toLowerCase() === 'ul' || element.tagName.toLowerCase() === 'ol'
}

export const isListItem = (element: HTMLElement): element is HTMLLIElement => {
  return element.tagName.toLowerCase() === 'li'
}

/**
 * These blots are topic related and have a data-id beginning with 'topic::'
 */
export const isTopicBlot = (node: Node) => {
  const isFroala = isBlot(node)
  if (isFroala && IsHTMLElement(node)) {
    const id = node.getAttribute('data-id')
    if (!id) {
      return false
    }

    return id.startsWith('topic::')
  }
  return false
}

export const isLegacyTitle = (element: HTMLElement) => {
  const display = element.getAttribute('data-display')

  return !display
}

/**
 * Is the node a block level element (true), or inline (false)
 * @param {*} node
 */
const blockRegex =
  /^(address|blockquote|body|center|dir|div|dl|fieldset|form|h[1-6]|hr|isindex|menu|noframes|noscript|ol|p|pre|table|ul|dd|dt|frameset|li|tbody|td|tfoot|th|thead|tr|html)$/i
export const isBlock = (node: ChildNode) => {
  return blockRegex.test(node.nodeName)
}

const phrasingRegex =
  /^(a|abbr|area|audio|b|bdi|bdo|br|button|canvas|cite|code|command|datalist|del|dfn|em|embed|i|iframe|img|input|ins|kbd|keygen|label|map|mark|math|meter|noscript|object|output|progress|q|ruby|s|samp|script|select|small|span|strong|sub|svg|textarea|time|u|var|video|wbr|text|#text)$/i
export const isPhrasingContent = (node: ChildNode) => phrasingRegex.test(node.nodeName)

/**
 * Sometimes froala uses div tags to wrap elements that next text-alignments.
 * This utility checks if the node is such an element
 */
export const isAlignDiv = (node: HTMLElement) => {
  const style = get(node, 'style.cssText', '')
  return node.tagName === 'DIV' && style.includes('text-align')
}

/**
 * @param {*} nodeA
 * @param {*} nodeB
 */
export const isSame = (nodeA: HTMLElement, nodeB: HTMLElement) => {
  return nodeA.tagName === nodeB.tagName
}

/**
 * @param {*} node
 */
const isComment = (node: Node) => {
  return node.nodeType === Node.COMMENT_NODE
}

/**
 * @param {*} node
 */
const isEmptyText = (node: Node) => {
  return node.nodeType === Node.TEXT_NODE && node.nodeValue && !/\S/.test(node.nodeValue)
}

const hasOneChild = (node: Node) => node.childNodes.length === 1

export function getAppliedNotes(content: string, notes: any[]) {
  const documentText = DOM(content).documentElement?.textContent || ''
  const notesTexts = notes.filter((note: { text: string | undefined }) => {
    const text = DOM(note.text)?.documentElement?.textContent || ''
    return documentText.includes(text)
  })

  return notesTexts
}

export const getBlotDisplayText = (val: string) => {
  let result
  if (val.includes('topic::')) {
    // @ts-ignore
    const { name } = global.data.topics.getTopicByKebabCasedName(val.replace('topic::', ''))
    result = name || Str.startCase(val.replace('topic::', ''))
  } else {
    result = Str.startCase(val)
  }
  return result
}
