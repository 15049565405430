import App from 'components'
import { CustomRouter } from 'components/routes/router'
import googleTagManager from 'helpers/google-tag-manager'
import { configure } from 'mobx'
import posthog from 'posthog-js'
import ReactDOM from 'react-dom'
import createStore from 'stores'
/** STATICS */
import { isPROD } from './helpers/env'
import './static/css/phone.input.min.css'
import confetti from './static/js/confetti.min.js'

// @ts-ignore
global.confetti = confetti

configure({ enforceActions: 'never' })

function render() {
  ReactDOM.render(
    <CustomRouter>
      <App />
    </CustomRouter>,

    document.getElementById('app'),
  )
}

function init() {
  if (isPROD) {
    posthog.init('phc_EmUiATg4bhUCI2Wg6AA9UgotBnYu7lFJvap8GF7SbMW', {
      api_host: 'https://app.posthog.com',
      upgrade: true,
      secure_cookie: true,
    })

    googleTagManager()
  }
  createStore(true)
  render() // main render
}

init()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
