import { WarningOutlined } from '@ant-design/icons'
import { Alert, Button, DatePicker, Form, Input, InputNumber, Row, Select, Space, Tooltip, Typography } from 'antd'
import { HolistiplanSmartFields } from 'components/drawers/Smartfields/integration/providers/holistiplan/holistiplan.smartfields'
import { RiskalyzeSmartFields } from 'components/drawers/Smartfields/integration/providers/riskalyze/riskalyze.smartfields'
import { FormItemLabel } from 'components/drawers/Smartfields/smartfield.styles'
import { SmartField } from 'components/drawers/Smartfields/types'
import {
  SmartFieldConfig,
  SmartFieldDateConfig,
  SmartFieldIntegrationConfig,
  SmartFieldListConfig,
} from 'components/drawers/Smartfields/types/data'
import { HolistiplanConfig, RiskalyzeConfig } from 'components/drawers/Smartfields/types/integration'
import RemirrorPreviewEditor from 'lib/remirror/PreviewEditor'
import { autorun } from 'mobx'
import { observer } from 'mobx-react'
import moment from 'moment-timezone'
import { useCallback, useEffect, useState } from 'react'
import { smartfieldStore as sfStore } from 'stores/smartfields'
import { highlightActive } from 'stores/smartfields/utils'
import styled from 'styled-components'
import styles from 'styles'
import { Note } from 'types/graphql'
import { useReplaceSmartfieldsModal } from './hooks'
import { SmartFieldModal } from './index.styles'
import { formatDesc, formatLabel } from './utils'

const NextButton = styled(Button)`
  &:hover,
  &:focus {
    background: ${styles.colors.primary} !important;
    color: white !important;
    outline: none;
  }
`

export const ReplaceSmartFields: React.FC = observer(() => {
  const [editorRef, setEditorRef] = useState<HTMLElement>()
  const { visible } = useReplaceSmartfieldsModal()
  const [form] = Form.useForm()

  const onAction = useCallback(
    (action: () => void) => {
      action?.()
      form.resetFields()
    },
    [form],
  )

  useEffect(() => {
    return autorun(() => {
      if (sfStore.smartfield && editorRef) {
        const selector = `[data-mention-atom-id="${sfStore.smartfield.id}"]`
        const node = editorRef?.querySelector(selector)

        // scroll the active smartfield into view
        if (node) {
          node.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
        if (sfStore.currentValue && sfStore.smartfield.type === 'date') {
          const dateValue = moment(
            sfStore.currentValue as string,
            (sfStore.smartfield as SmartField<SmartFieldDateConfig>)?.config?.date,
          )
          form.setFieldValue(sfStore.smartfield.id, dateValue)
        } else {
          form.setFieldValue(sfStore.smartfield.id, sfStore.currentValue)
        }
        // highlight the active smartfield
        highlightActive(editorRef, sfStore.smartfield)
      }
    })
    // leave - this needs to rerender when sfStore.note changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorRef, form, sfStore.note])

  const getInitialValue = (smartfield: SmartField) => {
    if (!sfStore.currentValue) return null
    if (smartfield.type === 'date') {
      return moment(sfStore.currentValue, (smartfield as SmartField<SmartFieldDateConfig>)?.config?.date)
    } else {
      return sfStore.currentValue
    }
  }

  return (
    <SmartFieldModal
      title={
        <Space>
          <WarningOutlined
            style={{
              color: styles.colors.red,
              fontSize: 20,
            }}
          />
          <Typography.Text style={{ fontSize: 18 }}>{sfStore.modalTitle}</Typography.Text>
        </Space>
      }
      onCancel={() => sfStore.hideReplaceSmartFieldModal(true)}
      footer={null}
      okText="Replace"
      open={visible}
      destroyOnClose
      maskClosable={false}
    >
      <Space direction="vertical" style={{ paddingTop: 12, width: '100%' }} size={16}>
        <Alert message="Enter values for the required SmartFields below" type="info" showIcon />

        <Form form={form} onValuesChange={sfStore.update} requiredMark={false} layout="vertical">
          <Form.Item>
            <Tooltip title="SmartField replacement preview">
              <div className="note-preview">
                <Typography.Text className="note-preview--title">Preview</Typography.Text>
                <RemirrorPreviewEditor
                  content={sfStore.text}
                  classNames={['no-min-height']}
                  disableAtomSync
                  onDOMNode={setEditorRef}
                />
              </div>
            </Tooltip>
          </Form.Item>

          <div style={{}} />

          <Form.Item style={{ marginBottom: 0 }}>
            <Typography.Title level={5}>{sfStore.noteTitle}</Typography.Title>
          </Form.Item>

          {sfStore.smartfield?.config?.type === 'date' && (
            <Form.Item
              name={sfStore.smartfield?.id}
              label={
                <FormItemLabel label={formatLabel(sfStore.smartfield)} description={formatDesc(sfStore.smartfield)} />
              }
              initialValue={getInitialValue(sfStore.smartfield)}
              data-testid="replace-smartfield-date"
            >
              <DatePicker
                size="large"
                style={{ width: '100%' }}
                format={(sfStore.smartfield as SmartField<SmartFieldDateConfig>)?.config?.date}
                placeholder="Select a date"
              />
            </Form.Item>
          )}

          {sfStore.smartfield?.config?.type === 'list' && (
            <Form.Item
              name={sfStore.smartfield?.id}
              label={
                <FormItemLabel label={formatLabel(sfStore.smartfield)} description={formatDesc(sfStore.smartfield)} />
              }
              initialValue={getInitialValue(sfStore.smartfield)}
              data-testid="replace-smartfield-list"
            >
              <Select
                options={(sfStore.smartfield?.config as SmartFieldConfig<SmartFieldListConfig>).list
                  ?.slice()
                  ?.sort((a, b) => a?.label?.localeCompare(b.label))}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                placeholder="Select an option"
                optionFilterProp="children"
                size="large"
                showSearch
              />
            </Form.Item>
          )}

          {sfStore.smartfield?.config?.type === 'number' && (
            <Form.Item
              name={sfStore.smartfield?.id}
              label={
                <FormItemLabel label={formatLabel(sfStore.smartfield)} description={formatDesc(sfStore.smartfield)} />
              }
              initialValue={getInitialValue(sfStore.smartfield)}
              data-testid="replace-smartfield-number"
            >
              <InputNumber autoFocus size="large" style={{ width: '100%' }} placeholder="Input a number" />
            </Form.Item>
          )}

          {sfStore.smartfield?.config?.type === 'text' && (
            <Form.Item
              name={sfStore.smartfield?.id}
              label={
                <FormItemLabel label={formatLabel(sfStore.smartfield)} description={formatDesc(sfStore.smartfield)} />
              }
              initialValue={getInitialValue(sfStore.smartfield)}
              data-testid="replace-smartfield-text"
            >
              <Input autoFocus size="large" placeholder="Input your text" />
            </Form.Item>
          )}

          {/* Integrations */}
          {sfStore.smartfield?.config?.type === 'integration' && (
            <div data-testid="replace-smartfield-integration">
              {(sfStore.smartfield?.config as SmartFieldConfig<SmartFieldIntegrationConfig>).provider ===
                'holistiplan' && (
                <HolistiplanSmartFields
                  uid={sfStore.smartfield?.id}
                  form={form}
                  config={
                    sfStore.smartfield?.config as SmartFieldConfig<SmartFieldIntegrationConfig<HolistiplanConfig>>
                  }
                  note={sfStore.note as Note}
                />
              )}

              {(sfStore.smartfield?.config as SmartFieldConfig<SmartFieldIntegrationConfig>).provider ===
                'riskalyze' && (
                <RiskalyzeSmartFields
                  uid={sfStore.smartfield?.id}
                  form={form}
                  config={sfStore.smartfield?.config as SmartFieldConfig<SmartFieldIntegrationConfig<RiskalyzeConfig>>}
                  note={sfStore.note as Note}
                />
              )}
            </div>
          )}

          <Form.Item>
            <Row style={{ width: '100%' }} justify="space-between">
              <Button
                size="large"
                onClick={() =>
                  onAction(() => {
                    sfStore.hideReplaceSmartFieldModal(true)
                  })
                }
                data-testid="cancel-button"
                tabIndex={2}
              >
                Cancel
              </Button>

              <Space>
                {!sfStore.firstField && (
                  <Button
                    size="large"
                    onClick={() =>
                      onAction(() => {
                        sfStore.previousSmartfield()
                      })
                    }
                    data-testid="previous-smartfield-button"
                    tabIndex={1}
                  >
                    Previous
                  </Button>
                )}

                {/* Further smartfields to replace, move to next one */}
                {!sfStore.lastField ? (
                  <NextButton
                    type="primary"
                    size="large"
                    ghost
                    onClick={() =>
                      onAction(() => {
                        sfStore.nextSmartfield()
                      })
                    }
                    data-testid="next-smartfield-button"
                    tabIndex={0}
                  >
                    Next SmartField
                  </NextButton>
                ) : (
                  <NextButton
                    type="primary"
                    size="large"
                    ghost
                    onClick={() =>
                      onAction(() => {
                        sfStore.finish()
                      })
                    }
                    data-testid="finish-button"
                    tabIndex={0}
                  >
                    Finish
                  </NextButton>
                )}
              </Space>
            </Row>
          </Form.Item>
        </Form>
      </Space>
    </SmartFieldModal>
  )
})
