import * as apiService from 'lib/apollo/client'
import { EntityId } from 'types/entity.interface'
import {
  AdvisorInput,
  AdvisorInsertInput,
  CrmUsersDocument,
  CrmUsersQuery,
  CrmUsersQueryVariables,
  DeleteAdvisorDocument,
  DeleteAdvisorMutation,
  DeleteAdvisorMutationVariables,
  GetAdvisorsCountDocument,
  GetAdvisorsCountQuery,
  GetAdvisorsCountQueryVariables,
  GetAdvisorsSignaturesDocument,
  GetAdvisorsSignaturesQuery,
  GetAdvisorsSignaturesQueryVariables,
  InsertAdvisorDocument,
  InsertAdvisorMutation,
  InsertAdvisorMutationVariables,
  InviteAdvisorsDocument,
  InviteAdvisorsMutation,
  InviteAdvisorsMutationVariables,
  UpdateAdvisorDocument,
  UpdateAdvisorMutation,
  UpdateAdvisorMutationVariables,
  ValidateRedemptionDocument,
  ValidateRedemptionMutation,
  ValidateRedemptionMutationVariables,
} from 'types/graphql'
import DataError, { IsError } from '../../helpers/dataError'

class AdvisorsService {
  async validateRedemption(username: string, code: string) {
    const { validateRedemption: resp } = await apiService.mutate<
      ValidateRedemptionMutation,
      ValidateRedemptionMutationVariables
    >(ValidateRedemptionDocument, { username, code })

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.isValid
  }

  async inviteAdvisors(emailsArray: Array<string>) {
    const emails = emailsArray.map((e) => (e || '').trim().toLowerCase()).filter((value) => value)

    const { inviteAdvisors: resp } = await apiService.mutate<InviteAdvisorsMutation, InviteAdvisorsMutationVariables>(
      InviteAdvisorsDocument,
      { emails },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return !!resp.sent
  }

  async insertAdvisor(email: string, firstName: string, lastName: string, phone: string) {
    const advisor: AdvisorInsertInput = { email, firstName, lastName, phone }
    const { insertAdvisor: resp } = await apiService.mutate<InsertAdvisorMutation, InsertAdvisorMutationVariables>(
      InsertAdvisorDocument,
      { advisor },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.advisor
  }

  async deleteAdvisor(id: EntityId, replacerId?: EntityId) {
    const { deleteAdvisor: resp } = await apiService.mutate<DeleteAdvisorMutation, DeleteAdvisorMutationVariables>(
      DeleteAdvisorDocument,
      { id, replacerId },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async updateAdvisor(id: EntityId, advisor: AdvisorInput, replacerId?: EntityId) {
    const { updateAdvisor: resp } = await apiService.mutate<UpdateAdvisorMutation, UpdateAdvisorMutationVariables>(
      UpdateAdvisorDocument,
      {
        advisor,
        id,
        replacerId,
      },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async fetchCRMUsers() {
    const { crmUsers: resp } = await apiService.query<CrmUsersQuery, CrmUsersQueryVariables>(CrmUsersDocument)
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.crmUsers
  }

  getAdvisorsSignatures = async () => {
    const { getAdvisorsSignatures: resp } = await apiService.query<
      GetAdvisorsSignaturesQuery,
      GetAdvisorsSignaturesQueryVariables
    >(GetAdvisorsSignaturesDocument)
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.signatures
  }

  getAdvisorsCount = async () => {
    const { getAdvisorsCount: resp } = await apiService.query<GetAdvisorsCountQuery, GetAdvisorsCountQueryVariables>(
      GetAdvisorsCountDocument,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.count
  }
}

export default new AdvisorsService()
