import { LockOutlined } from '@ant-design/icons'
import { Button, Form, FormInstance, Input, Modal, Switch, Tooltip } from 'antd'
import { PERMS_KEYS } from 'constants/perms.constants'
import { useCallback, useEffect, useState } from 'react'
import Presenter from '../pm'

interface SecurePrefixSwitchProps {
  form: FormInstance
  pm: Presenter
}

export const SecurePrefixSwitch: React.FC<SecurePrefixSwitchProps> = ({ form, pm }) => {
  const [visiblePopover, setVisiblePopover] = useState(false)
  const [readReceipts, setReadReceipts] = useState(false)

  const [checked, setChecked] = useState(false)
  const [secureFieldPrefix, setPrefix] = useState('')

  const onSetSecure = useCallback(
    (isSecure, defaultPrefix) => {
      if (!defaultPrefix) {
        return setVisiblePopover(true)
      }
      setChecked(isSecure)
      const prefix = defaultPrefix + ' '
      if (!prefix.trim()) {
        return
      }
      const filledOutSubject = prefix + form.getFieldValue('subject')
      const noPrefixSubject = form.getFieldValue('subject').replace(prefix, '')
      form.setFieldsValue({ subject: isSecure ? filledOutSubject : noPrefixSubject })
    },
    [form],
  )

  const onSetReadReceipt = useCallback(
    (value) => {
      pm.readReceipt = value
      setReadReceipts(value)
    },
    [pm],
  )

  const onCloseModal = useCallback(
    (whileSaving) => {
      if (whileSaving) {
        global.data.practice.update({ secureFieldPrefix: String(secureFieldPrefix).trim() }, true)
        onSetSecure(true, String(secureFieldPrefix).trim())
      }

      setVisiblePopover(false)
    },
    [onSetSecure, secureFieldPrefix],
  )

  useEffect(() => {
    const value = global.data.practice.defaultReadReceipt
    pm.readReceipt = value

    setReadReceipts(value)
  }, [pm])

  const showReadReceipts = global.perms.get(PERMS_KEYS.READ_RECEIPTS)
  const isReadReceiptsEnabled = global.perms.isAvailable(PERMS_KEYS.READ_RECEIPTS)

  return (
    <>
      <Modal
        title="Set Security Phrase"
        centered
        footer={
          <>
            <Button onClick={() => onCloseModal(false)}>Cancel</Button>
            <Button
              disabled={!String(secureFieldPrefix).trim()}
              onClick={() => onCloseModal(true)}
              htmlType="button"
              type="primary"
            >
              Set
            </Button>
          </>
        }
        open={visiblePopover}
        onOk={() => onCloseModal(true)}
        onCancel={() => onCloseModal(false)}
      >
        <Form layout="inline">
          <Form.Item
            label="Subject Line Prefix"
            name="secureFieldPrefix"
            extra="Add your email encryption service’s security phrase here. Select it before sending an email. Note, you can change this from the Settings panel."
            style={{ flex: 1, marginTop: -12 }}
          >
            <Input
              addonBefore={<LockOutlined />}
              placeholder="[Secure] My Subject text"
              type="text"
              onChange={(e) => setPrefix(e.target.value)}
              value={secureFieldPrefix}
              allowClear
            />
          </Form.Item>
        </Form>
      </Modal>

      <Tooltip title={`Add your email encryption service’s security phrase to the subject line.`}>
        <Switch
          id="add_note_modal-keep_open_toggle"
          checkedChildren={<>Email Secured</>}
          unCheckedChildren={<>Secure Email</>}
          onChange={(value) => onSetSecure(value, global.data.practice.secureFieldPrefix)}
          checked={checked}
        />
      </Tooltip>

      {showReadReceipts && (
        <Tooltip title={`Receive a read receipt for this email`}>
          <Switch
            style={{ marginLeft: 10 }}
            id="add_note_modal-keep_open_toggle"
            checkedChildren={<>Read Receipt</>}
            unCheckedChildren={<>Send Receipt</>}
            onChange={onSetReadReceipt}
            checked={readReceipts}
            disabled={!isReadReceiptsEnabled}
          />
        </Tooltip>
      )}
    </>
  )
}
