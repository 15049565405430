import { Card } from 'antd'
import logo from 'assets/images/pulse_logo_green_v3.png'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import styles from 'styles'
import Layout from './styles'

const StyledCard = styled(Card)`
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.03);
  .ant-card-body {
    padding: 30px 40px;
    .header,
    .footer {
      align-items: start;
      display: flex;
    }
    .header {
      display: flex;
      flex-direction: ${(p) => (p.right ? 'row-reverse' : 'row')};
      img {
        margin-right: ${(p) => (p.right ? 0 : 16)}px;
        margin-left: ${(p) => (p.right ? 30 : -5)}px;
        padding-bottom: 4px;
        /* padding-top: 10px; */
        width: 40px;
      }
      h1,
      h2 {
        font-family: 'proxima-nova' !important;
        font-weight: 600 !important;
        border-bottom: 1px solid ${styles.colors.border}!important;
        margin-bottom: 20px !important;
        font-size: 24px !important;
        color: black !important;
        padding-bottom: 2px;
        padding-top: 3px;
        width: 100%;
      }
    }
    .footer {
      /* border-top: 1px solid ${styles.colors.lightBorder}
      margin-top: 10px; */
    }
  }
  input {
    font-size: 14px !important;
  }
`

const Patent = styled.div`
  color: ${styles.colors.cyan};
  margin-top: 15px;
  font-size: 12px;
`

const P = styled.p`
  margin-bottom: 24px !important;
  font-size: 15px;
  &.tight {
    margin-bottom: 10px !important;
  }
  &.tightest {
    margin-bottom: 4px !important;
  }
`

const Link = styled.a`
  color: ${styles.colors.blue};
  font-size: 15px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

/**
 * Simple centered layout for positioning content in
 * the center of the page, as per the Login page
 * @param {*} props
 */
const CenteredLayout = (props) => (
  <Layout className="c-layout centered">
    {/* <Background><BG /></Background> */}
    {props.extra && <div className="centered-extra">{props.extra}</div>}
    <Layout.Content className="c-layout__content flex">
      <StyledCard bordered actions={props.actions} right={props.logoRight ? props.logoRight : undefined}>
        <div className="header">
          {!props.noLogo && <img src={logo} alt="" />}
          {props.title && <h2>{props.title}</h2>}
        </div>
        {props.children}
        <div className="footer">{props.footer}</div>
      </StyledCard>
      <Patent>Patent Pending #16/726,041</Patent>
    </Layout.Content>
  </Layout>
)

CenteredLayout.propTypes = {
  title: PropTypes.string,
  footer: PropTypes.object,
  avatar: PropTypes.node,
  actions: PropTypes.array,
  color: PropTypes.string,
  logoRight: PropTypes.bool,
  noLogo: PropTypes.bool,
}

CenteredLayout.defaultProps = {
  logoRight: false,
  actions: [],
  noLogo: false,
}

CenteredLayout.P = P
CenteredLayout.Link = Link
export default CenteredLayout
