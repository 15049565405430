/* eslint-disable @typescript-eslint/ban-ts-comment */
import { DownOutlined, HomeOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Dropdown, Typography } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { sortBy } from 'lodash'
import { observer } from 'mobx-react'
import styled from 'styled-components'

const menuClick = ({ key }: { key: string }) => {
  switch (true) {
    case key.startsWith('ws-'):
      global.stores.auth.signOut()
      //@ts-ignore
      global.workspace.load(key.replace('ws-', '')).catch(() => {})
      break

    case key === 'diff':
      global.stores.auth.signOut()
      //@ts-ignore
      global.workspace.reset()
      break
    default:
      break
  }
}

const StyledDropdown = styled(Dropdown)`
  .workspace-group {
    ul {
      background: red;
      max-height: 50vh;
      overflow: auto;
    }
  }
`

const WorkspaceMenu = () => {
  const items: ItemType[] = [
    {
      type: 'group',
      label: 'Workspaces',
      className: 'workspace-group',
      //@ts-ignore
      children: [
        {
          key: 'diff',
          icon: <PlusCircleOutlined />,
          label: 'Add Workspace',
        },
        {
          type: 'divider',
        },
        ...sortBy(global.workspace.teams, ['name']).map((team, i) => ({
          key: `ws-${team.domain}`,
          icon: <HomeOutlined />,
          label: team.name,
        })),
      ],
    },
  ]

  // @ts-ignore
  const selectedKeys = [`ws-${global.workspace.team.domain}`]

  return (
    <div style={{ textAlign: 'right' }}>
      <Typography.Text style={{ fontSize: 16, paddingRight: 2 }} ellipsis strong>
        {/* @ts-ignore */}
        {global.workspace.team.name}
      </Typography.Text>
    </div>
  )

  // NOTE: this allowed a choice of different practices but customers have only one for now
  return (
    <StyledDropdown menu={{ items, onClick: menuClick, selectedKeys }} placement="bottomRight" trigger={['click']}>
      <Button type="text" style={{ maxWidth: '100%' }}>
        <Typography.Text style={{ fontSize: 16 }} ellipsis strong>
          {/* @ts-ignore */}
          {global.workspace.team.name}
        </Typography.Text>
        <DownOutlined />
      </Button>
    </StyledDropdown>
  )
}

export default observer(WorkspaceMenu)
