import Icon, { SettingOutlined } from '@ant-design/icons'
import { Button, Dropdown, MenuProps } from 'antd'
import { observer } from 'mobx-react'
import { ImCogs } from 'react-icons/im'
import { LAYOUT_SUBMODULES, MODULES, createTourLabel } from '../../../../constants/tours'

const dataTourLabel = createTourLabel(MODULES.LAYOUT, LAYOUT_SUBMODULES.HEADER)

const menuClick = ({ key }: { key: string }) => {
  switch (true) {
    case key === 'settings':
      global.router.goto('/settings/practice')
      break

    default:
      break
  }
}

const items: MenuProps['items'] = [
  {
    key: 'settings',
    icon: <Icon component={ImCogs} />,
    label: 'Settings',
    className: dataTourLabel('settingsMenu', 'settings'),
  },
]

const SettingsMenu = () => {
  return (
    <Dropdown menu={{ items, onClick: menuClick }} placement="bottomRight" trigger={['click']}>
      <Button shape="circle" data-tour={dataTourLabel('settingsMenu', '')}>
        <SettingOutlined />
      </Button>
    </Dropdown>
  )
}

export default observer(SettingsMenu)
