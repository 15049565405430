import BaseMapper from './base'
import { Types } from '../utils'
import { Placeholder } from 'components/froala/types/mapper'
import MergeMap from '../_merge-map'

class PDFCrowdMapper extends BaseMapper {
  static placeHolders = [
    {
      id: 'pdfcrowd-pagecount',
      label: 'Footer - Page Count',
      func: () => {
        return "<span class='pdfcrowd-page-count'></span>"
      },
    },
    {
      id: 'pdfcrowd-pagenumber',
      label: 'Footer - Page Number',
      func: () => {
        return "<span class='pdfcrowd-page-number'></span>"
      },
    },
  ]

  static isCustom = true
  type = Types.PDFCROWD

  /**
   * @param {*} options = {}
   */
  constructor(node: HTMLElement, options = {}, mergeMap: MergeMap) {
    super(node, options, mergeMap)

    this.value = this.mapValue({ value: '' })
  }

  /**
   * Uses any specified mapping function for this placeHolder, to transform the val.
   * @param {*} id
   * @param {*} val
   * @returns
   */
  mapValue = ({
    placeHolders = PDFCrowdMapper.placeHolders,
    value,
  }: {
    placeHolders?: Placeholder[]
    value: string
  }) => {
    return super.mapValue({ placeHolders, value })
  }

  /**
   * Checks if this mapper matches the soon to be created mapper.
   * @param {*} options
   */
  match = (options: { id?: string; customText?: string }) => {
    return this.label === options.customText && this.options.id === options.id
  }

  /**
   * Updates the riskalyze value.
   */
  update = async () => {
    this.mergeMap?.replace(this)
  }

  /**
   * Disallow tooltips
   * @param {*} value
   */
  tooltip = () => {}
}

export default PDFCrowdMapper
