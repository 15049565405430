/* eslint-disable handle-callback-err, no-console */
// import React from 'react'
import { Auth } from '@aws-amplify/auth'
import { Authenticator as AwsAuth } from 'aws-amplify-react'

const AUTHENTICATOR_AUTHSTATE = 'amplify-authenticator-authState'

class Authenticator extends AwsAuth {
  constructor(props) {
    super(props)
    global.bus.on(
      global.bus.WS_CHANGE,
      () => {
        this.checkMountState()
      },
      this,
    )
  }

  componentDidMount() {
    this._isMounted = true
    this.checkMountState()
  }

  checkMountState = () => {
    const {
      pathname,
      wsParams: { username, code },
      params: { existed = '0' },
    } = global.deeplink
    if (!global.workspace.team) {
      this.handleStateChange('enterWorkspace', {})
    } else if (pathname === 'redeem' && username && code) {
      this.handleStateChange('signUp', { username: username.toLowerCase(), code, existed: Number(existed) })
    } else if (!global.workspace.sso) {
      this.handleStateChange('signIn', {})
    } else if (global.workspace.validated) {
      this.checkUser()
    }
  }

  checkUser() {
    return Auth.currentAuthenticatedUser()
      .then(async (user) => {
        if (!this._isMounted) {
          return
        }
        try {
          const acceptedEula = await global.stores.auth._signIn(user)
          const state = acceptedEula ? 'signedIn' : 'acceptEula'
          this.handleStateChange(state, user)
        } catch (er) {
          this.handleStateChange('signIn')
          this.handleAuthEvent({}, { type: 'error', data: er })
        }
      })
      .catch(() => {
        if (!this._isMounted) {
          return
        }
        let cachedAuthState = null
        try {
          cachedAuthState = localStorage.getItem(AUTHENTICATOR_AUTHSTATE)
        } catch (e) {
          console.debug('Failed to get the auth state from local storage', e)
        }
        const promise = cachedAuthState === 'signedIn' ? Auth.signOut() : Promise.resolve()
        promise
          .then(() => this.handleStateChange(this._initialAuthState))
          .catch((e) => {
            console.debug('Failed to sign out', e)
          })
      })
  }

  onHubCapsule(capsule) {
    const { channel, payload } = capsule
    if (channel === 'auth') {
      switch (payload.event) {
        case 'cognitoHostedUI':
          this.handleStateChange('signedIn', payload.data)
          break
        case 'cognitoHostedUI_failure':
          this.handleStateChange('signIn', null)
          break
        case 'parsingUrl_failure':
          this.handleStateChange('signIn', null)
          break
        case 'signOut':
          this.handleStateChange(global.workspace.team ? 'signIn' : 'enterWorkspace', null)
          break
        case 'customGreetingSignOut':
          this.handleStateChange('signIn', null)
          break
        case 'parsingCallbackUrl':
          localStorage.setItem('amplify-signin-with-hostedUI', 'true')
          break
        default:
          break
      }
    }
  }
}

export default Authenticator
