import { isEmpty } from 'lodash'

async function loadLibPhoneNumber() {
  const mod = await import(/* webpackChunkName: "libphonenumber-js" */ 'libphonenumber-js')
  return mod
}

const isValid = async (phoneNumber) => {
  const lib = await loadLibPhoneNumber()
  if (isEmpty(phoneNumber)) {
    return false
  }
  const result = lib.parsePhoneNumberFromString(phoneNumber)
  if (!result) {
    return false
  }
  return result.isValid()
}

const getType = async (phoneNumber) => {
  const lib = await loadLibPhoneNumber()
  if (isEmpty(phoneNumber)) {
    return undefined
  }
  const result = lib.parsePhoneNumberFromString(phoneNumber)
  return result.getType()
}

export default {
  isValid,
  getType,
}
