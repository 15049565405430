import { createUploadLink } from 'apollo-upload-client'
import { setContext } from '@apollo/client/link/context'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { onError } from '@apollo/client/link/error'
import apolloLogger from 'apollo-link-logger'
import { createClient } from 'graphql-ws'
import { signToken } from 'helpers/signer'
import { get } from 'lodash'
import { errorHandlers } from './errors'
import { ApolloLink } from '@apollo/client'

const REGEX = /(http)(s)?:\/\//
const wsUri = (uri) => uri.replace(REGEX, 'ws$2://')

/**
 * logs graphql errors to the console
 */
export const errorLink = () => {
  return onError(({ operation, graphQLErrors, networkError }) => {
    const operationName = get(operation, 'operationName', 'unknown_operation')

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, extensions }) => {
        let stack
        if (extensions) {
          stack = get(extensions, 'exception.stacktrace[0]', stack)
        }
        global.error(`[GraphQL error]: Message: ${message}, Operation: ${operationName} Stack: ${stack}`)
      })
    }
    if (networkError) {
      const code = get(networkError, 'result.errors[0].extensions.code', JSON.stringify(networkError, null, 2))
      global.error(`[Network error]: ${networkError}`)
      errorHandlers(code)
    }
  })
}

/**
 * stateless link to add headers
 */
export const authLink = () =>
  setContext((_, { headers }) => {
    const auth =
      process.env.NODE_ENV !== 'test'
        ? {
            'p-aws': global.workspace.pool,
            'x-aws': signToken(),
          }
        : {
            aaws: 'true',
          }

    return {
      headers: {
        ...headers,
        ...auth,
      },
    }
  })

/**
 * link for socket transport
 * @param {*} options Either SubscriptionClient or an object with three options on it to customize the behavior of the link
 */
export const wsLink = (uri) =>
  new GraphQLWsLink(
    createClient({
      url: wsUri(uri),
      connectionParams: {
        paws: global.workspace?.pool,
        xaws: signToken(),
      },
      lazy: true,
    }),
  )

export const uploadLink = (uri) =>
  createUploadLink({
    uri: uri,
    headers: {
      'keep-alive': 'true',
    },
  })

export const logLink = () => ApolloLink.from([apolloLogger])
