import * as apiService from 'lib/apollo/client'
import { makeObservable, observable } from 'mobx'
import { riskalyzeCreateQuestionnaire, riskalyzeFindClients } from 'stores/apollo/query/riskalyze.gql'
import { msg } from './msg'

class RiskalyzeStore {
  showModal = null
  _client = null
  _clientId = null
  _clients = []

  constructor() {
    makeObservable(this, {
      showModal: observable,
      _client: observable,
      _clientId: observable,
    })
  }

  findClients = async () => {
    global.app.loading = true
    const { riskalyzeFindClients: resp } = await apiService.query(riskalyzeFindClients)
    global.app.loading = false
    if (!Array.isArray(resp)) {
      msg.error(['Nitrogen clients', 'fetching'])
    } else {
      this._clients = resp
    }

    return this._clients
  }

  createQuestionnaire = async (riskalyzeClientId, type, amountInvested) => {
    global.app.loading = true
    const { riskalyzeCreateQuestionnaire: resp } = await apiService.mutate(riskalyzeCreateQuestionnaire, {
      questionnaire: { riskalyzeClientId, type, ...(amountInvested && { amountInvested: +amountInvested }) },
    })
    global.app.loading = false
    if (!resp.ok) {
      msg.error(['Nitrogen clients', 'fetching'])
    }

    return resp.link
  }
}

export default RiskalyzeStore
