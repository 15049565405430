import { SmartFieldMentionAttrs } from 'components/drawers/Smartfields/types'
import { orderBy } from 'lodash'
import { SmartFieldKind } from 'stores/smartfields/constants'
import { Topic } from 'types/graphql'
import { v4 as uuidv4 } from 'uuid'
import { SmartFieldAtomAttrs } from '../../../extensions/smartFieldAtom/component'
import { DISPLAY_MODE } from '../../../extensions/smartFieldAtom/utils/displayMode.constant'

export const SmartFieldMenuConstants = {
  USER_NICKNAME: 'user-nickname',
  USER_FULLNAME: 'user-fullname',
  CONTACT_GREETING: 'contact-greeting',
  CONTACT_NAME_AND_ADDRESS: 'contact-name-and-address',
  CONTACT_FORMAL_GREETING: 'contact-formal-greeting',
  CONTACT_ADDRESS: 'contact-address',
  DOCUMENT_CREATION_DATE: 'document-creation-date',
  EVENT_DATE: 'event-date',
  EVENT_TIME: 'event-time',
  USER_EMAIL_SIGNATURE: 'user-email-signature',
  USER_WET_SIGNATURE: 'user-wet-signature',
  CUSTOM_PLACEHOLDER: 'custom-placeholder',
  SMARTFIELD: 'smartfield',
} as const

const defaultProps: Pick<SmartFieldMentionAttrs, 'config' | 'reuse'> = {
  config: '',
  reuse: 'false',
}

const dynamicSmartfields: Omit<SmartFieldMentionAttrs, 'config' | 'reuse'>[] = [
  {
    id: SmartFieldMenuConstants.CUSTOM_PLACEHOLDER,
    label: 'Add SmartField',
    displayMode: DISPLAY_MODE.INLINE,
    kind: SmartFieldKind.CUSTOM,
  },
]

const autoSmartfields: Omit<SmartFieldMentionAttrs, 'config' | 'reuse'>[] = [
  {
    id: SmartFieldMenuConstants.CONTACT_GREETING,
    label: 'Contact Greeting',
    displayMode: DISPLAY_MODE.INLINE,
    kind: SmartFieldKind.CONTACT,
  },
  {
    id: SmartFieldMenuConstants.CONTACT_FORMAL_GREETING,
    label: 'Contact Formal Greeting',
    displayMode: DISPLAY_MODE.INLINE,
    kind: SmartFieldKind.CONTACT,
  },
  {
    id: SmartFieldMenuConstants.CONTACT_NAME_AND_ADDRESS,
    label: 'Contact Name and Address',
    displayMode: DISPLAY_MODE.PARAGRAPH,
    kind: SmartFieldKind.CONTACT,
  },
  {
    id: SmartFieldMenuConstants.CONTACT_ADDRESS,
    label: 'Contact Address',
    displayMode: DISPLAY_MODE.INLINE,
    kind: SmartFieldKind.CONTACT,
  },

  // User/Advisor placeholders
  {
    id: SmartFieldMenuConstants.USER_FULLNAME,
    label: 'User Fullname',
    displayMode: DISPLAY_MODE.INLINE,
    kind: SmartFieldKind.USER,
  },
  {
    id: SmartFieldMenuConstants.USER_NICKNAME,
    label: 'User Nickname',
    displayMode: DISPLAY_MODE.INLINE,
    kind: SmartFieldKind.USER,
  },
  {
    id: SmartFieldMenuConstants.USER_WET_SIGNATURE,
    label: 'User Wet Signature',
    displayMode: DISPLAY_MODE.PARAGRAPH,
    kind: SmartFieldKind.USER,
  },
  {
    id: SmartFieldMenuConstants.USER_EMAIL_SIGNATURE,
    label: 'User Email Signature',
    displayMode: DISPLAY_MODE.PARAGRAPH,
    kind: SmartFieldKind.USER,
  },

  // Date/Time placeholders
  {
    id: SmartFieldMenuConstants.DOCUMENT_CREATION_DATE,
    label: 'Creation Date',
    displayMode: DISPLAY_MODE.INLINE,
    kind: SmartFieldKind.DATE,
  },
  {
    id: SmartFieldMenuConstants.EVENT_DATE,
    label: 'Event Date',
    displayMode: DISPLAY_MODE.INLINE,
    kind: SmartFieldKind.DATE,
  },
  {
    id: SmartFieldMenuConstants.EVENT_TIME,
    label: 'Event Time',
    displayMode: DISPLAY_MODE.INLINE,
    kind: SmartFieldKind.DATE,
  },
]

export type SmartFieldMenuTypes = 'dynamic' | 'auto' | 'topic'

export function getMenuItems(mode: SmartFieldMenuTypes[], topics?: Topic[]): SmartFieldMentionAttrs[] {
  const items = [
    ...(mode.includes('dynamic') ? dynamicSmartfields : []),
    ...(mode.includes('auto') ? autoSmartfields : []),
    ...(mode.includes('topic') ? parseTopics(topics) : []),
  ].filter(Boolean)

  const menuItems = items.map(addDefaults)
  const sortedItems = orderBy(menuItems, ['label'])

  return sortedItems
}

const parseTopics = (topics?: Topic[]): Omit<SmartFieldMentionAttrs, 'config' | 'reuse'>[] =>
  (topics ?? []).map((topic) => ({
    id: uuidv4(),
    label: topic.name!,
    displayMode: DISPLAY_MODE.INLINE,
    kind: SmartFieldKind.TOPIC,
  }))

const addDefaults = (item: Omit<SmartFieldMentionAttrs, 'config' | 'reuse'>): SmartFieldMentionAttrs =>
  ({ ...defaultProps, ...item } as SmartFieldMentionAttrs)

export const smartFieldMenuItems = new Map(
  [...dynamicSmartfields.map(addDefaults), ...autoSmartfields.map(addDefaults)].map((sf) => [sf.id, sf]),
)

export function matchesCategoryOrSearch(item: SmartFieldAtomAttrs, query: string) {
  const [category, search] = query.split(/\s+/)

  const includesCategory = item.kind.toLowerCase().includes(category)
  const includesSearch = item.label.toLowerCase().includes(search)

  return includesCategory && includesSearch
}

export function matchesKindOrLabel(item: SmartFieldAtomAttrs, query: string) {
  const queryIncludesKind = item.kind.toLowerCase().includes(query)
  const queryIncludesLabel = item.label.toLowerCase().includes(query)

  return queryIncludesKind || queryIncludesLabel
}
