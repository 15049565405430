import { imageURLsTagAtrribute, leadingTrailingSlashes as slashes } from 'constants/regex'
import { dropRight, isEmpty, last } from 'lodash'
import moment from 'moment'
import Url from 'url'
import { sanitizeUrl } from './url'
const ds = (str = '') => str.replace(slashes, '')

/**
 * https:my-bucket.s3.us-west-2.amazon.com/static/images/SomeImage.jpg
 * @return example: SomeImage.jpg
 */
export const fileName = (file) => {
  if (isEmpty(file.url)) {
    return ds(file.name)
  }
  const { pathname } = Url.parse(file.url)
  return last(ds(pathname).split('/'))
}

/**
 * https:my-bucket.s3.us-west-2.amazon.com/static/images/SomeImage.jpg
 * @return example: /static/images/
 */
export const filePath = (file) => {
  if (isEmpty(file.url)) {
    return ds(file.path)
  }
  const { pathname } = Url.parse(file.url)
  const parts = ds(pathname).split('/')
  return dropRight(parts).join('/')
}

/**
 * https:my-bucket.s3.us-west-2.amazon.com/static/images/SomeImage.jpg
 * @return example: https:my-bucket.s3.us-west-2.amazon.com
 */
export const fileHost = (file) => {
  if (isEmpty(file.url)) {
    return ''
  }
  const { hostname, protocol } = Url.parse(file.url)
  return `${protocol}//${ds(hostname)}`
}

/**
 * Checks a S3 URL to see if the signature is still valid
 * @param {*} url
 * @return {*} isValid (boolean)
 */
export const signatureTimeIsValid = (url) => {
  if (!url) {
    throw new Error('Invalid URL')
  }
  const parsedURL = new URL(url)
  const paramTokenCreation = parsedURL.searchParams.get('X-Amz-Date')
  const paramTokenExpiration = parsedURL.searchParams.get('X-Amz-Expires')
  if (!paramTokenCreation || !paramTokenExpiration) {
    return false
  }

  const maxUseDateTime = moment(paramTokenCreation).add(paramTokenExpiration, 's')
  const dateTimeWithThreshold = moment().add(60, 's') // I add 60 seconds just to account for user interaction

  return dateTimeWithThreshold.isBefore(maxUseDateTime)
}
/**
 * Prepares a file to be uploaded, with all its metadata
 * @param {*} bucket global.data.practice.bucket
 * @param {*} file
 */
export function fileParts(bucket, file) {
  const host = fileHost(file)
  const path = filePath(file)
  const name = fileName(file)
  const key = `${path}/${name}`
  return {
    bucket,
    host,
    path,
    name,
    file,
    key,
  }
}

/**
 * Encodes a URL using the URL constructor
 * @param {String} url
 * @return {String} encodedURL
 */
export function generateURL(url) {
  if (!url) {
    throw new Error('Not a valid URL')
  }
  const path = new URL(url)
  const search = !path.search.includes('X-Amz-Security-Token')
    ? path.search
    : path.search
        .split('&')
        .map((searchParam) => {
          const split = searchParam.indexOf('=') // because to AWS using "=" as part of a value is possible (idiots)
          const [key, value] = [searchParam.slice(0, split), searchParam.slice(split + 1)]
          return key + '=' + encodeURIComponent(value)
        })
        .join('&')

  return `${path.origin}${path.pathname}${search}`
}

/**
 * Removes all queryParams from an URL
 * @param {String} url
 * @return {String} cleanURL
 */
export function removeQueryParamsFromURL(url) {
  if (!url) {
    throw new Error('Not a valid URL')
  }
  const path = new URL(url)
  return `${path.origin}${path.pathname}`
}

/**
 * Replaces in an HTML string every image signedURL, with the URL without signature
 * @param {String} html HTML string to search and replace
 * @return {String} cleanHTML
 */
export function clearImagesURLOnBody(html) {
  return html.replace(imageURLsTagAtrribute, (urlWithAttr) => replaceSrcOrHrefURL(urlWithAttr, sanitizeUrl))
}

/**
 * Replaces in an HTML string every image URL, with it's encoded version
 * @param {String} html HTML string to search and replace
 * @return {String} encodedImagesHTML
 */
export function encodeImagesURLOnBody(html) {
  return html.replace(imageURLsTagAtrribute, (urlWithAttr) => replaceSrcOrHrefURL(urlWithAttr, generateURL))
}

function replaceSrcOrHrefURL(urlWithAttr, urlTransformer) {
  const newUrlWithAttr = urlWithAttr.replace("'", '"').split('"')

  newUrlWithAttr[1] = urlTransformer(newUrlWithAttr[1])

  return newUrlWithAttr.join('"')
}
