import { useMail } from 'lib/data/mail/mail.hooks'
import { SendEmail as Send } from './send'

export const SendEmail = () => {
  const { showModal, config } = useMail()

  return (
    <>
      {showModal && <Send config={config} />}
    </>
  )
}
