/* eslint-disable no-underscore-dangle */
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { UnlockOutlined } from '@ant-design/icons'
import Auth from '@aws-amplify/auth'
import { Button, Input, Radio } from 'antd'
import { VerifyContact } from 'aws-amplify-react'
import CenteredLayout from 'components/layouts/CenteredLayout'
import styled from 'styled-components'
import styles from 'styles'

const StyledForm = styled(Form)`
  width: 320px;
  /* margin-top: 16px; */
  .ant-form-item,
  .ant-legacy-form-item {
    margin-bottom: 10px !important;
    &.tight {
      margin-bottom: 0px !important;
    }
  }
  .c-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .c-button {
    margin-top: 30px;
    .reset-button {
      width: 100%;
    }
  }
  .c-error {
    color: ${styles.colors.error};
    text-align: center;
  }
  .c-forgot {
    color: ${styles.colors.blue};
    cursor: pointer;
    font-size: 14px;
    &:hover {
      text-decoration: underline;
    }
  }
`

const StyledRadio = styled(Radio)`
  line-height: 30px;
  display: block;
  height: 30px;
`

const trim = (str = '') => str.trim()

class VerifyContactComponent extends VerifyContact {
  constructor(props) {
    super(props)
    this._validAuthStates = ['verifyContact']
    this.state = { verifyAttr: null, loading: false }
  }

  onVerify = (e) => {
    e && e.preventDefault()
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        if (!values.radio) {
          this.error('Neither Email nor Phone Number selected')
          return
        }
        this.setState({ loading: true })
        Auth.verifyCurrentUserAttribute(values.radio)
          .then((data) => {
            this.setState({ verifyAttr: values.radio, loading: false })
          })
          .catch((err) => {
            this.setState({ loading: false })
            this.error(err)
          })
      }
    })
  }

  onSubmit = (e) => {
    e && e.preventDefault()
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        this.setState({ loading: true })
        const attr = this.state.verifyAttr
        Auth.verifyCurrentUserAttributeSubmit(attr, trim(values.code))
          .then(async (data) => {
            try {
              const acceptedEula = await global.stores.auth._signIn(this.props.authData)
              const state = acceptedEula ? 'signedIn' : 'acceptEula'
              this.changeState(state, this.props.authData)
              this.setState({ verifyAttr: null, loading: false })
            } catch (er) {
              this.error(er)
            }
          })
          .catch((er) => {
            this.setState({ loading: false })
            this.error(er.message)
          })
      }
    })
  }

  onSkip = async () => {
    try {
      const acceptedEula = await global.stores.auth._signIn(this.props.authData)
      const state = acceptedEula ? 'signedIn' : 'acceptEula'
      this.changeState(state, this.props.authData)
    } catch (err) {
      this.error(err)
    } finally {
      this.setState({ loading: false })
    }
  }

  showComponent() {
    const {
      props: { form, authData },
    } = this
    const { verifyAttr } = this.state
    const { unverified } = authData
    return (
      <CenteredLayout title="Verify your details">
        <StyledForm onSubmit={verifyAttr ? this.onSubmit : this.onVerify}>
          {verifyAttr ? (
            <p
              style={{ marginBottom: 10 }}
            >{`Enter the code that you will have received from us, to complete verification of your ${verifyAttr}`}</p>
          ) : (
            <p style={{ marginBottom: 10 }}>
              Account recovery requires verified contact details. You still need to verify the following information:
            </p>
          )}

          {verifyAttr ? (
            <Form.Item style={{ marginTop: 20, marginBottom: 16 }}>
              {form.getFieldDecorator('code', {
                rules: [{ required: true, message: 'Enter verification code!' }],
              })(<Input prefix={<UnlockOutlined />} placeholder="Enter code" size="large" autoFocus />)}
            </Form.Item>
          ) : (
            <Form.Item>
              {form.getFieldDecorator('radio')(
                <Radio.Group>
                  {unverified.email && <StyledRadio value="email">Email</StyledRadio>}
                  {unverified.phone_number && <StyledRadio value="phone_number">Phone number</StyledRadio>}
                </Radio.Group>,
              )}
            </Form.Item>
          )}

          <div className="c-controls">
            <Form.Item className="c-forgot">
              <CenteredLayout.Link onClick={this.onSkip}>Skip for now</CenteredLayout.Link>
            </Form.Item>
            <Button
              style={{ width: 120 }}
              loading={this.state.loading}
              onClick={verifyAttr ? this.onSubmit : this.onVerify}
              className="c-reset-button"
              type="primary"
              size="large"
            >
              Verify
            </Button>
          </div>

          {this.state.error && <div className="c-error">{this.state.error}</div>}

          {/* hidden submit button */}
          <button type="submit" style={{ position: 'absolute', left: -9999 }} />
        </StyledForm>
      </CenteredLayout>
    )
  }
}

export default Form.create()(VerifyContactComponent)
