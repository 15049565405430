import { Layout } from 'antd'
import { observer } from 'mobx-react'
import styled from 'styled-components'

const { Header } = Layout

const HeaderLayoutStyled = styled(Header)<{ $isworkspace?: boolean }>`
  border-block-end: 1px solid rgba(5, 5, 5, 0.06);
  padding-inline: 0px;

  @media (min-width: 992px) {
    padding-right: ${(props) => (props.$isworkspace ? '135px' : '24px')};
    padding-left: 24px;
  }
`

function HeaderLayout({ children }: { children: React.ReactNode }) {
  const isWorkspace = false/* global.router.pathname.split('/')[0] === 'notes' */

  return <HeaderLayoutStyled $isworkspace={isWorkspace}>{children}</HeaderLayoutStyled>
}

export default observer(HeaderLayout)
