import { Placeholder } from 'components/froala/types/mapper'
import { random } from 'lodash'
import MergeMap from '../_merge-map'
import { Types } from '../utils'
import BaseMapper from './base'

class RiskalyzeMapper extends BaseMapper {
  static placeHolders = [
    {
      id: 'riskalyze-value',
      label: 'Nitrogen Value',
      func: () => {
        const value = random(10, 100, true)
        return `${value.toFixed(2)}%`
      },
    },
    {
      id: 'riskalyze-score',
      label: 'Nitrogen Risk Number',
      func: () => {
        const scores = ['Poor', 'Average', 'OK', 'Good', 'Excellent']
        const index = random(0, scores.length - 1)
        return scores[index]
      },
    },
    {
      id: 'riskalyze-value',
      label: 'Nitrogen Value',
      func: () => {
        const value = random(10, 100, true)
        return `${value.toFixed(2)}%`
      },
    },
    {
      id: 'riskalyze-score',
      label: 'Nitrogen Risk Number',
      func: () => {
        const scores = ['Poor', 'Average', 'OK', 'Good', 'Excellent']
        const index = random(0, scores.length - 1)
        return scores[index]
      },
    },
    {
      id: 'orion-network',
      label: 'Orion Networth',
      func: () => {
        const value = random(10, 100, true)
        return `${value.toFixed(2)}%`
      },
    },
    {
      id: 'myvest-gains',
      label: 'MyVest Gains',
      func: () => {
        const scores = ['Poor', 'Average', 'OK', 'Good', 'Excellent']
        const index = random(0, scores.length - 1)
        return scores[index]
      },
    },
    {
      id: 'altruist-gains',
      label: 'Altruist Gains',
      func: () => {
        const scores = ['Poor', 'Average', 'OK', 'Good', 'Excellent']
        const index = random(0, scores.length - 1)
        return scores[index]
      },
    },
  ]

  static isCustom = true
  type = Types.RISKALYZE

  /**
   * @param {*} options = {}
   */
  constructor(node: HTMLElement, options = {}, mergeMap: MergeMap) {
    super(node, options, mergeMap)

    this.value = this.mapValue({ value: '' })
  }

  /**
   * Uses any specified mapping function for this placeHolder, to transform the val.
   * @param {*} id
   * @param {*} val
   * @returns
   */
  mapValue = ({
    placeHolders = RiskalyzeMapper.placeHolders,
    value,
  }: {
    placeHolders?: Placeholder[]
    value: string
  }) => {
    return super.mapValue({ placeHolders, value })
  }

  /**
   * Checks if this mapper matches the soon to be created mapper.
   * @param {*} options
   */
  match = (options: { id?: string; customText?: string } = {}) => {
    return this.label === options.customText && this.options.id === options.id
  }

  /**
   * Updates the riskalyze value.
   */
  update = async () => {
    this.mergeMap?.replace(this)
  }
}

export default RiskalyzeMapper
