import logo from 'assets/images/pulse_logo_green_v3.png'
import styled from 'styled-components'
import styles from 'styles'

const StyledWrapperLogo = styled.div`
  height: ${styles.headerHeight}px;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 100;
`

const WrapperLogo = (props: any) => {
  return (
    <StyledWrapperLogo>
      <img alt="logo" className="logo" height="36" width="36" src={logo} />
    </StyledWrapperLogo>
  )
}

export default WrapperLogo
